import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { confirmable } from 'react-confirm'

import styles from './modal.module.scss'

const Confirmation = ({
  okLabbel = 'Yes',
  cancelLabel = 'No',
  title,
  confirmation,
  show,
  proceed,
  dismiss,
  cancel,
  enableEscape = true,
  showNoOption = true,
}) => {
  return (
    <div className='static-modal'>
      <Modal
        show={show}
        onHide={dismiss}
        backdrop={enableEscape ? true : 'static'}
        keyboard={enableEscape}
      >
        <Modal.Header className={styles.bgColor}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.bgColor}>{confirmation}</Modal.Body>
        <Modal.Footer className={styles.bgColor}>
          <button className={`${'btn btn-purple'} ${styles.bgLightBlue}`} onClick={proceed}>
            {okLabbel}
          </button>
          {showNoOption ? (
            <button onClick={cancel} className={`${'btn btn-grey'} ${styles.bgLightBlue}`}>
              {cancelLabel}
            </button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func.isRequired,
  dismiss: PropTypes.func,
  cancel: PropTypes.func,
  enableEscape: PropTypes.bool,
  showNoOption: PropTypes.bool,
}
export default confirmable(Confirmation)
