import httpService from './httpService'
import SessionService from './sessionService'

async function getByProfileUsername(parentId) {
  return httpService.apiRequest(
    'get',
    `/profile/${SessionService.getUserUsername()}/organizations/${parentId}`,
  )
}

async function getMainByProfileUsername(parentId = '') {
  return httpService.apiRequest(
    'get',
    `/profile/${SessionService.getUserUsername()}/mainOrganizations/${parentId}`,
  )
}

async function getById(organizationId) {
  return httpService.apiRequest(
    'get',
    `/profile/${SessionService.getUserUsername()}/organization/${organizationId}`,
  )
}

async function add(data, parentId) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/organization/${parentId}`,
    data,
  )
}

async function addMain(data) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/mainOrganization`,
    data,
  )
}

async function updateOrganization(organizationId, data) {
  return httpService.apiRequest(
    'put',
    `/profile/${SessionService.getUserUsername()}/organization/${organizationId}/updateAddress`,
    data,
  )
}

async function remove(organizationId) {
  return httpService.apiRequest(
    'delete',
    `/profile/${SessionService.getUserUsername()}/organization/${organizationId}`,
  )
}

async function getOrganizationStatsForPeriod(organizationId, startTime, endTime) {
  const query = []
  if (startTime) query.push('startTime=' + startTime)
  if (endTime) query.push('endTime=' + endTime)
  const queryParams = query.length > 0 ? '?' + query.join('&') : ''
  return httpService.apiRequest(
    'get',
    `/profile/${SessionService.getUserUsername()}/stats/organization/${organizationId}${queryParams}`,
  )
}

async function getOrganizationCallHistory(data) {
  return httpService.apiRequest(
    'get',
    `/profile/${SessionService.getUserUsername()}/organization/${
      data.organizationId
    }/callHistory${'?'}limit=${data.limit}${'&'}page=${data.page}`,
  )
}

async function removeAdmin(data) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/organization/${data.organizationId}/removeAdmin`,
    data,
  )
}

async function getMainOrganizationForMasterAdmin(organizationId) {
  return httpService.apiRequest(
    'get',
    `/profile/${SessionService.getUserUsername()}/mainOrganizations/${organizationId}`,
  )
}

export default {
  getByProfileUsername,
  getMainByProfileUsername,
  getById,
  add,
  addMain,
  updateOrganization,
  remove,
  getOrganizationStatsForPeriod,
  getOrganizationCallHistory,
  removeAdmin,
  getMainOrganizationForMasterAdmin,
}
