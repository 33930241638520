import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import CollapsedCharts from '../../components/CollapsedCharts/CollapsedCharts'
import PageTitle from '../../components/Common/PageTitle'
import Loader from '../../components/Loader/Loader'
import sessionService from '../../services/sessionService'
import statisticService from '../../services/statisticService'
import BarChartSmall from './BarChartSmall'
import styles from './Chart.module.scss'
import DoughnutCharts from './DoughnutCharts'
import LineChartBig from './LineChartBig'
import { ADMIN_ROLES } from '../../constants'

let usersBarChartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      borderWidth: 1,
      backgroundColor: 'rgb(80, 215, 255)',
      data: [],
    },
  ],
  options: {
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.yLabel
        },
      },
    },
  },
}

let sipCountBarChartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      borderWidth: 1,
      backgroundColor: 'rgb(128, 120, 253)',
      data: [],
    },
  ],
}

let outboundBarChartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      borderWidth: 1,
      backgroundColor: 'rgb(50, 246, 149)',
      data: [],
    },
  ],
}

let fromPSTNChart = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      borderWidth: 1,
      backgroundColor: 'rgb(50, 246, 149)',
      data: [],
    },
  ],
}

Date.prototype.monthDays = function () {
  var d = new Date(this.getFullYear(), this.getMonth() + 1, 0)
  return d.getDate()
}

let usersLineChartData = {
  labels: Array.from(new Array(new Date().monthDays()), (_, i) => i + 1),
  datasets: [
    {
      label: 'Current Month',
      fill: 'start',
      data: [],
      backgroundColor: 'rgba(0,123,255,0.1)',
      borderColor: 'rgba(0,123,255,1)',
      pointBackgroundColor: '#ffffff',
      pointHoverBackgroundColor: 'rgb(0,123,255)',
      borderWidth: 1.5,
      pointRadius: 0,
      pointHoverRadius: 3,
    },
    {
      label: 'Past Month',
      fill: 'start',
      data: [],
      backgroundColor: 'rgba(255,65,105,0.1)',
      borderColor: 'rgba(255,65,105,1)',
      pointBackgroundColor: '#ffffff',
      pointHoverBackgroundColor: 'rgba(255,65,105,1)',
      borderDash: [3, 3],
      borderWidth: 1,
      pointRadius: 0,
      pointHoverRadius: 2,
      pointBorderColor: 'rgba(255,65,105,1)',
    },
  ],
}

let callsDoughnutChartsData = {
  labels: ['On PBX', 'Video', 'To PSTN', 'From PSTN'],
  datasets: [
    {
      borderWidth: 0,
      data: [],
      backgroundColor: ['#55D8FE', '#A3A0FB', '#FFDA83', '#Fa3A83'],
      hoverBackgroundColor: ['#55D8FE', '#A3A0FB', '#FFDA83', '#Fa3A83'],
    },
  ],
}

const Charts = () => {
  const [state] = useState({ username: sessionService.getUserUsername() })
  const [role] = useState(sessionService.getUserRole())
  const [userStats, setUserStats] = useState({})
  const [loading, setLoading] = useState(true)
  const [selectedDays, setSelectedDays] = useState(0)
  const [usersBarChartState, setUsersBarChart] = useState(usersBarChartData)
  const [sipCountBarChartState, setSipCountBarChart] = useState(sipCountBarChartData)
  const [outboundBarChartState, setOutboundBarChart] = useState(outboundBarChartData)
  const [fromPSTNChartState, setFromPSTNChartState] = useState(fromPSTNChart)
  const [usersLineChartState, setUsersLineChart] = useState(usersLineChartData)
  const [callsDoughnutChartsState, setCallsDoughnutChartsState] = useState(callsDoughnutChartsData)
  const [agentUsers, setAgentUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(0)

  useEffect(() => {
    allStats()
  }, [selectedDays, selectedUser])

  useEffect(() => {
    if (userStats.stats) {
      const newUserBarCharState = {
        ...usersBarChartState,
        datasets: [...usersBarChartData.datasets],
      }
      newUserBarCharState.datasets[0].data = userStats.stats?.users?.monthly
      newUserBarCharState.datasets[0].backgroundColor =
        userStats.stats?.users?.total > 0 ? 'rgb(128, 120, 253)' : 'rgb(80, 215, 255)'
      setUsersBarChart(newUserBarCharState)
      sipCountBarChartData.datasets[0].data = userStats.stats?.voip?.monthly
      const newSipCountBarChartState = {
        ...sipCountBarChartState,
        datasets: [...sipCountBarChartState.datasets],
      }
      newSipCountBarChartState.datasets[0].data = userStats.stats?.voip?.monthly
      setSipCountBarChart(newSipCountBarChartState)

      const newOutboundBarChartData = {
        ...outboundBarChartState,
        datasets: [...outboundBarChartState.datasets],
      }
      newOutboundBarChartData.datasets[0].data = userStats.stats?.outboundToPSTN.monthly
      setOutboundBarChart(newOutboundBarChartData)

      const newUsersLineChartState = {
        ...usersLineChartState,
        datasets: [...usersLineChartState.datasets],
      }

      const fromPSTNChartData = {
        ...fromPSTNChartState,
        datasets: [...fromPSTNChartState.datasets],
      }
      fromPSTNChartData.datasets[0].data = userStats.stats?.outboundFromPSTN.monthly
      setFromPSTNChartState(fromPSTNChartData)

      newUsersLineChartState.datasets[0].data = userStats?.usersGraphData?.currentMonth
      newUsersLineChartState.datasets[1].data = userStats?.usersGraphData?.pastMonth
      setUsersLineChart(newUsersLineChartState)

      const newCallsDoughnutChartsState = {
        ...callsDoughnutChartsState,
        datasets: [...callsDoughnutChartsState.datasets],
      }
      newCallsDoughnutChartsState.datasets[0].data = [
        userStats?.stats?.voip?.total,
        userStats?.stats.video?.total,
        userStats?.stats?.outboundToPSTN?.total,
        userStats?.stats?.outboundFromPSTN?.total,
      ]
      setCallsDoughnutChartsState(newCallsDoughnutChartsState)
      if (role === ADMIN_ROLES.agent) {
        setAgentUsers(userStats?.agentStats?.agentUsers)
      }
    }
  }, [userStats])

  const allStats = async () => {
    const data = {
      ...(selectedDays === 0 ? {} : { startTime: startTime }),
      ...(selectedUser === 0 ? {} : { agentUser: selectedUser }),
    }
    try {
      const response = await statisticService.dashboardStats(data)
      setUserStats(response)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const value = parseInt(event.target.value)
    setSelectedDays(value)
  }

  const handleChangeUser = (event) => {
    const value = event.target.value
    setSelectedUser(value)
  }

  const currentDate = new Date()

  const startTime = new Date(currentDate.getTime() - selectedDays * 24 * 60 * 60 * 1000)

  return (
    <Container>
      <div className='page-header pt-4'>
        <PageTitle
          title={`Welcome back, ${state.username}.`}
          subtitle='Dashboard'
          className={`${'text-sm-start mb-3'} ${styles.titleHeader}`}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className={`${styles.selectDiv}`}>
          <div className={styles.selectOptionDiv}>
            {role === ADMIN_ROLES.agent ? (
              <div className={styles.selectOptionDiv}>
                <select onChange={handleChangeUser} className={`${''} ${styles.selectBox}`}>
                  <option value=''>All Users</option>
                  {agentUsers?.map((user) => (
                    <option key={user._id} value={user.username}>
                      {user.username}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            <select onChange={handleChange} className={`${''} ${styles.selectBox}`}>
              <option value='0'>All time</option>
              <option value='30'>Last 30 days</option>
              <option value='90'>Last 3 months</option>
              <option value='180'>Last 6 months</option>
              <option value='365'>Last 12 months</option>
            </select>
          </div>
          <div>
            {role === ADMIN_ROLES.gropu_admin ? null : (
              <CollapsedCharts title={'Group Charts'}>
                {role === ADMIN_ROLES.agent ? (
                  <h6 className='mt-3 light-grey'>
                    {selectedUser} organizations: {userStats?.groupStatsData?.length}
                  </h6>
                ) : null}

                {userStats?.groupStatsData?.map((grupa, index) => {
                  return (
                    <div key={index}>
                      <h4>{grupa.groupName.name} </h4>
                      <Row>
                        <Col md='6' sm='12' className='mb-2 col-xl'>
                          <BarChartSmall
                            data={usersBarChartState}
                            title='Users'
                            total={grupa?.users?.total}
                            percentage={grupa?.users?.growthRate.toFixed(2)}
                            increase={grupa?.users?.growthRate > 0 ? true : false}
                          />
                        </Col>

                        <Col md='6' sm='12' className='mb-2 col-xl'>
                          <BarChartSmall
                            data={sipCountBarChartData}
                            title='On PBX count'
                            total={grupa?.voip?.total}
                            percentage={grupa?.voip?.growthRate.toFixed(2)}
                            increase={grupa?.voip?.growthRate > 0 ? true : false}
                          />
                        </Col>
                        <Col md='6' sm='12' className='mb-2 col-xl'>
                          <BarChartSmall
                            data={outboundBarChartData}
                            title='To PSTN count'
                            total={grupa?.outboundToPSTN?.total}
                            percentage={grupa?.outboundToPSTN?.growthRate.toFixed(2)}
                            increase={grupa?.outboundToPSTN?.growthRate > 0 ? true : false}
                          />
                        </Col>
                        <Col md='6' sm='12' className='mb-2 col-xl'>
                          <BarChartSmall
                            data={fromPSTNChart}
                            title='From PSTN count'
                            total={grupa?.outboundFromPSTN?.total}
                            percentage={grupa?.outboundToPSTN?.growthRate.toFixed(2)}
                            increase={grupa?.outboundToPSTN?.growthRate > 0 ? true : false}
                          />
                        </Col>
                      </Row>
                    </div>
                  )
                })}
              </CollapsedCharts>
            )}
            <hr />
          </div>
        </div>
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <h3>Overall</h3>
            <Col md='6' sm='12' className='mb-4 col-xl'>
              <BarChartSmall
                data={usersBarChartState}
                title='Users'
                total={userStats?.stats?.users?.total}
                percentage={userStats?.stats?.users?.growthRate.toFixed(2)}
                increase={userStats?.stats?.users?.growthRate > 0 ? true : false}
              />
            </Col>

            <Col md='6' sm='12' className='mb-4 col-xl'>
              <BarChartSmall
                data={sipCountBarChartData}
                title='On PBX count'
                total={userStats?.stats?.voip?.total}
                percentage={userStats?.stats?.voip?.growthRate.toFixed(2)}
                increase={userStats?.stats?.voip?.growthRate > 0 ? true : false}
              />
            </Col>
            <Col md='6' sm='12' className='mb-4 col-xl'>
              <BarChartSmall
                data={outboundBarChartData}
                title='To PSTN count'
                total={userStats?.stats?.outboundToPSTN?.total}
                percentage={userStats?.stats?.outboundToPSTN?.growthRate.toFixed(2)}
                increase={userStats?.stats?.outboundToPSTN?.growthRate > 0 ? true : false}
              />
            </Col>
            <Col md='6' sm='12' className='mb-4 col-xl'>
              <BarChartSmall
                data={fromPSTNChart}
                title='From PSTN count'
                total={userStats?.stats?.outboundFromPSTN?.total}
                percentage={userStats?.stats?.outboundToPSTN?.growthRate.toFixed(2)}
                increase={userStats?.stats?.outboundToPSTN?.growthRate > 0 ? true : false}
              />
            </Col>
          </Row>
          <Row>
            <Col xl='7' lg='12' md='12' sm='12' className='mb-4 col-lg'>
              <LineChartBig
                data={usersLineChartData}
                title='Users'
                total='1222'
                percentage='22'
                increase='true'
              />
            </Col>
            <Col xl='5' lg='12' md='12' sm='12' className='mb-4  col-lg'>
              <DoughnutCharts
                data={callsDoughnutChartsData}
                title='Calls'
                total='1222'
                percentage='22'
                increase='true'
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default Charts
