import { useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import toastService from '../../services/toastService'
import usersAndAccountsService from '../../services/usersAndAccountsService'
import CustomPagination from '../CustomPagination/CustomPagination'
import Loader from '../Loader/Loader'

import { useNavigate } from 'react-router-dom'
import styles from './TableOrganizations.module.scss'
import organizationService from '../../services/organizationService'

const itemsPerPage = 10
const siblingCount = 1
const PAGING_SELECT_OPTIONS = ['10', '20', '50', '100']

const TableOrganizations = () => {
  const [organizations, setOrganizations] = useState([])
  const [totalCount, setTotalCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(itemsPerPage)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const getOrganizations = () => {
      setLoading(true)
      usersAndAccountsService
        .getOrganizations({
          page: currentPage - 1,
          limit: limit,
          search: search,
        })
        .then((response) => {
          setOrganizations(response?.organizations || [])
          setTotalCount(response?.total || 0)
          const totalPageCount = Math.ceil((response?.total || 0) / limit)
          if (currentPage > totalPageCount) setCurrentPage(1)
        })
        .catch((err) => {
          toastService.show('error', 'Error fetch organizations' + err?.message || '')
        })
        .finally(() => {
          setLoading(false)
        })
    }
    getOrganizations()
  }, [currentPage, limit])

  const handlePageSizeChagne = (size) => {
    setLimit(size)
    setCurrentPage(1)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      setLoading(true)
      usersAndAccountsService
        .getOrganizations({
          page: currentPage - 1,
          limit: limit,
          search: search,
        })
        .then((response) => {
          setOrganizations(response?.organizations || [])
          setTotalCount(response?.total || 0)
          const totalPageCount = Math.ceil((response?.total || 0) / limit)
          if (currentPage > totalPageCount) setCurrentPage(1)
        })
        .catch((err) => {
          toastService.show('error', 'Error fetch users' + err?.message || '')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const onNavigateHandler = (organization) => {
    if (organization?.parentId === null) {
      organizationService.getMainOrganizationForMasterAdmin(organization._id)
      navigate(`/dashboard/mainOrganizations/${organization._id}/main`)
    } else {
      navigate(`/dashboard/organization/${organization.parentId}/list`)
    }
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <div className={styles.info}>
        <div>
          <h3>ORGANIZATIONS</h3>
          <span>total: {totalCount}</span>
        </div>
        <div className={styles.inputWrapper}>
          <input
            placeholder='Search by organization name or username or twilio number'
            className='form-control bordered'
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
            value={search}
          />
          <MdSearch className={styles.searchIcon} onClick={handleKeyDown} />
        </div>
      </div>
      <div className={`${'p-0 pb-3 table-responsive card-body '}`}>
        <table className={`${'table mb-0'}`}>
          <thead className='lightblue-bg'>
            <tr>
              <th>Organizations Name</th>
              <th>Username</th>
              <th>Twilio Number</th>
            </tr>
          </thead>
          <tbody>
            {organizations?.map((organization, index) =>
              organization.active === 1 ? (
                <tr
                  onClick={() => {
                    onNavigateHandler(organization)
                  }}
                  key={index}
                >
                  <td>{organization.name}</td>
                  <td>{organization.username}</td>
                  <td>{organization.twilioNumber}</td>
                </tr>
              ) : null,
            )}
          </tbody>
        </table>
      </div>
      <div className={styles.paginationWrapper}>
        <CustomPagination
          totalCount={totalCount}
          currentPage={currentPage}
          pageSize={limit}
          siblingCount={siblingCount}
          onPageChage={setCurrentPage}
          options={PAGING_SELECT_OPTIONS}
          hasSelect={true}
          handleSelectChange={handlePageSizeChagne}
        />
      </div>
    </div>
  )
}

export default TableOrganizations
