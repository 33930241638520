import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import { BeatLoader } from 'react-spinners'
import paymentService from '../../../services/paymentService'
import plansAndBundlesService from '../../../services/plansAndBundlesService'
import sessionService from '../../../services/sessionService'
import { ADMIN_ROLES } from '../../../constants'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import styles from './modal.module.scss'
import { useNavigate } from 'react-router-dom'
import { stripeKey } from '../../../environment'

const options = {
  hideProgressBar: true,
}

const AddMinutes = ({ handleClose, show, organizationId }) => {
  const [state, setState] = useState({
    minutes: [],
    clientToken: null,
    minuteId: '',
    submited: true,
    disabledBtn: false,
  })
  const navigate = useNavigate()

  const userRole = sessionService.getUserRole()
  const isMasterAdmin = userRole === ADMIN_ROLES.master

  useEffect(() => {
    const loadMinutes = () => {
      plansAndBundlesService
        .getBundles()
        .then((result) => {
          setState((prev) => ({ ...prev, minutes: result }))
        })
        .catch(() => {})
    }
    loadMinutes()
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    setState((prev) => ({
      ...prev,
      [name]: value,
      error: null,
    }))
  }

  const addExtraMinutes = async () => {
    if (state.minuteId) {
      paymentService
        .purchaseExtraMinutes(organizationId, null, minuteId)
        .then(() => {
          toast.success('Extra minutes are successfully purchased!', options)
          handleClose()
          navigate('/dashboard/organizations')
        })
        .catch(() => {
          toast.error('Failed to purchase extra minutes and SMS', options)
        })
    } else {
      setState((prev) => ({ ...prev, error: 'Pricing plan is required!' }))
    }
  }

  const { minutes, minuteId, error, disabledBtn } = state
  const stripePromise = loadStripe(stripeKey)

  const AddCardForm = () => {
    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async (e) => {
      e.preventDefault()
      if (elements === null) {
        return null
      }
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })
      if (error && error.message) {
        toast.error('Error adding card', options)
      } else if (paymentMethod) {
        paymentService
          .purchaseExtraMinutes(organizationId, paymentMethod.id, minuteId)
          .then(() => {
            toast.success('Extra minutes are successfully purchased!', options)
            handleClose()
            navigate('/dashboard/organizations')
          })
          .catch(() => {
            toast.error('Failed to purchase extra minutes and SMS', options)
          })
      }
    }
    return (
      <form onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                iconColor: '#c4f0ff',
                color: '#fff',
                fontWeight: '500',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                  color: '#fce883',
                },
                '::placeholder': {
                  color: '#87BBFD',
                },
              },
              invalid: {
                iconColor: '#FFC7EE',
                color: '#FFC7EE',
              },
            },
          }}
        />

        <Modal.Footer style={{ background: '#121621' }}>
          <button className='btn btn-lightblue' disabled={disabledBtn} type='submit'>
            Add
          </button>

          <button className='btn btn-lightblue' onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </form>
    )
  }
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ background: '#121621' }} closeVariant='white' closeButton>
          <Modal.Title>Purchase more voice minutes or SMS messages</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#121621' }}>
          <form className='form-wrapper'>
            <table className='table mb-0 tb-list-groups'>
              <thead className='lightblue-bg'>
                <tr>
                  <th className='border-0'> # </th>
                  <th className='border-0'>Price</th>
                  <th className='border-0'>Call credits </th>
                  <th className='border-0'>SMS </th>
                  <th className='border-0 text-center'> </th>
                </tr>
              </thead>
              <tbody>
                {minutes.map((minute, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <span>$</span>
                      {minute.price}
                    </td>
                    <td>{minute.minutes}</td>
                    <td>{minute.sms}</td>
                    <td>
                      <div className='custom-radio-container'>
                        <input
                          type='radio'
                          id={`sel-${minute._id}`}
                          className='custom-radio'
                          name='minuteId'
                          value={minute._id}
                          onChange={handleChange}
                        />
                        <label htmlFor={`sel-${minute._id}`}></label>
                        <div className='check'>
                          <div className='inside'></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {error && <div className={'alert alert-danger'}>{error}</div>}
          </form>
          {state.loading ? (
            <div className=' m-5 text-center'>
              <BeatLoader
                // css={override}
                size={15}
                color={'#299bff'}
                loading={state.loading}
              />
            </div>
          ) : null}
          {!isMasterAdmin && minuteId && (
            <div className={styles.cardElement}>
              <Elements stripe={stripePromise}>
                <AddCardForm />
              </Elements>
            </div>
          )}
        </Modal.Body>
        {
          <Modal.Footer style={{ background: '#121621' }}>
            {isMasterAdmin && minuteId ? (
              <button
                className='btn btn-lightblue'
                disabled={disabledBtn}
                onClick={addExtraMinutes}
              >
                Save
              </button>
            ) : null}
            {isMasterAdmin && (
              <button className='btn btn-lightblue' onClick={handleClose}>
                Close
              </button>
            )}
          </Modal.Footer>
        }
      </Modal>
    </div>
  )
}

AddMinutes.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  organizationId: PropTypes.string,
}

export default AddMinutes
