import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import styles from './UsersAndAccounts.module.scss'
import TableUsers from '../../components/TableUsers/TableUsers'
import TableAccounts from '../../components/TableAccounts/TableAccounts'
import TableOrganizations from '../../components/TableOrganizations/TableOrganizations'
import PageTitle from '../../components/Common/PageTitle'

const UsersAndAccounts = () => {
  return (
    <div className='py-4'>
      <PageTitle
        title=''
        subtitle='Manage Users & Accounts & Organizations'
        className={styles.title}
      />
      <h3 className={styles.header}>Users & Accounts & Organizations</h3>
      <div className={styles.usersAndAccountsWrapper}>
        <Tabs>
          <TabList>
            <Tab>Users</Tab>
            <Tab>Accounts</Tab>
            <Tab>Organizations</Tab>
          </TabList>
          <TabPanel>
            <TableUsers />
          </TabPanel>
          <TabPanel>
            <TableAccounts />
          </TabPanel>
          <TabPanel>
            <TableOrganizations />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default UsersAndAccounts
