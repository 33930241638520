import { Route, Routes, Navigate } from 'react-router-dom'
import App from '../App'
import PrivateRoute from './PrivateRoute'
import Login from '../pages/Login/Login'
import Register from '../pages/Register/Register'
import ForgotPassword from '../pages/ForgotPassword/ForgotPassord'
import Dashboard from '../pages/Dashboard/Dashboard'
import Profile from '../pages/Profile/Profile'
import Organizations from '../pages/Organizations/Organizations'
import Charts from '../pages/Charts/Charts'
import ResetPassword from '../pages/ResetPassword/ResetPassword'
import Transfers from '../pages/Transfers/Transfers'
import Extensions from '../pages/Extensions/Extensions'
import Organization from '../pages/Organization/Organization'
import Plan from '../pages/Plan/Plan'
// import AddDevice from '../pages/AddDevice/AddDevice'
import PlansAndSubscription from '../pages/PlansAndSubscription/PlansAndSubscription'
import PlanCreation from '../pages/Plan/PlanCreation'
import BundleCreation from '../components/Bundle/BundleCreation'
import MasterAdminRoute from './MasterAdminRoute'
import UsersAndAccounts from '../pages/UsersAndAccounts/UsersAndAccounts'
import ExtensionCallHistory from '../pages/ExtensionCallHostry/ExtensionCallHistory'
import OrganizationCallHistory from '../pages/OrganizationCallHistory/OrganizationCallHistory'
import MasterAdmins from '../pages/MasterAdmins/MasterAdmins'
import RegisterAgent from '../pages/RegisterAgent/RegisterAgent'
import RequestedPage from '../pages/RequestedPage/RequestedPage'
import Agents from '../pages/Agents/Agents'
import AgentAddUser from '../pages/AgentAddUser/AgentAddUser'
import AgentView from '../pages/AgentView/AgentView'
import AgentInfoUser from '../pages/AgentInfoUser/AgentInfoUser'
import Settings from '../pages/Settings/Settings'

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<App />}>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='register-agent' element={<RegisterAgent />} />
        <Route path='request' element={<RequestedPage />} />
        <Route path='register/:hash' element={<Register />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password/:hash' element={<ResetPassword />} />
        <Route path='*' element={<Navigate to='/dashboard' replace />} />

        <Route element={<PrivateRoute />}>
          <Route path='dashboard' element={<Dashboard />}>
            <Route path='charts' element={<Charts />} />
            <Route element={<MasterAdminRoute />}>
              <Route path='plans-bundles' element={<PlansAndSubscription />} />
              <Route path='plans-bundles/plan' element={<PlanCreation />} />
              <Route path='plans-bundles/plan/:planId' element={<PlanCreation />} />
              <Route path='plans-bundles/bundle' element={<BundleCreation />} />
              <Route path='plans-bundles/bundle/:bundleId' element={<BundleCreation />} />
              <Route path='users-accounts' element={<UsersAndAccounts />} />
              <Route path='master-admins' element={<MasterAdmins />} />
              <Route path='agents' element={<Agents />} />
              <Route path='settings' element={<Settings />} />
              <Route
                path='mainOrganizations/:organizationId/main'
                element={<Organizations main={true} />}
              />
            </Route>
            <Route path='profile' element={<Profile />} />
            <Route path='organizations' element={<Organizations main={true} />} />

            <Route path='organization/add' element={<Organization main={true} />} />
            <Route
              path='organization/:organizationId/edit'
              element={<Organization main={true} />}
            />
            <Route path='organization/:organizationId/list' element={<Organizations />} />
            <Route path='organization/:organizationId/add' element={<Organization />} />
            <Route path='organization/:organizationId' element={<Plan />} />
            {/* <Route path='payment/:organizationId' element={<AddDevice />} /> */}
            <Route path='extensions/:organizationId' element={<Extensions />} />
            <Route
              path='extensions/:organizationId/callhistory/:accountId'
              element={<ExtensionCallHistory />}
            />
            <Route
              path='organization/:organizationId/callhistory/list'
              element={<OrganizationCallHistory />}
            />
            <Route path='transfers/:organizationId' element={<Transfers />} />
            <Route path='agents/add-user' element={<AgentAddUser />} />
            <Route path='agents/view' element={<AgentView />} />
            <Route path='agents/user/info' element={<AgentInfoUser />} />
            <Route path='*' element={<Navigate to='/dashboard' replace />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRoutes
