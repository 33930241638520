import { useState } from 'react'
import styles from './BindAccount.module.scss'
import QrCode from '../Common/Modals/Qrcode'

const BindAccount = () => {
  const [showQRCodeModal, setShowQRCodeModal] = useState(false)

  const openQrCodeModal = () => {
    setShowQRCodeModal(true)
  }

  const closeQrCodeModal = () => {
    setShowQRCodeModal(false)
  }

  return (
    <div className={styles.wrapper}>
      {showQRCodeModal ? (
        <QrCode show={showQRCodeModal} generateQRCode={''} handleClose={closeQrCodeModal} />
      ) : null}
      <div>
        <button onClick={openQrCodeModal} className='btn btn-sm btn-lightblue me-3 mb-4'>
          Get QR code
        </button>
      </div>
    </div>
  )
}

export default BindAccount
