import PageTitle from '../../components/Common/PageTitle'
import styles from './AgentAddUser.module.scss'
import logo from '../../assets/images/logo.png'
import { useState } from 'react'
import agentService from '../../services/agentService'
import toastService from '../../services/toastService'
import { useNavigate } from 'react-router-dom'

const usernameRGEX = /^[a-zA-Z][a-zA-Z0-9_]{3,}[a-zA-Z0-9]$/

const AgentAddUser = () => {
  const [user, setUser] = useState({ username: '', email: '', password: '' })
  const [error, setError] = useState('')

  const navigate = useNavigate()

  const handleChange = (event) => {
    const name = event.target.name
    let value = event.target.value
    if ('username' === name) {
      value = value.toLowerCase()
    }
    setUser({ ...user, [name]: value, error: null })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const error = validateForm()
    if (!error) {
      agentService
        .registerUser(user)
        .then((response) => {
          navigate('/dashboard/agents/user/info', { state: { user: response } })
          toastService.show('success', `User is successfully created!`)
        })
        .catch(() => {})
    } else {
      setError({ error })
    }
  }

  const validateForm = () => {
    if (!usernameRGEX.test(user.username)) {
      return 'Username need to start with letter, it need to be at least 5 characters long, can have letters, numbers and underscore and it need to end with letter or number'
    } else if (!user.email) {
      return 'email is required'
    } else if (!user.password || user.password.length < 5) {
      return 'password need to be longer then 5 characters'
    }
    return null
  }

  return (
    <div className='py-4'>
      <PageTitle title='' subtitle='Creating user with agent' className={styles.title} />
      <h3 className={styles.header}>Create Users</h3>
      <div className={styles.wrapper}>
        <div className={`${'auth-frame ps-5 pe-5 fs-9'} ${styles.headerBox}`}>
          <div className={`${'auth-img-box pt-5 pb-4'} ${styles.logoBox}`}>
            <img className={styles.logo} src={logo} alt='Air Touch' />
          </div>
          <form onSubmit={handleSubmit} className='text-start'>
            <div className='form-group mb-2'>
              <label className={`${'form-label'} ${styles.labelInp}`} htmlFor='username'>
                Username:
              </label>
              <input
                id='username'
                className={`${'form-control border-0'} ${styles.inputBgColor}`}
                type='text'
                name='username'
                value={user.username}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='form-group mb-2'>
              <label className={styles.labelInp} htmlFor='email'>
                Email:
              </label>
              <input
                id='email'
                className={`${'form-control mt-2 border-0'} ${styles.inputBgColor}`}
                type='text'
                name='email'
                value={user.email}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className='form-group mb-2'>
              <label className={styles.labelInp} htmlFor='password'>
                Password:
              </label>
              <input
                id='password'
                className={`${'form-control mt-2 mb-3 border-0'} ${styles.inputBgColor}`}
                type='password'
                name='password'
                value={user.password}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {error && <div className={'alert alert-danger'}>{error.error}</div>}
            <div className='text-end'>
              <button type='submit' className={`${'btn mb-3'} ${styles.registerBtn}`}>
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AgentAddUser
