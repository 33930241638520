import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
export const exportToXLSX = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const ws = XLSX.utils.json_to_sheet(csvData)
  const date = new Date()
  const sheetName = `Airtouch ${dayjs(date).format('MMM DD, YYYY')}`

  let objectMaxLength = []
  csvData.forEach((obj) => {
    let i = 0
    for (const [key, value] of Object.entries(obj)) {
      let maxLen = key.length >= String(value)?.length ? key.length : String(value).length
      objectMaxLength[i] = objectMaxLength[i] >= maxLen ? objectMaxLength[i] : maxLen
      i++
    }
  })
  objectMaxLength = objectMaxLength.map((item) => ({ wch: item }))
  ws['!cols'] = objectMaxLength
  const wb = {
    Sheets: { [sheetName]: ws },
    SheetNames: [sheetName],
  }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  saveAs(data, fileName + fileExtension)
}
