import httpService from './httpService'
import SessionService from './sessionService'

async function getCallHistory(accountId, currentPage, limit) {
  return httpService.apiRequest(
    'get',
    `/account/${SessionService.getUserUsername()}/calls/${accountId}/${currentPage}/${limit}`,
  )
}

export default {
  getCallHistory,
}
