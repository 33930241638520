import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'

import styles from './UserInformations.module.scss'
import { useState } from 'react'
import agentService from '../../services/agentService'
import toastService from '../../services/toastService'

const UserInformations = ({
  show,
  handleClose,
  user,
  handleDeactivateUser,
  setusersModalShow,
  setUsers,
}) => {
  const [commission, setCommission] = useState('')

  const handleCommission = (event) => {
    setCommission(event.target.value)
  }

  const handleNumber = (e) => {
    const inputValue = e.target.value
    e.target.value = inputValue.replace(/\D/g, '')
  }

  const onClickCommission = () => {
    if (!user._id) return
    agentService
      .addCommission({ username: user.username, commission: commission })
      .then((response) => {
        setUsers((prevAgent) => {
          return prevAgent.map((mapAgent) =>
            mapAgent._id === user._id
              ? { ...mapAgent, commission: response.user.commission }
              : mapAgent,
          )
        })
        toastService.show('success', `Successfully added agent commission`)
      })
      .catch((err) => {
        toastService.show('error', 'Error add agent commission' + err?.message || '')
      })
      .finally(() => setusersModalShow(false))
  }

  return (
    <div>
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title>User informations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.infoWrapper}>
            <div className={styles.oneRedcord}>
              <br />
              {user?.username ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Username: </span>
                  </div>
                  <h5>{user.username}</h5>
                </div>
              ) : null}
              {user?.email ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Email: </span>
                  </div>
                  <h5>{user.email}</h5>
                </div>
              ) : null}
              {user?.role ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Role: </span>
                  </div>
                  <h5>{user.role}</h5>
                </div>
              ) : null}
              {user?.adminOrganizationName ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Organization Name: </span>
                  </div>
                  <h5>{user.adminOrganizationName}</h5>
                </div>
              ) : null}
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>Status: </span>
                </div>
                <h5>{user?.status === 1 ? 'Active' : 'Inactive'}</h5>
              </div>
            </div>
            <div className={styles.actions}>
              <div className={styles.status}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>
                    {user?.status === 1 ? 'Deactivate the user:' : 'Activate the user:'}
                  </span>
                </div>
                <Button
                  className={`${user.status === 1 ? 'btn light-grey-bg sm' : 'btn btn-success sm'}`}
                  size='sm'
                  onClick={() => {
                    handleDeactivateUser(user)
                  }}
                >
                  {user.status === 1 ? 'Deactivate' : 'Activate'}
                </Button>
              </div>
              {/* TODO: THINK HOW TO HANDLE SET ADMIN ROLE AS MASTER USER */}
              {/* <div className={styles.role}>
                <div className={styles.spanDiv}>
                  <span>Set Admin Role:</span>
                </div>
                <select
                  value={selectedOptions || ''}
                  onChange={(e) => handleUserSelectChange(e, user)}
                  className='form-control-sm'
                >
                  <option disabled={true} value=''>
                    Set Role
                  </option>
                  <option value='ORGANIZATION_ADMIN'>Organization Admin</option>
                  <option value='GROUP_ADMIN'>Group Admin</option>
                </select>
                <Button
                  onClick={() => {
                    handleChangeRole(user)
                  }}
                  className='btn lightblue-bg '
                  disabled={disableBtn}
                >
                  Save
                </Button>
              </div> */}
            </div>
            {user?.agentId ? (
              <>
                <div className={styles.oneRedcord}>
                  {user?.commission ? (
                    <div className={styles.infoDiv}>
                      <div className={styles.spanDiv}>
                        <span className='label-color'>Commission: </span>
                      </div>
                      <h5>{user.commission}</h5>
                    </div>
                  ) : null}
                </div>
                <div className={styles.commission}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>
                      {user?.commission ? 'Edit commission' : 'Add commission'}{' '}
                    </span>
                  </div>
                  <div className='custom-form-inline'>
                    <input
                      style={{ background: '#121621' }}
                      className='form-control bordered me-2'
                      type='text'
                      placeholder='Add the percentage'
                      onInput={handleNumber}
                      onChange={handleCommission}
                    />
                    <button onClick={onClickCommission} className='btn btn-lightblue'>
                      {user?.commission ? 'Edit' : 'Add'}
                    </button>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.oganizationBgColor}>
          <button className='btn btn-lightblue' onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default UserInformations
UserInformations.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  handleDeactivateUser: PropTypes.func.isRequired,
  setusersModalShow: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired,
}
