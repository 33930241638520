export const ADMIN_ROLES = {
  master: 'MASTER',
  organization_admin: 'ORGANIZATION_ADMIN',
  gropu_admin: 'GROUP_ADMIN',
  viewer: 'VIEWER',
  agent: 'AGENT',
}

export const ADMIN_TYPES = {
  masterAdmin: 'masterAdmin',
  groupAdmin: 'groupAdmin',
}

export const PLAN_TYPES = {
  trial: 'trial',
  custom: 'custom',
  country: 'country',
  public: 'public',
}

export const EMERGENCY_CALL_COUNTRIES = ['US', 'CA', 'GB']
