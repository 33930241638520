import { Outlet, Navigate, useLocation } from 'react-router-dom'
import sessionService from '../services/sessionService'
import { useEffect } from 'react'
import profileService from '../services/profileService'

const PrivateRoute = () => {
  const location = useLocation()
  const isAuthenticated = sessionService.isAuth()
  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await profileService.refreshProfile()
        if (user?._id) sessionService.updateUser(user)
      } catch (error) {
        console.log('Error refresh user', error)
      }
    }
    getUser()
  }, [])
  return isAuthenticated ? <Outlet /> : <Navigate to='/login' state={{ from: location }} replace />
}

export default PrivateRoute
