import httpService from './httpService'
import SessionService from './sessionService'

async function register(user) {
  return httpService.apiRequest('post', '/profile/register', user)
}

async function login(username, password) {
  return httpService.apiRequest('post', '/profile/login', { username, password })
}

async function refreshProfile() {
  return httpService.apiRequest('get', `/profile/${SessionService.getUserUsername()}/refresh`)
}

async function update(email, tutorial, offices) {
  return httpService.apiRequest('post', `/profile/${SessionService.getUserUsername()}/updateUser`, {
    email,
    tutorial,
    offices,
  })
}

async function forgotPassword(email) {
  return httpService.apiRequest('post', '/profile/forgotPassword', { email })
}

async function resetPassword(hash, data) {
  return httpService.apiRequest('post', `/profile/resetPassword/${hash}`, data)
}

async function changePassword(data) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/changePassword`,
    data,
  )
}

async function sendMessage(data) {
  return httpService.apiRequest('post', '/profile/sendMessage', data)
}

async function getExtraMinutePackages() {
  return httpService.apiRequest(
    'get',
    `/profile/${SessionService.getUserUsername()}/getExtraMinutPackages`,
  )
}

async function getBindingCode() {
  return httpService.apiRequest('get', `/profile/${SessionService.getUserUsername()}/code`)
}

async function disconnectAccount() {
  return httpService.apiRequest('delete', `/profile/${SessionService.getUserUsername()}/account`)
}

async function getOrganizationPendingInvites(organizationId) {
  return httpService.apiRequest(
    'get',
    `/profile/${SessionService.getUserUsername()}/organization/${organizationId}/invites/pending`,
  )
}

async function removeOrganizationExtensionsInvite(organizationId, inviteId) {
  return httpService.apiRequest(
    'delete',
    `/profile/${SessionService.getUserUsername()}/organization/${organizationId}/invite/${inviteId}`,
  )
}

async function emailInvite(data) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/admin/invite`,
    data,
  )
}

export default {
  register,
  login,
  refreshProfile,
  update,
  forgotPassword,
  resetPassword,
  changePassword,
  sendMessage,
  getExtraMinutePackages,
  getBindingCode,
  disconnectAccount,
  getOrganizationPendingInvites,
  removeOrganizationExtensionsInvite,
  emailInvite,
}
