import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import DatePicker from '../../components/Common/DatePicker'
import organizationService from '../../services/organizationService'
import days from 'dayjs'
import { convertMinutesToMinutesAndSeconds } from '../../utils/utils'

import styles from './organizations.module.scss'

const options = {
  autoClose: 3000,
  hideProgressBar: true,
}

const OrganizationStats = ({ show, handleClose, organizationId }) => {
  const [state, setState] = useState({
    extensions: [],
    callsMap: {},
    totalCallsMap: {},
    extensionStats: [],
    fromDate: days().startOf('month').toDate(),
    toDate: new Date(),
  })

  useEffect(() => {
    loadOrganizationStats()
  }, [state.fromDate])

  const loadOrganizationStats = async () => {
    const { fromDate, toDate } = state
    try {
      const result = await organizationService.getOrganizationStatsForPeriod(
        organizationId,
        days(fromDate).format('YYYY-MM-DD'),
        days(toDate).format('YYYY-MM-DD'),
      )
      const { extensions, callsMap, totalCallsMap } = result
      const extensionStats = extensions
        .map(({ _id, name, accountNumber }) => ({
          name,
          accountNumber,
          count: callsMap[_id].count,
          minutes: callsMap[_id].minutes,
          sipCount: callsMap[_id].sipCount,
          sipMinutes: callsMap[_id].sipMinutes,
          pstnCount: callsMap[_id].pstnCount,
          pstnMinutes: callsMap[_id].pstnMinutes,
        }))
        .sort((first, second) =>
          first.minutes < second.minutes ? 1 : first.minutes === second.minutes ? 0 : -1,
        )
      setState((prev) => ({ ...prev, extensions, callsMap, totalCallsMap, extensionStats }))
    } catch (error) {
      toast.error(error.response, options)
    }
  }

  const handleFromDateSelect = (data) => {
    setState((prev) => ({ ...prev, fromDate: data.selection.startDate }))
  }

  const { extensionStats, fromDate } = state
  return (
    <div>
      <Modal
        size='lg'
        show={show}
        onHide={handleClose}
        contentClassName={styles.oganizationBgColor}
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title>Usage Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker fromDate={fromDate} onSelect={handleFromDateSelect} />
          <br />
          <h4>Extensions calls statistics</h4>
          <table className='table  mb-0'>
            <thead className='lightblue-bg'>
              <tr>
                <th className='border-0'> #</th>
                <th className='border-0'>Number/Email</th>
                <th className='border-0'>Call count</th>
                <th className='border-0 '>Minutes</th>
                <th className='border-0'>P2P count</th>
                <th className='border-0 '>P2P Minutes</th>
                <th className='border-0'>PSTN count</th>
                <th className='border-0 '>PSTN Minutes</th>
              </tr>
            </thead>
            <tbody>
              {extensionStats.map((statsData, i) => (
                <tr key={'extensionStats -' + i}>
                  <td>{i + 1}</td>
                  <td>{statsData.accountNumber}</td>
                  <td>{statsData.count}</td>
                  <td>
                    {statsData?.minutes
                      ? convertMinutesToMinutesAndSeconds(statsData.minutes)
                      : statsData.minutes}
                  </td>
                  <td>{statsData.sipCount}</td>
                  <td>
                    {statsData?.sipMinutes
                      ? convertMinutesToMinutesAndSeconds(statsData.sipMinutes)
                      : statsData.sipMinutes}
                  </td>
                  <td>{statsData.pstnCount}</td>
                  <td>
                    {statsData?.pstnMinutes
                      ? convertMinutesToMinutesAndSeconds(statsData.pstnMinutes)
                      : statsData.pstnMinutes}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className={styles.oganizationBgColor}>
          <button className='btn btn-lightblue' onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

OrganizationStats.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  organizationId: PropTypes.string,
}

export default OrganizationStats
