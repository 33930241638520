import httpService from './httpService'
import SessionService from './sessionService'

async function addCommission(data) {
  return httpService.apiRequest(
    'post',
    `/settings/${SessionService.getUserUsername()}/settings/agentCommission`,
    data,
  )
}

export default {
  addCommission,
}
