import PropTypes from 'prop-types'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import callTransferService from '../../services/callTransferService'
import toastService from '../../services/toastService'
import styles from './ExtensionsTransferCall.module.scss'
import sessionService from '../../services/sessionService'
import { webAppUrl } from '../../environment'

const ExtensionsTransferCall = ({
  show,
  handleClose,
  extensions,
  currentExtension,
  callInfo,
  setusersModalShow,
}) => {
  const [callForTransfer, setCallForTransfer] = useState('')

  const user = sessionService.getUser()

  const handleChangeUser = (event) => {
    const value = event.target.value
    setCallForTransfer(value)
  }
  const transferCall = async () => {
    try {
      await callTransferService.sendTransferData({
        fromExtensionId: currentExtension._id,
        toExtensionId: callForTransfer,
        callerId: callInfo.inCallCallerIds[0],
      })
      toastService.show('success', `The call is transferred!`)
      setusersModalShow(false)
    } catch (error) {
      toastService.show('error', 'Error call transfer' + error?.message || '')
    }
  }

  const transferCallToUser = async () => {
    try {
      await callTransferService.transferCallLineToUser({
        fromExtensionId: currentExtension._id,
        callerId: callInfo.inCallCallerIds[0],
      })
      toastService.show('success', `The call is transferred to user!`)
      window.open(webAppUrl, '_blank')
    } catch (error) {
      toastService.show('error', 'Error call transfer to user' + error?.message || '')
    }
  }

  return (
    <div>
      <div>
        <Modal size='lg' show={show} onHide={handleClose}>
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title>Transfer calls</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.infoWrapper}>
              <div className='form-organization'>
                <div className='custom-form-inline'>
                  <div className={styles.extensionsDiv}>
                    <div className={styles.extInfo}>
                      <p>Extension Number: {currentExtension.extensionNumber}</p>
                      <p>Account Number: {currentExtension.accountNumber}</p>
                    </div>
                    <div>
                      <p>All extensions</p>
                      <select onChange={handleChangeUser}>
                        <option value=''>All Numbers</option>
                        {extensions.map((extension, index) =>
                          extension.endpoint.username !== callInfo.from &&
                          extension.endpoint.username !== callInfo.to ? (
                            <option key={index} value={extension._id}>
                              Number: {extension.accountNumber}
                            </option>
                          ) : null,
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                {user?.accountId ? (
                  <div>
                    <hr />
                    <button onClick={transferCallToUser} className='btn btn-lightblue'>
                      Pick Up a Call
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.oganizationBgColor}>
            <button onClick={transferCall} className='btn btn-lightblue'>
              Transfer Call
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default ExtensionsTransferCall

ExtensionsTransferCall.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  extensions: PropTypes.any,
  currentExtension: PropTypes.object,
  callInfo: PropTypes.object,
  setusersModalShow: PropTypes.any,
}
