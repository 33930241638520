import httpService from './httpService'
import SessionService from './sessionService'

async function loadOrganizationTransfer() {
  return httpService.apiRequest('post', `/profile/loadApprovedOrganizationTransfer`, {
    username: SessionService.getUserUsername(),
  })
}

async function loadApprovedOrganizationTransfer() {
  return httpService.apiRequest('post', `/profile/loadOrganizationTransfer`, {
    username: SessionService.getUserUsername(),
  })
}

async function loadApprovedFromOrganizationTransfer() {
  return httpService.apiRequest('post', `/profile/loadMyApprovedOrganizationTransfer`, {
    username: SessionService.getUserUsername(),
  })
}

async function createTransferRequest(transferTo, organizationId, organizationName) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/createTransfer`,
    { transferTo, organizationId, organizationName },
  )
}

async function approveTransferRequest(organizationId, transferId, from) {
  const data = {
    username: SessionService.getUserUsername(),
    organizationId: organizationId,
    id: transferId,
    from: from,
  }
  return httpService.apiRequest('patch', `/profile/approveTransfer`, data)
}

async function declineTransferRequest(transferId) {
  return httpService.apiRequest(
    'delete',
    `/profile/to/${SessionService.getUserUsername()}/transfer/${transferId}`,
  )
}

export default {
  loadOrganizationTransfer,
  loadApprovedOrganizationTransfer,
  loadApprovedFromOrganizationTransfer,
  createTransferRequest,
  approveTransferRequest,
  declineTransferRequest,
}
