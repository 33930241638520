import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import dayjs from 'dayjs'
import { useState } from 'react'
import agentService from '../../services/agentService'
import toastService from '../../services/toastService'
import { exportToXLSX } from '../../utils/exportToXLSX'
import Loader from '../Loader/Loader'
import styles from './ModalForTransactionsExel.module.scss'
import sessionService from '../../services/sessionService'

const ModalForTransactionsExel = ({ show, handleClose, setShowModal }) => {
  const [info, setInfo] = useState({ from: '', to: '', username: '' })
  const [isLoading, setIsLoading] = useState(false)

  const agent = sessionService.getUser()

  const date = new Date()

  const handleChange = (e) => {
    const { name, value } = e.target
    setInfo({
      ...info,
      [name]: value,
    })
  }

  const onclickDownload = async () => {
    const formData = {
      ...info,
      from: info.from ? new Date(info.from).toISOString() : '',
      to: info.to ? new Date(info.to).toISOString() : '',
    }

    try {
      const response = await agentService.downloadFile(formData)
      if (response?.transactions && response?.transactions.length) {
        const xlsxObject = response?.transactions.map((transaction) => {
          const user = response?.agentUsersMap[transaction.username]
          let userCommission = user?.commission || agent.commission

          const agentCommission = Number(((transaction.amount * userCommission) / 100).toFixed(2))

          return {
            User: transaction.username,
            Organization: transaction.organizationName,
            Type: transaction.type,
            'Created at': dayjs(transaction.createdAt).format('DD-MM-YYYY'),
            'Amount in $': Number(transaction.amount),
            'Agent Commission': agentCommission,
          }
        })

        exportToXLSX(xlsxObject, `Agent transactions - ${dayjs(date).format('MMM DD, YYYY')}`)
        toastService.show('success', `File successfully downloaded!`)
      }
      setShowModal(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal size='md' show={show} onHide={handleClose}>
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title>Transactions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.infoDiv}>
              <div className={styles.spanDiv}>
                <span className='label-color'>Enter date of transactions</span>
              </div>
              <div className={`${'custom-form-inline mt-2'} ${styles.inputs}`}>
                <div>
                  <span>From</span>
                  <input
                    className='form-control bordered me-2'
                    type='date'
                    placeholder='From'
                    name='from'
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <span>To</span>
                  <input
                    className='form-control bordered me-2 mb-3'
                    type='date'
                    placeholder='To'
                    name='to'
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <div>
                  <span>User</span>
                  <input
                    className='form-control bordered me-2 mb-3 mt-2'
                    type='text'
                    placeholder='Username'
                    name='username'
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button onClick={onclickDownload} className='btn btn-lightblue'>
                Download
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.oganizationBgColor}>
            <button className='btn btn-lightblue' onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default ModalForTransactionsExel

ModalForTransactionsExel.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
}
