import httpService from './httpService'
import SessionService from './sessionService'

async function getUsers(data) {
  return httpService.apiRequest(
    'get',
    `/user/${SessionService.getUserUsername()}/list${'?'}page=${data.page}${'&'}limit=${
      data.limit
    }${data.username ? `${'&'}username=${data.username}` : ''}`,
  )
}

async function getAccounts(data) {
  return httpService.apiRequest(
    'get',
    `/account/${SessionService.getUserUsername()}/accounts${'?'}limit=${data.limit}${'&'}page=${
      data.page
    }${data.name ? `${'&'}name=${data.name}` : ''}`,
  )
}
async function getOrganizations(data) {
  return httpService.apiRequest(
    'get',
    `/account/${SessionService.getUserUsername()}/organizations${'?'}page=${data.page}${'&'}limit=${
      data.limit
    }${data.search ? `${'&'}search=${data.search}` : ''}`,
  )
}

async function activeDeactiveUser(data) {
  return httpService.apiRequest(
    'post',
    `/user/${SessionService.getUserUsername()}/deactiveUser`,
    data,
  )
}

async function setAdminRole(data) {
  return httpService.apiRequest(
    'post',
    `/user/${SessionService.getUserUsername()}/setAdminRole`,
    data,
  )
}

async function getMasterAdminIvites() {
  return httpService.apiRequest(
    'get',
    `/user/${SessionService.getUserUsername()}/list/invitedUsers`,
  )
}

async function deleteMasterAdminInvite(inviteId) {
  return httpService.apiRequest('delete', `/user/${SessionService.getUserUsername()}/${inviteId}`)
}

async function getMasterAdmins() {
  return httpService.apiRequest('get', `/user/${SessionService.getUserUsername()}/list/masterAdmin`)
}

async function sendMasterAdminInvite(data) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/admin/invite`,
    data,
  )
}

async function activateDeactivateMasterAdmin(username, status) {
  return httpService.apiRequest(
    'post',
    `/user/${SessionService.getUserUsername()}/activateDeactivateMasterAdmin`,
    { username, status },
  )
}

export default {
  getUsers,
  getAccounts,
  getOrganizations,
  activeDeactiveUser,
  setAdminRole,
  getMasterAdminIvites,
  deleteMasterAdminInvite,
  sendMasterAdminInvite,
  getMasterAdmins,
  activateDeactivateMasterAdmin,
}
