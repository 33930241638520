import { useState } from 'react'
import PropType from 'prop-types'
import { Pagination } from 'react-bootstrap'

import { usePagination } from '../../hooks/usePaginaton'

import './CustomPagination.scss'

const CustomPagination = ({
  totalCount,
  currentPage,
  pageSize,
  siblingCount,
  hasSelect = false,
  options = ['10', '20', '50', '100'],
  onPageChage,
  handleSelectChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(pageSize)

  const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize })

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10)
    setSelectedValue(newValue)
    handleSelectChange(newValue)
  }

  const lastPage = Math.ceil(totalCount / pageSize)

  if (!Array.isArray(paginationRange) || !paginationRange.length) return null
  return (
    <div className='paginationWrapper'>
      <Pagination className='d-flex justify-content-center'>
        <Pagination.First disabled={currentPage === 1} onClick={() => onPageChage(1)} />
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() =>
            onPageChage((prev) => {
              if (prev === 1) return prev
              return prev - 1
            })
          }
        />
        {paginationRange?.map((page, index) => {
          if (page === '...left')
            return (
              <Pagination.Ellipsis
                key={page + index}
                onClick={() => onPageChage(currentPage - 3)}
              />
            )
          if (page === '...right')
            return (
              <Pagination.Ellipsis
                key={page + index}
                onClick={() => onPageChage(currentPage + 3)}
              />
            )

          return (
            <Pagination.Item
              active={currentPage === page}
              onClick={() => onPageChage(page)}
              key={page}
            >
              {page}
            </Pagination.Item>
          )
        })}
        <Pagination.Next
          disabled={currentPage === lastPage}
          onClick={() =>
            onPageChage((prev) => {
              if (prev >= lastPage) return prev
              return prev + 1
            })
          }
        />
        <Pagination.Last
          disabled={currentPage === lastPage}
          onClick={() => onPageChage(lastPage)}
        />{' '}
      </Pagination>
      {hasSelect ? (
        <select value={selectedValue} onChange={handleChange}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : null}
    </div>
  )
}

CustomPagination.propTypes = {
  totalCount: PropType.number.isRequired,
  currentPage: PropType.number.isRequired,
  pageSize: PropType.number.isRequired,
  siblingCount: PropType.number.isRequired,
  hasSelect: PropType.bool,
  options: PropType.array,
  onPageChage: PropType.func.isRequired,
  handleSelectChange: PropType.func,
}

export default CustomPagination
