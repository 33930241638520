import { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import sessionStorageService from '../services/sessionStorageService'

const MimicUserContext = createContext()

const MimicUserProvider = ({ children }) => {
  const [mimicUser, setMimicUser] = useState(sessionStorageService.getItem('mimic-user'))

  useEffect(() => {
    const user = sessionStorageService.getItem('mimic-user')
    setMimicUser(user)
  }, [])

  return (
    <MimicUserContext.Provider value={{ mimicUser, setMimicUser }}>
      {children}
    </MimicUserContext.Provider>
  )
}

MimicUserProvider.propTypes = {
  children: PropTypes.node,
}

export { MimicUserContext, MimicUserProvider }
