import { Outlet, Navigate, useLocation } from 'react-router-dom'
import sessionService from '../services/sessionService'
import { ADMIN_ROLES } from '../constants'

const MasterAdminRoute = () => {
  const location = useLocation()
  const role = sessionService.getUserRole()
  return role === ADMIN_ROLES.master ? (
    <Outlet />
  ) : (
    <Navigate to='/dashboard/charts' state={{ from: location }} replace />
  )
}

export default MasterAdminRoute
