import { useEffect, useState } from 'react'
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md'
import agentService from '../../services/agentService'
import Loader from '../Loader/Loader'
import ModalForTransactionsExel from '../ModalForTransactionsExel/ModalForTransactionsExel'
import styles from './TableAgentUsers.module.scss'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const TableAgentUsers = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [totalEarning, setTotalEarning] = useState([])
  const currentDate = new Date()
  const [currentMonthNumber, setCurrentMonthNumber] = useState(currentDate.getMonth() + 1)
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  const [date, setDate] = useState(new Date())
  const [selectedDays, setSelectedDays] = useState(0)
  const [firstClick, setFirstClick] = useState(true)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    allStats()
  }, [selectedDays])

  const allStats = async () => {
    const data = {
      ...(selectedDays === 0 ? {} : { month: selectedDays }),
    }
    try {
      const response = await agentService.getAgentUsers(data)
      setUsers(response?.agentUsers)
      setTotalEarning(response.totalEarnings)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onPrevious = () => {
    let updatedMonthNumber = currentMonthNumber
    let updatedYear = currentYear

    const newDate = new Date(date)
    newDate.setDate(1)

    if (firstClick) {
      updatedMonthNumber = currentDate.getMonth() + 1
      updatedYear = currentDate.getFullYear()
      setFirstClick(false)
    } else {
      if (updatedMonthNumber === 1) {
        updatedMonthNumber = 12
        updatedYear -= 1
      } else {
        updatedMonthNumber -= 1
      }
    }

    setCurrentMonthNumber(updatedMonthNumber)
    setCurrentYear(updatedYear)

    newDate.setFullYear(updatedYear, updatedMonthNumber - 1, 1)

    setDate(newDate)
    setSelectedDays(newDate)
  }

  const onNext = () => {
    let updatedMonthNumber = currentMonthNumber + 1
    let updatedYear = currentYear

    if (updatedMonthNumber > 12) {
      updatedMonthNumber = 1
      updatedYear += 1
    }
    setCurrentMonthNumber(updatedMonthNumber)
    setCurrentYear(updatedYear)

    const newDate = new Date(date)
    newDate.setMonth(newDate.getMonth() + 1)
    newDate.setDate(1)

    setDate(newDate)
    setSelectedDays(newDate)
  }

  const onClickTotal = () => {
    setSelectedDays(0)
    setCurrentMonthNumber(currentDate.getMonth() + 1)
    setCurrentYear(currentDate.getFullYear())
    setDate(new Date())
    setFirstClick(true)
  }

  const currentMonth = months[currentMonthNumber - 1]

  const onClickAgent = () => {
    setShowModal(true)
  }
  const handleCloseAgentInfo = () => {
    setShowModal(false)
  }

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {showModal ? (
            <ModalForTransactionsExel
              show={showModal}
              handleClose={handleCloseAgentInfo}
              setShowModal={setShowModal}
            />
          ) : null}
          <div className={styles.info}>
            <div>
              <h3 className='me-3'>USERS</h3>
              <span>total: {users?.length}</span>
            </div>
            <div className={styles.totalEarning}>
              <div>
                <button onClick={onClickTotal} className={'btn btn-sm btn-lightblue'}>
                  Total
                </button>
              </div>
              <div className={styles.monthDiv}>
                <MdOutlineNavigateBefore onClick={onPrevious} className={styles.icons} />
                <span className={styles.month}>
                  {selectedDays === 0 ? (
                    'All transactions'
                  ) : (
                    <>
                      {currentMonth} {currentYear}
                    </>
                  )}
                </span>
                <MdOutlineNavigateNext
                  onClick={onNext}
                  className={
                    selectedDays === 0 || date.getMonth() + 1 === currentDate.getMonth() + 1
                      ? styles.hideIcon
                      : styles.icons
                  }
                />
              </div>
              <span className={styles.total}>Total Earning : {totalEarning}$</span>
            </div>
          </div>
          <div className={`${'p-0 pb-3 table-responsive card-body'}`}>
            <table className={`${'table mb-0'}`}>
              <thead className='lightblue-bg'>
                <tr>
                  <th>Username</th>
                  <th>Email</th>
                  <th className='text-center'>Role</th>
                  <th className='text-center'>Agent Commission</th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, index) => (
                  <tr key={index}>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td className='text-center'>{user.role}</td>
                    <td className='text-center'>
                      {user.commissionEarn ? user.commissionEarn + ' $' : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <button onClick={onClickAgent} className='btn btn-lightblue'>
        Download transactions
      </button>
    </div>
  )
}

export default TableAgentUsers
