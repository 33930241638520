import { Bar } from 'react-chartjs-2'
import { Card, CardBody } from 'reactstrap'

import styles from './Chart.module.scss'

import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

const options = {
  maintainAspectRatio: true,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  tooltips: {
    enabled: false,
    custom: false,
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      tension: 0.33,
    },
  },
  scales: {
    x: {
      gridLines: false,
      ticks: {
        display: false,
      },
    },

    y: {
      gridLines: false,
      scaleLabel: false,
      ticks: {
        display: false,
        isplay: false,
      },
    },
  },
}

const BarChartSmall = (data) => {
  return (
    <Card className='mb-4 card-custom px-3'>
      <CardBody className={`${'line-charts-box'} ${styles.barBox} `}>
        <div className='line-charts-heading'>
          <div className='text-center'>
            <span className={`${'line-charts-title d-block mb-4'} ${styles.barChartTitle} `}>
              {data.title}
            </span>
            <h6 className={`${'line-charts-total'} ${styles.barChartTotal}`}>{data.total}</h6>
          </div>
          <div className='line-charts-percent text-center'>
            {data.increase ? (
              <span className={`${'green-increase'} ${styles.barCharPercentage}`}>
                <i className='fa fa-arrow-up' aria-hidden='true'></i> {data.percentage} %
              </span>
            ) : (
              <span className={`${'red-decrease'} ${styles.barCharPercentage}`}>
                <i className='fa fa-arrow-down' aria-hidden='true'></i> {data.percentage} %
              </span>
            )}
          </div>
        </div>
        <Bar type='bar' data={data.data} options={options} redraw={true} />
      </CardBody>
    </Card>
  )
}

export default BarChartSmall
