import httpService from './httpService'
import SessionService from './sessionService'

async function register(user) {
  return httpService.apiRequest('post', '/profile/agentRegister', user)
}

async function registerUser(user) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/agentRegister/user`,
    user,
  )
}

async function getAgents() {
  return httpService.apiRequest('get', `/user/${SessionService.getUserUsername()}/list/agents`)
}

async function getAgentUsers(data) {
  return httpService.apiRequest(
    'post',
    `/user/${SessionService.getUserUsername()}/list/agents/users`,
    data,
  )
}

async function getAgentOrganizations() {
  return httpService.apiRequest(
    'get',
    `/user/${SessionService.getUserUsername()}/list/agents/organizations`,
  )
}

async function acceptOrReject(data) {
  return httpService.apiRequest(
    'post',
    `/user/${SessionService.getUserUsername()}/agent/acceptOrReject`,
    data,
  )
}

async function addCommission(data) {
  return httpService.apiRequest(
    'post',
    `/user/${SessionService.getUserUsername()}/agent/addCommission`,
    data,
  )
}

async function downloadFile(data) {
  return httpService.apiRequest(
    'post',
    `/user/${SessionService.getUserUsername()}/agent/downloadFile`,
    data,
  )
}

export default {
  register,
  getAgents,
  acceptOrReject,
  registerUser,
  getAgentUsers,
  getAgentOrganizations,
  addCommission,
  downloadFile,
}
