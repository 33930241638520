import { useEffect, useState, useRef } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { PaymentElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import { BeatLoader } from 'react-spinners'
import paymentService from '../../services/paymentService'

import styles from './plan.module.scss'
import { stripeKey } from '../../environment'
import { useNavigate } from 'react-router-dom'
const stripePromise = loadStripe(stripeKey)
const options = {
  hideProgressBar: true,
}

const PlanPayment = ({ handleClose, show, plans, planId, organizationId, subscribe = true }) => {
  const [state, setState] = useState({
    clientSecret: null,
    pendingClientSecret: null,
    loading: true,
    planId,
    plans,
    selectedPlan: {},
  })
  const navigate = useNavigate()
  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      const generateStripeSecret = () => {
        paymentService
          .purchase(organizationId, planId, subscribe)
          .then((response) => {
            console.log(response)
            setState((prev) => ({
              ...prev,
              clientSecret: response.clientSecret,
              pendingClientSecret: response.pendingClientSecret,
              loading: false,
            }))
          })
          .catch((error) => {
            toast.error(error.response, options)
          })
      }
      generateStripeSecret()
      getPlanName(planId)
    }
  }, [])

  const stripeOptions = {
    clientSecret: state.clientSecret || state.pendingClientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  }

  const getPlanName = (planId) => {
    const { plans } = state
    if (plans.length > 0) {
      let plan = plans.find((element) => {
        return element.planId === planId
      })

      setState((prev) => ({
        ...prev,
        selectedPlan: plan,
      }))
    }
  }

  const { clientSecret, pendingClientSecret, selectedPlan, loading } = state
  const ConfirmPayment = () => {
    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async (e) => {
      e.preventDefault()
      if (elements === null) {
        return null
      }
      const stripeParams = { elements, redirect: 'if_required', confirmParams: {} }
      const { error } = clientSecret
        ? await stripe.confirmPayment(stripeParams)
        : await stripe.confirmSetup(stripeParams)

      if (error) {
        toast.error('Error purchasing package!', options)
        handleClose()
      } else {
        toast.success('Package is successfully purchased!', options)
        handleClose()
        navigate('/dashboard/organizations')
      }
    }
    return (
      <form onSubmit={handleSubmit} className={styles.form}>
        <PaymentElement />
        <Modal.Footer>
          <button
            className='btn btn-lightblue'
            disabled={!stripe || !elements || loading}
            type='submit'
          >
            Buy now
          </button>
          <button className='btn btn-lightblue' onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </form>
    )
  }
  return (
    <div>
      <Modal show={show} onHide={handleClose} size='md'>
        <Modal.Header className={styles.modalContent} closeButton>
          <Modal.Title>Purchase plan</Modal.Title>
        </Modal.Header>
        {state.loading ? (
          <div className=' m-5 text-center'>
            <BeatLoader size={15} color={'#299bff'} loading={state.loading} />
          </div>
        ) : null}

        <div className={styles.modalContent}>
          <Modal.Body className='purchase-body'>
            <div>
              <ul className='selected-plan-modal'>
                <li>
                  Selected plan: <span>{selectedPlan?.name}</span>
                </li>
                <li>
                  Price: <span>$ {selectedPlan?.price}</span>
                </li>
              </ul>
            </div>
            {clientSecret || pendingClientSecret ? (
              <div>
                <Elements stripe={stripePromise} options={stripeOptions}>
                  <ConfirmPayment />
                </Elements>
              </div>
            ) : null}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}

PlanPayment.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  plans: PropTypes.array,
  planId: PropTypes.string,
  organizationId: PropTypes.string,
  subscribe: PropTypes.bool,
}

export default PlanPayment
