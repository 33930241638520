import { useLocation, useNavigate } from 'react-router-dom'
import styles from './AgentInfoUser.module.scss'
import PageTitle from '../../components/Common/PageTitle'

const AgentInfoUser = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const user = location?.state?.user

  const onClickNo = () => {
    navigate('/dashboard/charts')
  }

  const onClickYes = () => {
    navigate('/dashboard/organization/add', {
      state: { agentUserUsername: user.username, agentUserEmail: user.email, agentCreation: true },
    })
  }

  return (
    <div className='py-4'>
      <PageTitle title='' subtitle='Manage Registred User' className={styles.title} />
      <h3 className={styles.header}>Registred User</h3>
      <div className={styles.infoDiv}>
        <p>Username: </p>
        <h6>{user?.username}</h6>
        <p>Email: </p>
        <h6>{user?.email}</h6>
      </div>
      <hr />
      <div className={styles.question}>
        <p>Do you want to create main organization for this user?</p>
        <div>
          <button onClick={onClickYes} className='btn btn-lightblue me-3'>
            Yes
          </button>
          <button onClick={onClickNo} className='btn btn-danger'>
            No
          </button>
        </div>
      </div>
    </div>
  )
}

export default AgentInfoUser
