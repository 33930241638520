import Layout from './components/Layout/Layout'
import { ToastContainer } from 'react-toastify'
import { MimicUserProvider } from './context/MimicUserContext'

import './App.scss'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <MimicUserProvider>
      <div className='App'>
        <ToastContainer />
        <Layout />
      </div>
    </MimicUserProvider>
  )
}

export default App
