import PropTypes from 'prop-types'
import 'react-quill/dist/quill.snow.css'

import './QuillTextarea.scss'

const QuillReact = ({ value }) => {
  return (
    <div
      className='quillReact'
      dangerouslySetInnerHTML={{
        __html: value,
      }}
    />
  )
}

export default QuillReact

QuillReact.propTypes = {
  value: PropTypes.any,
}
