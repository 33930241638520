import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './BundleCreation.module.scss'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import plansAndBundlesService from '../../services/plansAndBundlesService'
import { toast } from 'react-toastify'
import QuillTextarea from '../QuillTextarea/QuillTextarea'

const BundleCreation = () => {
  const [bundle, setBundle] = useState({
    name: '',
    minutes: 0,
    sms: 0,
    duration: 'monthly',
    price: '',
    type: 'minutes',
    description: '',
  })
  const [selectedField, setSelectedField] = useState('minutes')
  const [error, setError] = useState('')

  const { bundleId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (bundleId) {
      plansAndBundlesService
        .getBundle(bundleId)
        .then((response) => {
          setBundle(response)
        })
        .catch(() => {})
    }
  }, [])

  const handleFieldChange = (e) => {
    const value = e.target.value
    setSelectedField(value)
    if (value === 'sms') {
      setBundle({ ...bundle, minutes: 0 })
      setBundle((prevBundle) => ({
        ...prevBundle,
        minutes: 0,
        type: 'sms',
      }))
    } else if (value === 'minutes') {
      setBundle({ ...bundle, sms: 0 })
      setBundle((prevBundle) => ({
        ...prevBundle,
        sms: 0,
        type: 'minutes',
      }))
    }
  }

  const handleChange = (e) => {
    const name = e.target.name
    let value = e.target.value

    if (name === 'name' || name === 'duration') {
      value = String(value)
    } else if (name === 'price') {
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1)
      }
    } else {
      value = Number(value)
    }

    setBundle({ ...bundle, [name]: value })
  }

  const handleChangeDescription = (value) => {
    setBundle({ ...bundle, description: value })
  }

  // const handleDurationChange = (e) => {
  //   const value = e.target.value
  //   setBundle({ ...bundle, duration: value })
  // }

  const validateForm = () => {
    const selectedValue =
      selectedField === 'minutes' && bundle.type === 'minutes' ? bundle.minutes : bundle.sms

    if (
      !bundle.name.trim() ||
      !selectedValue ||
      // !bundle.duration ||
      !bundle.price ||
      !bundle.description
    ) {
      setError('All inputs are required')
      return false
    }

    setError('')
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm()) {
      if (bundleId) {
        plansAndBundlesService
          .updateBundle(bundle)
          .then(() => {
            toast.success('Bundle is successfully updated!')
            navigate('/dashboard/plans-bundles')
          })
          .catch(() => {
            toast.error('Something went wrong, try again')
          })
      } else {
        plansAndBundlesService
          .createBundle(bundle)
          .then(() => {
            toast.success('Budnle is successfully created!')
            navigate('/dashboard/plans-bundles')
          })
          .catch(() => {
            toast.error('Something went wrong, try again')
          })
      }
    }
  }

  const handleNumber = (e) => {
    const inputValue = e.target.value
    e.target.value = inputValue.replace(/\D/g, '')
  }

  return (
    <div className='py-4'>
      <div className={styles.wrapper}>
        {bundleId ? <h2>Edit Bundle</h2> : <h1>Create Your Bundle</h1>}
        <div className={styles.fromWrapper}>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputBox}>
              <label>Name</label>
              <input
                onChange={handleChange}
                name='name'
                className='form-control'
                value={bundle.name}
              />
            </div>
            <div className={styles.selectBox}>
              <label>Minutes or SMS</label>
              <select value={bundle.type} onChange={handleFieldChange} className='form-control'>
                <option value='minutes'>Minutes</option>
                <option value='sms'>SMS</option>
              </select>
              <MdOutlineKeyboardArrowDown className={styles.arrowDown} />
            </div>

            <div className={styles.inputBox}>
              <label>
                {selectedField === 'minutes' && bundle.type === 'minutes' ? 'Minutes' : 'SMS'}
              </label>
              <input
                name={selectedField === 'minutes' && bundle.type === 'minutes' ? 'minutes' : 'sms'}
                className='form-control'
                onChange={handleChange}
                onInput={handleNumber}
                value={
                  selectedField === 'minutes' && bundle.type === 'minutes'
                    ? bundle.minutes
                    : bundle.sms
                }
              />
            </div>
            {/* <div className={styles.selectBox}>
              <label>Duration</label>
              <select
                value={bundle.duration}
                onChange={handleDurationChange}
                className={`${'form-control'} ${styles.selectElement} `}
              >
                <option value='forever'>Forever</option>
                <option value='daily'>Daily</option>
                <option value='monthly'>Monthly</option>
                <option value='yearly'>Yearly</option>
              </select>
              <MdOutlineKeyboardArrowDown className={styles.arrowDown} />
            </div> */}
            <div className={styles.inputBox}>
              <label>Price</label>
              <input
                onChange={handleChange}
                name='price'
                className='form-control mb-4'
                value={bundle.price}
                type='number'
              />
            </div>
            <QuillTextarea
              handleChangeDescription={handleChangeDescription}
              value={bundle.description}
            />
            {error && <div className={'alert alert-danger mt-2'}>{error}</div>}

            {bundleId ? (
              <button className={` ${'btn btn-lightblue form-control'} ${styles.formBtn} `}>
                Edit
              </button>
            ) : (
              <button className={` ${'btn btn-lightblue form-control'} ${styles.formBtn} `}>
                Create
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default BundleCreation
