import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import ChangePassword from './ChangePassword'
import ChangeEmail from './ChangeEmail'
import profileService from '../../services/profileService'
import QrCode from '../../components/Common/Modals/Qrcode'
import { confirm } from '../../components/Common/Modals/ConfirmService'
import PageTitle from '../../components/Common/PageTitle'

import styles from './profile.module.scss'
import BindAccount from '../../components/BindAccount/BindAccount'

const Profile = () => {
  const [showModalQrCode, setShowModalQrCode] = useState(false)
  const [user, setUser] = useState({ username: '', email: '', accountId: '', accountNumber: '' })

  useEffect(() => {
    getUser()
  }, [])

  const getUser = () => {
    profileService
      .refreshProfile()
      .then((response) => {
        setUser({
          username: response.username,
          email: response.email,
          accountId: response.accountId ? response.accountId : null,
          accountNumber: response.accountNumber ? response.accountNumber : null,
        })
      })
      .catch((error) => {
        toast.error(error.response)
      })
  }

  const handleClose = () => {
    setShowModalQrCode(false)
    getUser()
  }

  // const handleShowQrCodeModal = () => {
  //   setShowModalQrCode(true)
  // }

  const handleUnbind = () => {
    confirm('Are you sure you want to unbind the account from the profile?').then(
      () => {
        profileService
          .disconnectAccount()
          .then(() => {
            toast.success('Account unbinded successuffuly!')
            getUser()
          })
          .catch((error) => {
            toast.error(error.response)
          })
      },
      () => {},
    )
  }

  return (
    <div className={`${'wrapper profile-page-container'} ${styles.wrapperContainer}`}>
      {showModalQrCode ? <QrCode show={showModalQrCode} handleClose={handleClose} /> : null}
      <div className='profile'>
        <Row className='page-header py-4 justify-content-center'>
          <PageTitle sm='4' title='My account' className='text-md-center' />
        </Row>
        <div className='border-bottom'></div>
        <Row className='py-4 justify-content-center'>
          <Col sm='12' md='10' xl='10'>
            <ChangeEmail />
            <ChangePassword />
            {user.accountId ? null : <BindAccount />}
            <div>
              <ul>
                {user.accountId ? (
                  <li className={styles.bind}>
                    <div className={styles.accountInfo}>
                      <span>Application number: </span>
                      <span>{user.accountNumber} </span>
                    </div>
                    <div>
                      <span className='clearfix'>
                        <button
                          className='btn btn-lightblue pull-right mb-3'
                          onClick={handleUnbind}
                        >
                          Unbind
                        </button>
                      </span>
                    </div>
                  </li>
                ) : (
                  <li>
                    {/* <div className='d-flex flex-row'>
                      <h6 className={`${'mt-3 mb-3 grey clearfix'} ${styles.spanEdit}`}>
                        Application number
                      </h6>
                      <span className='pull-right d-flex align-items-center'>
                        <button
                          className={`${'btn btn-lightblue'} ${styles.updateBtn}`}
                          onClick={handleShowQrCodeModal}
                        >
                          Unbind
                        </button>
                      </span>
                    </div> */}
                  </li>
                )}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Profile
