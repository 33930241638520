import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import profileService from '../../services/profileService'
// import { confirm } from '../../components/Common/Modals/ConfirmService'
import sessionService from '../../services/sessionService'
// import QrCode from '../../components/Common/Modals/Qrcode'
import logo from '../../assets/images/logo.png'

import styles from './Register.module.scss'

const usernameRGEX = /^[a-zA-Z][a-zA-Z0-9_]{3,}[a-zA-Z0-9]$/

const Register = () => {
  const { hash } = useParams()

  const [user, setUser] = useState({ username: '', email: '', password: '', hash: hash || '' })
  const [error, setError] = useState('')
  // const [showModalQrCode, setShowModalQrCode] = useState(false)
  const [options] = useState({ autoClose: 3000, hideProgressBar: true })

  const navigate = useNavigate()

  const handleChange = (event) => {
    const name = event.target.name
    let value = event.target.value
    if ('username' === name) {
      value = value.toLowerCase()
    }
    setUser({ ...user, [name]: value, error: null })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const error = validateForm()
    if (!error) {
      profileService
        .register(user)
        .then((response) => {
          sessionService.create(response)
          // Comented QR CODE SCANNING DURING REGISTRATION
          // confirm(
          //   'If you have already Mobile App Free Account, you can connect it with paid account.Do you want to connect your Free App account?',
          // ).then(
          //   () => {
          //     setShowModalQrCode(true)
          //     toast.success('Account is successfully created!', options)
          //   },
          //   () => {
          //     navigate('/dashboard/charts')
          //     toast.success('Account is successfully created!', options)
          //   },
          // )
          navigate('/dashboard/charts')
          toast.success('Account is successfully created!', options)
        })
        .catch(() => {})
    } else {
      setError({ error })
    }
  }

  const validateForm = () => {
    if (!usernameRGEX.test(user.username)) {
      return 'Username need to start with letter, it need to be at least 5 characters long, can have letters, numbers and underscore and it need to end with letter or number'
    } else if (!user.email) {
      return 'email is required'
    } else if (!user.password || user.password.length < 5) {
      return 'password need to be longer then 5 characters'
    }
    return null
  }

  // const handleClose = () => {
  //   setShowModalQrCode(false)
  //   navigate('/dashboard/charts')
  // }

  return (
    <div className='auth-page-container container-fluid'>
      {/* {showModalQrCode ? <QrCode show={showModalQrCode} handleClose={handleClose} /> : null} */}
      <div className={`${'auth-frame ps-5 pe-5 fs-9'} ${styles.headerBox}`}>
        <div className='auth-img-box pt-5 pb-4 '>
          <img className={styles.logo} src={logo} alt='Air Touch' />
        </div>
        <form onSubmit={handleSubmit} className='text-start'>
          <div className='form-group mb-2'>
            <label className={`${'form-label'} ${styles.labelInp}`} htmlFor='username'>
              Username:
            </label>
            <input
              id='username'
              className={`${'form-control border-0'} ${styles.inputBgColor}`}
              type='text'
              name='username'
              value={user.username}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-group mb-2'>
            <label className={styles.labelInp} htmlFor='email'>
              Email:
            </label>
            <input
              id='email'
              className={`${'form-control mt-2 border-0'} ${styles.inputBgColor}`}
              type='text'
              name='email'
              value={user.email}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className='form-group mb-2'>
            <label className={styles.labelInp} htmlFor='password'>
              Password:
            </label>
            <input
              id='password'
              className={`${'form-control mt-2 mb-3 border-0'} ${styles.inputBgColor}`}
              type='password'
              name='password'
              value={user.password}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {error && <div className={'alert alert-danger'}>{error.error}</div>}
          <div className='text-end'>
            <button type='submit' className={`${'btn mb-3'} ${styles.registerBtn}`}>
              Register
            </button>
          </div>
        </form>
        {hash ? null : (
          <div className='auth-footer'>
            <div className='pb-5'>
              <span className={styles.footerSpan}>Go back to </span>{' '}
              <Link className={`${'text-info'} ${styles.link}`} to='/login'>
                Login.
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Register
