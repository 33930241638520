import Plans from '../../components/Plans/Plans'
import PageTitle from '../../components/Common/PageTitle/PageTitle'

import styles from './PlansAndSubscription.module.scss'

const PlansAndSubscription = () => {
  return (
    <div className='py-4'>
      <PageTitle title='' subtitle='Manage Plans and Bundles' className={styles.title} />
      <h3 className={styles.header}>Plans & Bundles</h3>
      <Plans />
    </div>
  )
}

export default PlansAndSubscription
