import styles from './RequestedPage.module.scss'
import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'

const RequestedPage = () => {
  return (
    <div>
      <div className='auth-page-container container-fluid'>
        <div className={`${'auth-frame ps-5 pe-5 fs-9 pb-5'}`}>
          <div className='auth-img-box pt-5 pb-4 '>
            <img className={styles.logo} src={logo} alt='Air Touch' />
          </div>
          <h4>
            Your request has been sent. <br /> We will send you an answer on email
          </h4>
          <div className='auth-footer'>
            <div className='pb-3 pt-3'>
              <span className={styles.footerSpan}>Go back to </span>{' '}
              <Link className={`${'text-info'} ${styles.link}`} to='/login'>
                Login.
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestedPage
