import { useState, useEffect } from 'react'

import { Card, CardHeader, CardBody } from 'reactstrap'
import { MdCheckCircleOutline } from 'react-icons/md'
import { TbBan, TbTrash } from 'react-icons/tb'
import dayjs from 'dayjs'
import PageTitle from '../../components/Common/PageTitle'
import { confirm } from '../../components/Common/Modals/ConfirmService'
import toastService from '../../services/toastService'
import usersAndAccountsService from '../../services/usersAndAccountsService'
import { ADMIN_TYPES } from '../../constants'

import styles from './MasterAdmins.module.scss'
import Loader from '../../components/Loader/Loader'

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const MasterAdmins = () => {
  const [email, setEmail] = useState('')
  const [masterAdminInvites, setMasterAdminIvites] = useState([])
  const [emailError, setEmailError] = useState('')
  const [masterAdmins, setMasterAdmins] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handleChange = (e) => {
    const { value } = e.target
    setEmail(value)
    setEmailError('')
  }

  const sendInvite = async () => {
    try {
      if (!emailRegex.test(email)) {
        setEmailError('Invalid email format')
        return
      }
      await usersAndAccountsService.sendMasterAdminInvite({
        email,
        type: ADMIN_TYPES.masterAdmin,
      })
      setEmail('')
      toastService.show('success', `Invite sent successfully to ${email}`)
      const invitedUsers = await usersAndAccountsService.getMasterAdminIvites()
      setMasterAdminIvites(invitedUsers)
    } catch (err) {
      toastService.show('error', 'Error send invite')
    }
  }

  useEffect(() => {
    usersAndAccountsService
      .getMasterAdminIvites()
      .then((invites) => {
        setMasterAdminIvites(invites)
      })
      .catch(() => {
        toastService.show('error', 'Error get master admin invites')
      })
      .finally(() => setIsLoading(false))
    usersAndAccountsService
      .getMasterAdmins()
      .then((admins) => {
        setMasterAdmins(admins)
      })
      .catch(() => {
        toastService.show('error', 'Error get master admins')
      })
      .finally(() => setIsLoading(false))
  }, [])

  const handleRemoveInvite = (invite) => {
    confirm(`Are you sure you want to delete invite ${invite?.email}?`).then(
      () => {
        usersAndAccountsService
          .deleteMasterAdminInvite(invite?._id)
          .then(() => {
            toastService.show('success', `Invite ${invite?.email} deleted successuffuly!`)
            const filteredPendingInvites = masterAdminInvites?.filter(
              (item) => item?._id !== invite?._id,
            )
            setMasterAdminIvites(filteredPendingInvites)
          })
          .catch(() => {
            toastService.show('error', `Error remove invite ${invite?.email}`)
          })
      },
      () => {},
    )
  }

  const handleActivateDeactivateMasterAdmin = (admin) => {
    if (!admin?.username) return
    const message = admin?.status === 1 ? 'deactivate' : 'activate'
    confirm(`Are you sure you want to ${message} master admin ${admin?.username}?`).then(
      async () => {
        try {
          const { user } = await usersAndAccountsService.activateDeactivateMasterAdmin(
            admin.username,
            admin.status,
          )
          const newUsers = masterAdmins.map((item) => (item._id === admin._id ? user : item))
          toastService.show('success', `Successuffuly ${message}d ${admin?.username}`)
          setMasterAdmins(newUsers)
        } catch (err) {
          toastService.show('error', `Error ${message} ${admin?.useranme}`)
        }
      },
      () => {},
    )
  }

  return (
    <div className='py-4'>
      <PageTitle title='' subtitle='Manage Master admin' className={styles.title} />
      <h3 className={styles.header}>Master admins</h3>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <Card className='mb-4 card-custom'>
            <CardHeader className='border-bottom card-header-custom'>
              <h6 className='m-0'>
                <div className='search-box'>
                  <label className='pb-2' htmlFor=''>
                    Invite master admin
                  </label>
                  <div className=''>
                    <div className='form-organization'>
                      <div className='custom-form-inline'>
                        <input
                          style={{ background: '#121621' }}
                          id='email'
                          className='form-control bordered'
                          type='text'
                          name='email'
                          value={email}
                          placeholder='Enter email'
                          onChange={handleChange}
                        />
                        <button className='btn lightblue pull-right bt-w-95' onClick={sendInvite}>
                          Invite
                        </button>
                      </div>
                    </div>
                    {emailError && <div className={'alert alert-danger'}>{emailError}</div>}
                  </div>
                </div>
              </h6>
            </CardHeader>
            {masterAdminInvites?.length ? (
              <>
                <h6 className='m-2'>Pending master admin users</h6>
                <CardBody className='p-0 pb-3 table-responsive'>
                  <table className='table  mb-0'>
                    <thead className='lightblue-bg'>
                      <tr>
                        <th className='border-0'> #</th>
                        <th className='border-0'>Email</th>
                        <th className='border-0'>Invited at</th>
                        <th className='border-0 '>Status</th>
                        <th className='border-0 text-center'>Remove </th>
                      </tr>
                    </thead>
                    <tbody>
                      {masterAdminInvites?.map((invite, i) => (
                        <tr className={styles.tr} key={invite._id}>
                          <td>{i + 1}</td>
                          <td>{invite?.email}</td>
                          <td>{dayjs(invite.createAt).format('YYYY-MM-DD hh:mm:ss')}</td>
                          <td>{invite.status}</td>
                          <td className='th-fa-icons text-center'>
                            <TbTrash onClick={() => handleRemoveInvite(invite)} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </>
            ) : null}
            {masterAdmins?.length ? (
              <>
                <h6 className='m-2'>Master admin users</h6>
                <CardBody className='p-0 pb-3 table-responsive'>
                  <table className='table  mb-0'>
                    <thead className='lightblue-bg'>
                      <tr>
                        <th className='border-0'> #</th>
                        <th className='border-0'>Username</th>
                        <th className='border-0'>Email</th>
                        <th className='border-0 '>Status</th>
                        <th className='border-0 '>Role</th>
                        <th className='border-0 text-center'>Activate / Deactivate </th>
                      </tr>
                    </thead>
                    <tbody>
                      {masterAdmins.map((admin, i) => (
                        <tr className={styles.tr} key={admin._id}>
                          <td>{i + 1}</td>
                          <td>{admin?.username}</td>
                          <td>{admin?.email}</td>
                          <td>{admin?.status === 1 ? 'Active' : 'Inactive'}</td>
                          <td>{admin?.role}</td>
                          <td className='th-fa-icons text-center'>
                            {admin?.status === 1 ? (
                              <MdCheckCircleOutline
                                onClick={() => handleActivateDeactivateMasterAdmin(admin)}
                              />
                            ) : (
                              <TbBan onClick={() => handleActivateDeactivateMasterAdmin(admin)} />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </>
            ) : null}
          </Card>
        )}
      </div>
    </div>
  )
}

export default MasterAdmins
