import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { Card, CardBody, Col, Row } from 'reactstrap'
import AddMinutes from '../../components/Common/Modals/AddMinutes'
import { confirm } from '../../components/Common/Modals/ConfirmService'
import PageTitle from '../../components/Common/PageTitle/PageTitle'
import Stepper from '../../components/Common/Stepper'
import QuillReact from '../../components/QuillTextarea/QuillReact'

import organizationService from '../../services/organizationService'
import plansAndBundlesService from '../../services/plansAndBundlesService'

import paymentService from '../../services/paymentService'
import PlanPayment from './Payment'
import Loader from '../../components/Loader/Loader'

import 'react-quill/dist/quill.snow.css'
import styles from './plan.module.scss'
import { PLAN_TYPES } from '../../constants'
import toastService from '../../services/toastService'

const options = {
  hideProgressBar: true,
}

const Plan = () => {
  const [state, setState] = useState({
    plans: [],
    organization: {},
    showModalMinut: false,
    showModalPayment: false,
    error: null,
    activeClass: '',
    loading: true,
    subscribe: false,
    planId: '',
  })

  const { organizationId } = useParams()

  useEffect(() => {
    loadOrganizationById()
    loadPlans()
  }, [])

  const loadOrganizationById = () => {
    organizationService
      .getById(organizationId)
      .then((result) => {
        setState((prev) => ({
          ...prev,
          organization: result,
          name: result.name,
          activeClass: result.subscription ? result.subscription.planId : null,
          subscribe: result?.subscription?.neverExpires
            ? result?.subscription?.neverExpires
            : false,
          planId: result.subscription ? result.subscription.planId : '',
        }))
      })
      .catch(() => {
        toast.error('Error loading organization', options)
      })
  }

  const loadPlans = () => {
    plansAndBundlesService
      .getPlans()
      .then((result) => {
        setState((prev) => ({
          ...prev,
          loading: false,
          plans: result,
        }))
      })
      .catch(() => {})
  }

  // const getPlanName = (planId) => {
  //   const plans = state.plans
  //   if (plans.length > 0) {
  //     const planName = plans.filter((element) => {
  //       return element.id === planId
  //     })
  //     return planName[0].name
  //   }
  // }

  const cancelPlan = () => {
    confirm('Are you sure you want to cancel current plan?').then(
      () => {
        paymentService
          .cancelPurchase(state.organization.subscription.id, state.organization, null)
          .then(async () => {
            toast.success('Package is successfully cancaled!', options)
            await loadOrganizationById()
          })
          .catch((error) => {
            toast.error(error.response, options)
          })
      },
      () => {},
    )
  }

  const handleChange = (event) => {
    const target = event.target
    let organization = { ...state.organization }
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const planId = name === 'planId' ? value : state?.planId
    const selectedPlan = state?.plans?.find((plan) => plan?.planId === planId)
    let disableSubscribe = selectedPlan?.type === PLAN_TYPES.trial
    let subscribeObject = {
      subscribe: disableSubscribe ? false : name === 'subscribe' ? value : state.subscribe,
    }
    if (disableSubscribe && name === 'subscribe') {
      toastService.show('error', 'You are not able to subscribe to the trial plan')
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
      activeClass: value,
      error: null,
      organization: organization,
      ...subscribeObject,
    }))
  }

  const isChecked = (planId) => {
    return state?.planId === planId
  }
  const handleShowModalMinut = () => {
    setState((prev) => ({
      ...prev,
      showModalMinut: true,
    }))
  }

  const handleClose = () => {
    if (state.showModalMinut) {
      setState((prev) => ({ ...prev, showModalMinut: false }))
    } else if (state.showModalPayment) {
      setState((prev) => ({ ...prev, showModalPayment: false }))
    }
  }

  const handleShowModalPayment = () => {
    if (isValid()) {
      setState((prev) => ({
        ...prev,
        showModalPayment: true,
      }))
    } else {
      setState((prev) => ({
        ...prev,
        error: 'You must select plan!',
      }))
    }
  }

  const isValid = () => {
    return state.planId
  }

  const getPlanByPlanId = (planId) => {
    return state?.plans?.find((plan) => plan.planId === planId)
  }

  const { organization, planId, plans, showModalMinut, showModalPayment, error, subscribe } = state

  if (state?.loading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  const selectedPlan = getPlanByPlanId(planId)

  const isOnlyTrialPlan = plans?.filter((plan) => plan.type === PLAN_TYPES.trial) || []

  return (
    <div>
      {showModalMinut ? (
        <AddMinutes
          show={showModalMinut}
          handleClose={handleClose}
          organizationId={organization._id}
        />
      ) : null}
      {showModalPayment ? (
        <PlanPayment
          show={showModalPayment}
          handleClose={handleClose}
          planId={planId}
          plans={plans}
          organizationId={organization._id}
          subscribe={subscribe}
        />
      ) : null}

      <div className='curent-plan'>
        {organization.subscription ? (
          <Row className='page-header py-4'>
            <PageTitle
              title={`Current plan: ${organization.subscription.planName}`}
              subtitle='Subscription plan management'
              className='text-sm-left'
            />
            <Row className='curent-plan-options'>
              <Col sm='12' lg='6'>
                <div className='subtitle-border-bottom h-100'>
                  <ul>
                    <li>
                      <p>Selected organization</p> <span> {organization.name}</span>
                    </li>
                    <li>
                      <p>Automatic billing</p>{' '}
                      <span>{organization.subscription.neverExpires ? 'ON' : 'OFF'}</span>
                    </li>
                    <li>
                      <p>Available call credits</p>{' '}
                      <span>
                        {organization.limit !== 0
                          ? Math.floor(organization.limit / 60)
                          : organization.limit}
                      </span>
                    </li>
                    <li>
                      <p>Additional call credits</p>{' '}
                      <span>
                        {organization.extraLimit !== 0
                          ? Math.floor(organization.extraLimit / 60)
                          : organization.extraLimit}
                      </span>
                    </li>
                    <li>
                      <p>Available SMS</p> <span>{organization?.sms}</span>
                    </li>
                    <li>
                      <p>Additional SMS</p>
                      <span>{organization?.extraSms}</span>
                    </li>
                    <li>
                      <p>Expiration date </p>
                      <span>
                        {!organization.subscription.neverExpires
                          ? organization.subscription.firstBillingDate
                          : 'N/A'}
                      </span>
                    </li>
                    <li>
                      <p>VoIP Number</p>{' '}
                      <span>{organization?.twilioNumber ? organization?.twilioNumber : 'N/A'}</span>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col sm='12' lg='6'>
                <div className='curent-plan-right'>
                  {organization?.subscription?.neverExpires ? (
                    <div className=''>
                      <button className='btn btn-lightblue' onClick={cancelPlan}>
                        Cancel subscription
                      </button>
                      <p>
                        You can still use the same service until expiration date. If you want to
                        keep the VoIP number assigned to the subscription, then you will be required
                        to at least subscribe to the GCP Starter Kit Plan at $0.99/month.
                      </p>
                      <hr />
                    </div>
                  ) : null}
                  <div className=''>
                    <button className='btn btn-lightblue' onClick={handleShowModalMinut}>
                      Purchase more voice minutes or SMS messages
                    </button>
                    <p>
                      Add more minutes or messages to your current plan. This will not affect your
                      subscription, but will simply add more calling minutes or more SMS messages to
                      your plan. Additional minutes and messages expire with your plan expiration.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        ) : (
          <div>
            <Row className='page-header pt-4'>
              <PageTitle title='Purchase a plan' subtitle='Subscription' className='text-sm-left' />
              <Col sm='12' md='12' lg='8' xl='6'>
                <p className='m-0 light-grey'>
                  Associate a purchase plan with your organization. Purchased plan and minutes will
                  be applicable only for this organization.
                </p>
              </Col>
            </Row>
            <Stepper step={2} />
          </div>
        )}
      </div>

      {organization.subscription ? (
        <h3 className='mb-3'>Change your subscription plan for organization </h3>
      ) : null}
      {(!selectedPlan || selectedPlan?.type !== PLAN_TYPES.trial) &&
      isOnlyTrialPlan.length !== plans.length ? (
        <div className='form-organization'>
          <input
            className='custom-checkbox'
            type='checkbox'
            name='subscribe'
            id='subscribe'
            onChange={handleChange}
            checked={subscribe}
            value={subscribe}
          />
          <label htmlFor='subscribe' className='m-0'>
            {' '}
            Automatic billing
          </label>
          <p className='small'>
            By selecting “Automatic Billing”, your selected subscription plan will automatically
            renew every 30 days.
          </p>
        </div>
      ) : null}
      {state.loading ? (
        <div className=' mb-5'>
          <Row>
            <Col sm='12' md='12' lg='8' xl='6'>
              <BeatLoader
                // css={override}
                // sizeUnit={'px'}
                size={15}
                color={'#299bff'}
                loading={state.loading}
              />
            </Col>
          </Row>
        </div>
      ) : null}
      {plans.map((plan, ind) => (
        <Row key={plan._id} className={styles.rowBox}>
          <Col sm='12' md='12' lg='8' xl='6' className={styles.paymentBox}>
            <Card
              className={
                state.activeClass === plan.id
                  ? `${'active-plan-card card-custom card-plan plan-cards-box box-shadow-no border-gray'} ${
                      styles.planBox
                    }`
                  : `${'card-custom card-plan plan-cards-box box-shadow-no border-gray'} ${
                      styles.planBox
                    }`
              }
            >
              <CardBody className={styles.cardBodyBox}>
                <div className='plan-card-box'>
                  <div className='plan-card-name'>
                    <span>{ind + 1}</span>
                  </div>
                  <div className='plan-card-desc'>
                    <div>
                      <h6>
                        {plan.name} <span className='lightblue'>{plan.price}$</span>
                      </h6>
                      <QuillReact value={plan.description || ''} />
                    </div>
                    <div className='custom-radio-container'>
                      <input
                        id={`sel-${plan.planId}`}
                        type='radio'
                        value={plan.planId}
                        name='planId'
                        checked={isChecked(plan.planId)}
                        className='custom-radio'
                        onChange={handleChange}
                      />
                      <label htmlFor={`sel-${plan.planId}`}> </label>
                      <div className='check'>
                        <div className='inside'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            {planId === plan.planId &&
            (isChecked(plan.planId) || !organization?.subscription?.planId) ? (
              <div className='text-center d-flex align-items-center pb-4'>
                <button
                  className={`${'btn btn-primary'} ${styles.prceedBtn} `}
                  onClick={handleShowModalPayment}
                >
                  Proceed to payment
                </button>
              </div>
            ) : null}
          </Col>
        </Row>
      ))}
      {error && <div className={'alert alert-danger'}>{error}</div>}
      {/* {!this.state.loading ?
          <Row >
            <Col sm="12" md="12" lg="8" xl="6">
              <div className="pull-right">
                <button className="btn btn-purple" onClick={this.handleShowModalPayment}>Proceed to payment</button>
              </div>
            </Col>
          </Row> : null} */}
    </div>
  )
}

export default Plan
