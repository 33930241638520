import httpService from './httpService'
import SessionService from './sessionService'

async function sendTransferData(data) {
  return httpService.apiRequest(
    'post',
    `/twilio/${SessionService.getUserUsername()}/newCall/transferCallLine`,
    data,
  )
}

async function transferCallLineToUser(data) {
  return httpService.apiRequest(
    'post',
    `/twilio/${SessionService.getUserUsername()}/newCall/transferCallLineToUser`,
    data,
  )
}

export default { sendTransferData, transferCallLineToUser }
