/**
 * Stores user json object ot local storage
 * @param user
 */
function create(user) {
  localStorage.setItem('user', JSON.stringify(user))
}

/**
 * Destroy user object from local storage and redirects to login
 */
function destroy() {
  localStorage.removeItem('user')
}

const isData = (data) => {
  return data && data !== 'undefined'
}

function getUserId() {
  const data = localStorage.getItem('user')
  if (!isData(data)) return null
  var user = JSON.parse(data)
  return user ? user?.user?._id : ''
}

function getUserUsername() {
  const data = localStorage.getItem('user')
  if (!isData(data)) return null
  var user = JSON.parse(data)
  return user ? user?.user?.username : ''
}
function getUserEmail() {
  const data = localStorage.getItem('user')
  if (!isData(data)) return null
  var user = JSON.parse(data)
  return user ? user?.user?.email : ''
}
function getUser() {
  const data = localStorage.getItem('user')
  if (!isData(data)) return null
  var user = JSON.parse(data)
  return user ? user.user : {}
}
function getUserRole() {
  const data = localStorage.getItem('user')
  if (!isData(data)) return null
  var user = JSON.parse(data)
  return user ? user?.user?.role : ''
}

function getSessionToken() {
  const data = localStorage.getItem('user')
  if (!isData(data)) return null
  const session = JSON.parse(data)
  return session && session.token ? session.token : null
}

function updateUserEmail(data) {
  let user = JSON.parse(localStorage.getItem('user'))
  user.user.email = data.email
  localStorage.setItem('user', JSON.stringify(user))
}
function updateOffices(data) {
  let user = JSON.parse(localStorage.getItem('user'))
  user.user.offices = []
  user.user.offices = data.offices
  localStorage.setItem('user', JSON.stringify(user))
}
function isAdmin() {
  const data = localStorage.getItem('user')
  if (!isData(data)) return null
  var admin = JSON.parse(data).user
  return admin ? admin.isAdmin : ''
}

function isAuth() {
  const data = localStorage.getItem('user')
  if (!isData(data)) {
    return false
  } else {
    var session = JSON.parse(data)
    return session.token
  }
}

function updateUser(userData) {
  let user = JSON.parse(localStorage.getItem('user'))
  user.user = userData
  localStorage.setItem('user', JSON.stringify(user))
}

export default {
  create,
  destroy,
  getUserId,
  getUserUsername,
  getUser,
  getUserRole,
  getSessionToken,
  isAdmin,
  isAuth,
  getUserEmail,
  updateUserEmail,
  updateOffices,
  updateUser,
}
