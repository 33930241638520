import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import { useState } from 'react'
import agentService from '../../services/agentService'
import toastService from '../../services/toastService'
import styles from './Agents.module.scss'

const RejectIinput = ({ show, handleClose, user, setusersModalShow, setAgents }) => {
  const [message, setMessage] = useState('')

  const handleChangeMsg = (event) => {
    setMessage(event.target.value)
  }

  const handleReject = () => {
    if (!user._id) return
    try {
      agentService
        .acceptOrReject({
          username: user.username,
          requestedStatus: user.requestedStatus,
          email: user.email,
          message: message,
        })
        .then((response) => {
          setAgents((prevAgent) => {
            return prevAgent.map((mapAgent) =>
              mapAgent._id === user._id
                ? { ...mapAgent, requestedStatus: response.user.requestedStatus }
                : mapAgent,
            )
          })
        })
      toastService.show('success', `Agent is successfully ${user.requestedStatus}!`)
      setusersModalShow(false)
    } catch (error) {
      toastService.show('error', 'Error updating user status' + error?.message || '')
    }
  }

  return (
    <div>
      <Modal size='md' show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title>Please enter the reasons for rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.infoWrapper}>
            <div className='form-organization'>
              <div className='custom-form-inline'>
                <input
                  style={{ background: '#121621' }}
                  id='message'
                  className='form-control bordered'
                  type='text'
                  name='message'
                  placeholder='message'
                  onChange={handleChangeMsg}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.oganizationBgColor}>
          <button onClick={handleReject} className='btn btn-lightblue'>
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default RejectIinput
RejectIinput.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setusersModalShow: PropTypes.func.isRequired,
  setAgents: PropTypes.func.isRequired,
}
