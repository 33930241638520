import httpService from './httpService'
import SessionService from './sessionService'

async function createPlan(plan) {
  return httpService.apiRequest('post', `/plan/${SessionService.getUserUsername()}/create`, plan)
}

async function getPlans() {
  return httpService.apiRequest('get', `/plan/${SessionService.getUserUsername()}/list`)
}

async function getPlan(planId) {
  return httpService.apiRequest('get', `/plan/${SessionService.getUserUsername()}/${planId}`)
}

async function updatePlan(plan) {
  return httpService.apiRequest('post', `/plan/${SessionService.getUserUsername()}/update`, plan)
}

async function deletePlan(planId) {
  return httpService.apiRequest('delete', `/plan/${SessionService.getUserUsername()}/${planId}`)
}

async function createBundle(budnle) {
  return httpService.apiRequest(
    'post',
    `/bundle/${SessionService.getUserUsername()}/create`,
    budnle,
  )
}

async function getBundles() {
  return httpService.apiRequest('get', `/bundle/${SessionService.getUserUsername()}/list`)
}

async function getBundle(bundleId) {
  return httpService.apiRequest('get', `/bundle/${SessionService.getUserUsername()}/${bundleId}`)
}

async function updateBundle(budnle) {
  return httpService.apiRequest(
    'post',
    `/bundle/${SessionService.getUserUsername()}/update`,
    budnle,
  )
}

async function deleteBundle(budnleId) {
  return httpService.apiRequest('delete', `/bundle/${SessionService.getUserUsername()}/${budnleId}`)
}

export default {
  createPlan,
  getPlans,
  getPlan,
  updatePlan,
  createBundle,
  getBundles,
  getBundle,
  updateBundle,
  deletePlan,
  deleteBundle,
}
