import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/Sidebar/Sidebar'
import Socket from '../../sockets/socket'

const Dashboard = () => {
  return (
    <>
      <Socket />
      <Sidebar />
      <div className='container-fluid overflow-auto d-flex'>
        <div className='container-fluid main-wrapper '>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default Dashboard
