import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

import styles from './modal.module.scss'

const Extension = ({ extensionNumber, name, alias, username, number, show, handleClose }) => {
  return (
    <div>
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title>Extension details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-wrapper'>
            <div className={styles.oneRedcord}>
              <br />
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>Extension Name: </span>
                </div>
                <h5>{name || ''}</h5>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>Extension Number: </span>
                </div>
                <h5>{extensionNumber || ''}</h5>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>SIP Alias: </span>
                </div>
                <h5>{alias || ''}</h5>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>SIP Username: </span>
                </div>
                <h5>{username || ''}</h5>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>Organization name: </span>
                </div>
                <h5>{name || ''}</h5>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>Number: </span>
                </div>
                <h5>{number || ''}</h5>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <button className="btn btn-purple" onClick={() => this.props.handleSubmitOrganization(this.state)}>
                            Save
                </button> */}
          <button className='btn btn-lightblue' onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

Extension.propTypes = {
  serialNo: PropTypes.string,
  extensionNumber: PropTypes.string,
  name: PropTypes.string,
  alias: PropTypes.string,
  username: PropTypes.string,
  number: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default Extension
