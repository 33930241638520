import { useContext } from 'react'
import { BiUserCheck } from 'react-icons/bi'
import { FaUsers } from 'react-icons/fa'
import {
  MdBusinessCenter,
  MdOutlineLockPerson,
  MdOutlineSettings,
  MdPersonAdd,
  MdTableView,
  MdUnsubscribe,
} from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import { ADMIN_ROLES } from '../../constants'
import { MimicUserContext } from '../../context/MimicUserContext'
import sessionService from '../../services/sessionService'
import sessionStorageService from '../../services/sessionStorageService'
import { confirm } from '../Common/Modals/ConfirmService'

const Sidebar = () => {
  const isAuthenticated = sessionService.isAuth()
  const role = sessionService.getUserRole()
  const { mimicUser, setMimicUser } = useContext(MimicUserContext)

  const logOut = () => {
    sessionService.destroy()
    sessionStorageService.destroy()
    setMimicUser()
  }

  const hendleUnmimicUser = () => {
    if (!mimicUser) return
    confirm(`Do you want to stop mimic user ${mimicUser?.username || ''}`).then(
      () => {
        sessionStorageService.destroy()
        setMimicUser(null)
      },
      () => {},
    )
  }

  return (
    <aside className='dashboard-navbar'>
      <nav className='fixed-wrapper'>
        {!isAuthenticated ? (
          <NavLink className='navbar-brand' to='/login'>
            <img className='img-logo' src={logo} alt='logo' />
          </NavLink>
        ) : (
          <NavLink className='navbar-brand' to='/dashboard/charts'>
            <img className='img-logo' src={logo} alt='logo' />
          </NavLink>
        )}
        {mimicUser?.username ? (
          <p onClick={hendleUnmimicUser} className='text-center' style={{ cursor: 'pointer' }}>
            {mimicUser.username}{' '}
            <BiUserCheck style={{ width: '15px', height: '15px', display: 'inline-flex' }} />
          </p>
        ) : null}

        <ul className='dash-navbar'>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'activeNavLink nav-link icon' : 'nav-link icon'
              }
              to='/dashboard/charts'
            >
              <svg
                id='Layer_2'
                data-name='Layer 2'
                xmlns='http://www.w3.org/2000/svg'
                width='40'
                height='40'
                viewBox='0 0 40 40'
              >
                <g id='pie-chart'>
                  <rect
                    id='Rectangle_220'
                    data-name='Rectangle 220'
                    width='40'
                    height='40'
                    fill='#2f3545'
                    opacity='0'
                  />
                  <path
                    id='Path_154'
                    data-name='Path 154'
                    d='M15.057,15.918H26.2a1.387,1.387,0,0,0,1.387-1.387A12.531,12.531,0,0,0,15.057,2,1.387,1.387,0,0,0,13.67,3.387V14.531a1.387,1.387,0,0,0,1.387,1.387Z'
                    transform='translate(9.171 1.342)'
                    fill='#2f3545'
                  />
                  <path
                    id='Path_155'
                    data-name='Path 155'
                    d='M33.843,18.712H20.225a1.52,1.52,0,0,1-1.52-1.52V3.541A1.537,1.537,0,0,0,17,2,16.709,16.709,0,1,0,35.38,20.383a1.537,1.537,0,0,0-1.537-1.671Z'
                    transform='translate(1.379 1.338)'
                    fill='#2f3545'
                  />
                </g>
              </svg>
              <p>Dashboard</p>
            </NavLink>
          </li>

          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'activeNavLink nav-link icon' : 'nav-link icon'
              }
              to='/dashboard/organizations'
            >
              <svg
                id='Layer_2'
                data-name='Layer 2'
                xmlns='http://www.w3.org/2000/svg'
                width='40'
                height='40'
                viewBox='0 0 40 40'
              >
                <g id='briefcase'>
                  <rect
                    id='Rectangle_221'
                    data-name='Rectangle 221'
                    width='40'
                    height='40'
                    fill='#2f3545'
                    opacity='0'
                  />
                  <path
                    id='Path_156'
                    data-name='Path 156'
                    d='M7,33H23.667V9.667H22v-2.5A4.167,4.167,0,0,0,17.833,3h-5A4.167,4.167,0,0,0,8.667,7.167v2.5H7ZM12,7.167a.833.833,0,0,1,.833-.833h5a.833.833,0,0,1,.833.833v2.5H12Z'
                    transform='translate(4.667 2)'
                    fill='#2f3545'
                  />
                  <path
                    id='Path_157'
                    data-name='Path 157'
                    d='M19,7V30.333a5,5,0,0,0,5-5V12A5,5,0,0,0,19,7Z'
                    transform='translate(12.667 4.667)'
                    fill='#2f3545'
                  />
                  <path
                    id='Path_158'
                    data-name='Path 158'
                    d='M7,7a5,5,0,0,0-5,5V25.333a5,5,0,0,0,5,5Z'
                    transform='translate(1.333 4.667)'
                    fill='#2f3545'
                  />
                </g>
              </svg>
              <p>Organization & Service Plan</p>
            </NavLink>
          </li>

          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'activeNavLink nav-link icon' : 'nav-link icon'
              }
              to='/dashboard/profile'
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
                <g id='Layer_21' data-name='Layer 21' transform='translate(0 -256)'>
                  <g id='person' transform='translate(0 256)'>
                    <rect
                      id='Rectangle_222'
                      data-name='Rectangle 222'
                      width='40'
                      height='40'
                      fill='#2f3545'
                      opacity='0'
                    />
                    <path
                      id='Path_159'
                      data-name='Path 159'
                      d='M14.667,16.333A6.667,6.667,0,1,0,8,9.667,6.667,6.667,0,0,0,14.667,16.333Z'
                      transform='translate(5.333 2)'
                      fill='#2f3545'
                    />
                    <path
                      id='Path_160'
                      data-name='Path 160'
                      d='M26.667,26.333a1.667,1.667,0,0,0,1.667-1.667A11.667,11.667,0,1,0,5,24.667a1.667,1.667,0,0,0,1.667,1.667Z'
                      transform='translate(3.333 8.667)'
                      fill='#2f3545'
                    />
                  </g>
                </g>
              </svg>
              <p>Account Profile</p>
            </NavLink>
          </li>
          {role === ADMIN_ROLES.master && (
            <>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'activeNavLink nav-link icon' : 'nav-link icon'
                  }
                  to='/dashboard/plans-bundles'
                >
                  <MdUnsubscribe />
                  <p>Plans & Bundles</p>
                </NavLink>
              </li>
              <li className='usersList'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'activeNavLink nav-link icon' : 'nav-link icon'
                  }
                  to='/dashboard/users-accounts'
                >
                  <FaUsers className='usersIcon' />
                  <p>Users & Accounts & Organizations</p>
                </NavLink>
              </li>
              <li className='usersList'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'activeNavLinkReactIcons nav-link' : 'nav-link'
                  }
                  to='/dashboard/master-admins'
                >
                  <MdOutlineLockPerson className='usersIcon' />
                  <p>Master admins</p>
                </NavLink>
              </li>
              <li className='usersList'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'activeNavLinkReactIcons nav-link' : 'nav-link'
                  }
                  to='/dashboard/agents'
                >
                  <MdBusinessCenter className='usersIcon' />
                  <p>Agents</p>
                </NavLink>
              </li>
              <li className='usersList'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'activeNavLinkReactIcons nav-link' : 'nav-link'
                  }
                  to='/dashboard/settings'
                >
                  <MdOutlineSettings className='usersIcon' />
                  <p>Settings</p>
                </NavLink>
              </li>
            </>
          )}
          {role === ADMIN_ROLES.agent && (
            <>
              <li className='usersList'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'activeNavLinkReactIcons nav-link' : 'nav-link'
                  }
                  to='/dashboard/agents/add-user'
                >
                  <MdPersonAdd className='usersIcon' />
                  <p>Add User</p>
                </NavLink>
              </li>
              <li className='usersList'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'activeNavLinkReactIcons nav-link' : 'nav-link'
                  }
                  to='/dashboard/agents/view'
                >
                  <MdTableView className='usersIcon' />
                  <p>View</p>
                </NavLink>
              </li>
            </>
          )}
          <li className='logout' onClick={logOut}>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'activeNavLink nav-link icon' : 'nav-link icon'
              }
              to='/login'
            >
              <svg
                id='Layer_22'
                data-name='Layer 22'
                xmlns='http://www.w3.org/2000/svg'
                width='40'
                height='40'
                viewBox='0 0 40 40'
              >
                <g id='log-out'>
                  <rect
                    id='Rectangle_224'
                    data-name='Rectangle 224'
                    width='40'
                    height='40'
                    transform='translate(40) rotate(90)'
                    fill='#2f3545'
                    opacity='0'
                  />
                  <path
                    id='Path_163'
                    data-name='Path 163'
                    d='M9,7.333A1.667,1.667,0,1,0,9,4H5.667A1.667,1.667,0,0,0,4,5.667V29a1.667,1.667,0,0,0,1.667,1.667H9a1.667,1.667,0,1,0,0-3.333H7.333v-20Z'
                    transform='translate(2.667 2.667)'
                    fill='#2f3545'
                  />
                  <path
                    id='Path_164'
                    data-name='Path 164'
                    d='M28.7,14.368,24,7.7a1.667,1.667,0,1,0-2.717,1.933l2.867,4.033H10.667a1.667,1.667,0,0,0,0,3.333H24l-3,4a1.667,1.667,0,0,0,2.667,2l5-6.667a1.667,1.667,0,0,0,.033-1.967Z'
                    transform='translate(6 4.666)'
                    fill='#2f3545'
                  />
                </g>
              </svg>
              <p>Logout</p>
            </NavLink>
          </li>
        </ul>
        <ul></ul>
      </nav>
    </aside>
  )
}

export default Sidebar
