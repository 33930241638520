import styles from './RegisterAgent.module.scss'
import logo from '../../assets/images/logo.png'
import { useState } from 'react'
import agentService from '../../services/agentService'
import { Link, useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const RegisterAgent = () => {
  const [user, setUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
    username: '',
    address: '',
    phoneNumber: '',
    password: '',
  })
  const [error, setError] = useState('')

  const navigate = useNavigate()

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value })
    setError(null)
  }

  const handleChangePhone = (value) => {
    setUser({ ...user, phoneNumber: value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const error = validateForm()
    if (!error) {
      agentService
        .register(user)
        .then(() => {
          navigate('/request')
        })
        .catch(() => {})
    } else {
      setError({ error })
    }
  }

  const validateForm = () => {
    if (
      !user.email.trim() ||
      !user.username.trim() ||
      !user.firstName.trim() ||
      !user.lastName.trim() ||
      !user.address.trim() ||
      !user.phoneNumber
    ) {
      return 'All fields are required'
    } else if (!user.password || user.password.length < 5) {
      return 'password need to be longer then 5 characters'
    }
    return null
  }

  return (
    <div className={`${'auth-page-container container-fluid'} ${styles.wrapper}`}>
      <div className={`${'auth-frame ps-5 pe-5 fs-9'} ${styles.headerBox}`}>
        <div className='auth-img-box pt-5 pb-4 '>
          <img className={styles.logo} src={logo} alt='Air Touch' />
        </div>
        <form onSubmit={handleSubmit} className='text-start'>
          <div className='form-group mb-2'>
            <label className={styles.labelInp} htmlFor='email'>
              Email:
            </label>
            <input
              id='email'
              className={`${'form-control mt-2 border-0'} ${styles.inputBgColor}`}
              type='text'
              name='email'
              value={user.email}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-group mb-2'>
            <label className={styles.labelInp} htmlFor='username'>
              Username:
            </label>
            <input
              id='username'
              className={`${'form-control mt-2 border-0'} ${styles.inputBgColor}`}
              type='text'
              name='username'
              value={user.username}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-group mb-2'>
            <label className={`${'form-label'} ${styles.labelInp}`} htmlFor='firstName'>
              First Name:
            </label>
            <input
              id='firstName'
              className={`${'form-control border-0'} ${styles.inputBgColor}`}
              type='text'
              name='firstName'
              value={user.firstName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-group mb-2'>
            <label className={`${'form-label'} ${styles.labelInp}`} htmlFor='lastName'>
              Last Name:
            </label>
            <input
              id='lastName'
              className={`${'form-control border-0'} ${styles.inputBgColor}`}
              type='text'
              name='lastName'
              value={user.lastName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-group mb-2'>
            <label className={styles.labelInp} htmlFor='address'>
              Address:
            </label>
            <input
              id='address'
              className={`${'form-control mt-2 mb-3 border-0'} ${styles.inputBgColor}`}
              type='text'
              name='address'
              value={user.address}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-group mb-2'>
            <label className={`${'form-label'} ${styles.labelInp}`} htmlFor='phoneNumber'>
              Phone Number:
            </label>
            <PhoneInput
              international
              placeholder='Enter phone number'
              value={user.phoneNumber}
              defaultCountry='US'
              onChange={handleChangePhone}
              className={styles.phoneInputCSS}
            />
          </div>
          <div className='form-group'>
            <label className={styles.labelInp} htmlFor='password'>
              Password:
            </label>
            <input
              id='password'
              className={`${'form-control mt-2 mb-3 border-0'} ${styles.inputBgColor}`}
              type='password'
              name='password'
              value={user.password}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {error && <div className={'alert alert-danger'}>{error.error}</div>}
          <div className='text-end'>
            <button type='submit' className={`${'btn mb-3'} ${styles.registerBtn}`}>
              Send request
            </button>
          </div>
        </form>
        <div className='auth-footer'>
          <div className='pb-5'>
            <span className={styles.footerSpan}>Go back to </span>{' '}
            <Link className={`${'text-info'} ${styles.link}`} to='/login'>
              Login.
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterAgent
