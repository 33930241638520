/**
 * Stores user json object ot local storage
 * @param user
 */
function create(user, key) {
  sessionStorage.setItem(key, JSON.stringify(user))
}

/**
 * Destroy user object from local storage and redirects to login
 */
function destroy() {
  sessionStorage.clear()
}

const isData = (data) => {
  return data && data !== 'undefined'
}

function getItem(key) {
  const data = sessionStorage.getItem(key)
  if (!isData(data)) return null
  var parsedData = JSON.parse(data)
  return parsedData || {}
}

export default {
  create,
  destroy,
  getItem,
}
