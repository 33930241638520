import PropTypes from 'prop-types'

const Steper = ({ step, organizationId }) => (
  <div className='steper-wrapper'>
    <div className='step stepOne'>
      <div className='step-item'>
        <div className='step-circle'>
          <span className={step === 2 || step === 3 ? 'done-false' : 'done-true'}>1</span>
          <span className={step === 2 || step === 3 ? 'done-true' : 'done-false'}>
            <i className='bi bi-check'></i>
          </span>
        </div>

        <h6>{organizationId ? 'Create group' : 'Create organization'}</h6>
      </div>
    </div>
    <div className='step stepTwo'>
      <div className='step-item'>
        <div className='step-circle'>
          <span className={step === 3 || step === 1 ? 'circle-grey' : ''}>3</span>
          <span className={step === 3 ? 'done-true' : 'done-false'}>
            <i className='bi bi-check'></i>
          </span>
        </div>
        <h6>Subscribe to premium plan</h6>
      </div>
    </div>

    <div className='step  stepThree'>
      <div className='step-item'>
        <div className='step-circle'>
          <span className={step === 1 || step === 2 ? 'circle-grey' : ''}>3</span>
          <span
            className={
              step === 1 || step === 2 || step === 3 || step === 4 ? 'done-false' : 'done-true'
            }
          >
            <i className='bi bi-check'></i>
          </span>
        </div>
        <h6>Invite team members</h6>
      </div>
    </div>
  </div>
)

Steper.propTypes = {
  step: PropTypes.number.isRequired,
  organizationId: PropTypes.string,
}

export default Steper
