import { Rings } from 'react-loader-spinner'
import styles from './Loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.wrapper}>
      <Rings
        height={80}
        width={80}
        color='#498bf5'
        radius={6}
        wrapperStyle={{}}
        wrapperClassName=''
        visible={true}
        ariaLabel='rings-loading'
      />
    </div>
  )
}

export default Loader
