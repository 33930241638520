import { NavLink } from 'react-router-dom'
import styles from './Plans.module.scss'
import { useEffect, useState } from 'react'
import { MdRestoreFromTrash, MdEdit } from 'react-icons/md'
import plansAndBundlesService from '../../services/plansAndBundlesService'
import { toast } from 'react-toastify'
import Loader from '../Loader/Loader'

const Plans = () => {
  const [plans, setPlans] = useState([])
  const [bundles, setBundles] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    plansAndBundlesService
      .getPlans()
      .then((response) => {
        setPlans(response)
      })
      .catch(() => {})
      .finally(() => setIsLoading(false))
    plansAndBundlesService
      .getBundles()
      .then((response) => {
        setBundles(response)
      })
      .catch(() => {})
      .finally(() => setIsLoading(false))
  }, [])

  const handleDeletePlan = (planId) => {
    plansAndBundlesService
      .deletePlan(planId)
      .then(() => {
        toast.success('Plan is successfully deleted!')
        const newArray = plans.filter((plan) => plan._id !== planId)
        setPlans(newArray)
      })
      .catch(() => {
        toast.error('Something went wrong, try again')
      })
  }

  const handleDeleteBundle = (bundleId) => {
    plansAndBundlesService
      .deleteBundle(bundleId)
      .then(() => {
        toast.success('Plan is successfully deleted!')
        const newArray = bundles.filter((bundle) => bundle._id !== bundleId)
        setBundles(newArray)
      })
      .catch(() => {
        toast.error('Something went wrong, try again')
      })
  }

  if (isLoading) {
    return <Loader />
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.btns}>
        <h2>Plans</h2>
        <NavLink className={styles.customNavLink} to={'/dashboard/plans-bundles/plan'}>
          <button className={`${'btn btn-lightblue btn-sm'}`}>Create Plan</button>
        </NavLink>
      </div>
      <div className={styles.planWrapper}>
        {plans?.map((plan) => {
          return (
            <div key={plan._id} className={styles.singlePlan}>
              <div className={styles.planOptions}>
                <label>Name:</label>
                <p>{plan.name}</p>
              </div>
              <div className={styles.planOptions}>
                <label>Minutes:</label>
                <p>{plan.minutes}</p>
              </div>
              <div className={styles.planOptions}>
                <label>SMS:</label>
                <p>{plan.sms}</p>
              </div>
              <div className={styles.planOptions}>
                <label>Plan duration:</label>
                <p>{plan.duration}</p>
              </div>
              <div className={styles.planOptions}>
                <label>Unique Plan Identifier:</label>
                <p>{plan.planId}</p>
              </div>
              <div className={styles.planOptions}>
                <label>Price:</label>
                <p>{plan.price} $</p>
              </div>
              <div className={`${styles.editDeleteBtns} d-flex justify-content-end`}>
                <NavLink
                  className={styles.customNavLink}
                  to={`/dashboard/plans-bundles/plan/${plan._id}`}
                >
                  <MdEdit className={styles.icons} />
                </NavLink>
                <MdRestoreFromTrash
                  onClick={() => handleDeletePlan(plan._id)}
                  className={styles.icons}
                />
              </div>
            </div>
          )
        })}
      </div>
      <div className={styles.btns}>
        <h2>Bundles</h2>
        <NavLink to={'/dashboard/plans-bundles/bundle'} className={styles.customNavLink}>
          <button className={`${'btn btn-lightblue btn-sm'}`}>Create Bundles</button>
        </NavLink>
      </div>
      <div className={styles.bundleWrapper}>
        {bundles?.map((bundle) => {
          return (
            <div key={bundle._id} className={styles.singlePlan}>
              {bundle && bundle.minutes > 0 && (
                <div className={styles.planOptions}>
                  <label>Minutes:</label>
                  <p>{bundle.minutes}</p>
                </div>
              )}
              {bundle && bundle.sms > 0 && (
                <div className={styles.planOptions}>
                  <label>SMS:</label>
                  <p>{bundle.sms}</p>
                </div>
              )}

              <div className={styles.planOptions}>
                <label>Bundle Name:</label>
                <p>{bundle.name}</p>
              </div>
              <div className={styles.planOptions}>
                <label>Price:</label>
                <p>{bundle.price}$</p>
              </div>

              <div className={`${styles.editDeleteBtns} d-flex justify-content-end`}>
                <NavLink
                  className={styles.customNavLink}
                  to={`/dashboard/plans-bundles/bundle/${bundle._id}`}
                >
                  <MdEdit className={styles.icons} />
                </NavLink>
                <div className={styles.customNavLink}>
                  <MdRestoreFromTrash
                    onClick={() => handleDeleteBundle(bundle._id)}
                    className={styles.icons}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Plans
