import { useState, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import profileService from '../../services/profileService'

import logo from '../../assets/images/logo.png'

const ResetPassword = () => {
  const [state, setState] = useState({
    password: '',
    confirmation: '',
  })
  const [error, setError] = useState('')

  const params = useParams()
  const inputRef = useRef(null)

  const { hash } = params
  const navigate = useNavigate()

  useEffect(() => {
    inputRef?.current?.focus()
  }, [])

  const handleChange = (e) => {
    const { value, name } = e.target
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
    setError('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm()) {
      profileService
        .resetPassword(hash, state)
        .then(() => {
          navigate('/')
        })
        .catch(() => {
          setError('Failed to reset password.')
        })
    }
  }

  const validateForm = () => {
    if (!state?.password || !state?.confirmation) {
      setError('All fields are required')
      return false
    } else if (state.password !== state.confirmation) {
      setError('Password do not match')
      return false
    }
    return true
  }

  const { password, confirmation } = state
  return (
    <div className='auth-page-container container-fluid'>
      <div className='auth-frame jumbotron'>
        <div className='auth-img-box '>
          <img className='auth-logo' src={logo} alt='Airtouch logo' />
        </div>
        <h5 className='m-3'>Reset password</h5>
        <form onSubmit={handleSubmit} className='text-left'>
          <div className='form-group'>
            <label htmlFor='email'>New password:</label>
            <input
              ref={inputRef}
              id='password'
              className='form-control'
              type='password'
              name='password'
              value={password || ''}
              onChange={handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='confirmation'>Confirm password:</label>
            <input
              id='confirmation'
              className='form-control'
              type='password'
              name='confirmation'
              value={confirmation || ''}
              onChange={handleChange}
            />
          </div>
          {error && <div className={'alert alert-danger'}>{error}</div>}
          <div className='text-right mt-3'>
            <button type='submit' className='btn btn-lightblue'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
