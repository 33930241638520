import { useState } from 'react'
import profileService from '../../services/profileService'
import { toast } from 'react-toastify'
import sessionService from '../../services/sessionService'

import styles from './profile.module.scss'

const ChangePassword = () => {
  const [pass, setPass] = useState({ password: '', newPassword: '', confirmPassword: '' })
  const [edit, setEdit] = useState(false)

  const options = {
    autoClose: 3000,
    hideProgressBar: true,
  }

  const showEditPassword = () => setEdit(true)
  const hideEditPassword = () => setEdit(false)

  const handleChange = (event) => {
    setPass({ ...pass, [event.target.name]: event.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (validateForm()) {
      profileService
        .changePassword(pass)
        .then(() => {
          if (sessionService.isAuth()) {
            setPass({ password: '', newPassword: '', confirmPassword: '' })
            toast.success('Password is successfully updated!', options)
            setEdit(false)
          }
        })
        .catch((error) => {
          toast.error(error, options)
        })
    }
  }

  const validateForm = () => {
    return pass.password && pass.newPassword && pass.confirmPassword
  }

  return (
    <div className='subtitle-border-bottom'>
      <ul>
        {!edit ? (
          <li className='profil-change-pass'>
            <h6 className='grey'>Password:</h6>
            <div className='d-flex flex-row'>
              <span className={`${'lightblue'} ${styles.spanPassword}`}>
                &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679;
              </span>
              <span className='link-look pull-right' onClick={showEditPassword}>
                Edit
              </span>
            </div>
          </li>
        ) : (
          <div className='change-pass-profil'>
            <div className='d-flex flex-row'>
              <h6 className={`${'mt-3 mb-3 blue'} ${styles.spanEdit}`}> Password </h6>
              <span
                className='link-look pull-right d-flex align-items-center '
                onClick={hideEditPassword}
              >
                Close
              </span>
            </div>

            <hr />
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <label htmlFor='password'>Old password:</label>
                <input
                  id='password'
                  className={`${'form-control border-0 mb-3 mt-2'} ${styles.inputBgColor}`}
                  type='password'
                  name='password'
                  value={pass.password}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='newPassword'>New password:</label>
                <input
                  id='newPassword'
                  className={`${'form-control border-0 mb-3 mt-2'} ${styles.inputBgColor}`}
                  type='password'
                  name='newPassword'
                  value={pass.newPassword}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className='form-group'>
                <label htmlFor='confirmPassword'>Confirm new password:</label>
                <input
                  id='confirmPassword'
                  className={`${'form-control border-0 mb-3 mt-2'} ${styles.inputBgColor}`}
                  type='password'
                  name='confirmPassword'
                  value={pass.confirmPassword}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div
                className={`${'clearfix d-flex justify-content-md-end '} ${styles.changePassBtn}`}
              >
                <button className={`${'btn btn-lightblue  pull-right '} ${styles.updateBtn}`}>
                  Change password
                </button>
              </div>
            </form>
          </div>
        )}
      </ul>
    </div>
  )
}

export default ChangePassword
