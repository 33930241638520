import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import PageTitle from '../../components/Common/PageTitle'
import styles from './AgentView.module.scss'
import TableAgentUsers from '../../components/TableAgentUsers/TableAgentUsers'
import TableAgentOrganizations from '../../components/TableAgentOrganizations/TableAgentOrganizations'

const AgentView = () => {
  return (
    <div className='py-4'>
      <PageTitle title='' subtitle='Creating user with agent' className={styles.title} />
      <h3 className={styles.header}>Agent View</h3>
      <div className={styles.usersAndAccountsWrapper}>
        <Tabs>
          <TabList>
            <Tab>Users</Tab>
            <Tab>Organizations</Tab>
          </TabList>
          <TabPanel>
            <TableAgentUsers />
          </TabPanel>
          <TabPanel>
            <TableAgentOrganizations />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default AgentView
