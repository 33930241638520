import { ArcElement, Chart } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Card, CardBody, CardHeader } from 'reactstrap'

import styles from './Chart.module.scss'

Chart.register(ArcElement)

const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        padding: 25,
        boxWidth: 20,
      },
    },
  },
  tooltips: {
    custom: false,
    mode: 'index',
    position: 'nearest',
  },
}

const DoughnutCharts = (data) => {
  return (
    <Card className='mb-4 card-custom h-100 '>
      <CardHeader className='card-charts-title'>
        <h6 className='text-grey select-none'>{data.title}</h6>
      </CardHeader>
      <CardBody className='pie-charts-box'>
        <div className='charts-pie-wrapper '>
          <div className={styles.chartBarDiv}>
            <Doughnut
              className={styles.pieChart}
              data={data.data}
              options={options}
              redraw={true}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default DoughnutCharts
