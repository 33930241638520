import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import profileService from '../../services/profileService'
import sessionService from '../../services/sessionService'
import logo from '../../assets/images/logo.png'
import styles from './login.module.scss'

const Login = () => {
  const [user, setUser] = useState({ username: '', password: '' })
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value })
    setError(null)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const { username, password } = user

    if (validateForm()) {
      profileService
        .login(username, password)
        .then((response) => {
          sessionService.create(response)
          if (sessionService.isAuth()) {
            navigate('/dashboard/charts')
          }
        })
        .catch(() => {
          setError('Error: Wrong username or password!')
        })
    } else {
      setError('All field are required!')
    }
  }

  const validateForm = () => {
    return user.username.length > 3 && user.password.length > 3
  }

  return (
    <div className='auth-page-container container-fluid'>
      <div className={`${'auth-frame ps-5 pe-5 fs-9'} ${styles.headerBox}`}>
        <div className='auth-img-box pt-5 pb-4 '>
          <img className={styles.logo} src={logo} alt='Air Touch' />
        </div>
        <form onSubmit={handleSubmit} className='text-start'>
          <div className='form-group mb-2'>
            <label className={styles.labelInp} htmlFor='username'>
              Username:
            </label>
            <input
              id='username'
              className={`${'form-control mt-2 mb-3 border-0'} ${styles.inputBgColor}`}
              type='text'
              name='username'
              value={user.username}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-group mb-2'>
            <label className={styles.labelInp} htmlFor='password'>
              Password:
            </label>
            <input
              id='password'
              className={`${'form-control mt-2 mb-3 border-0'} ${styles.inputBgColor}`}
              type='password'
              name='password'
              value={user.password}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {error && <div className={'alert alert-danger'}>{error}</div>}
          <div className='text-end'>
            <button type='submit' className={`${'btn'} ${styles.loginBtn}`}>
              Login
            </button>
          </div>
        </form>
        <div className='auth-footer'>
          <div className=''>
            <span className={styles.footerSpan}> Dont have an account yet?</span>{' '}
            <Link className={`${'text-info'} ${styles.link}`} to='/register'>
              Sign up!
            </Link>
          </div>
          <div className=''>
            <span className={styles.footerSpan}>Forgot password?</span>{' '}
            <Link className={`${'text-info'} ${styles.link}`} to='/forgot-password'>
              Click here!
            </Link>
          </div>
          <div className='pb-5'>
            <span className={styles.footerSpan}>Register as Agent?</span>{' '}
            <Link className={`${'text-info'} ${styles.link}`} to='/register-agent'>
              Sign up!
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
