import axios from 'axios'
import SessionService from './sessionService'
import sessionStorageService from './sessionStorageService'
import ToastService from './toastService'
import url from '../environment'

axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    // return response body
    return response.data
  },
  (error) => {
    // Handle response error
    _handleError(error)
    return Promise.reject(error)
  },
)

async function apiRequest(method, apiUrl, body, headers) {
  try {
    const apiToken = SessionService.getSessionToken()
    const mimicUser = sessionStorageService.getItem('mimic-user')
    const requestHeaders = !headers ? {} : headers
    if (apiToken) requestHeaders['x-access-token'] = apiToken
    if (mimicUser?._id) requestHeaders['mimic-user'] = mimicUser._id
    if (method === 'get' || method === 'delete')
      return axios[method](url + '/api/v1' + apiUrl, { headers: requestHeaders })
    else if (method === 'post' || method === 'put' || method === 'patch') {
      return axios[method](url + '/api/v1' + apiUrl, body, { headers: requestHeaders })
    }
  } catch (err) {
    _handleError(err)
  }
}

async function outsideRequest(method, url, body, headers) {
  try {
    const requestHeaders = !headers ? {} : headers

    if (method === 'get' || method === 'delete')
      return axios[method](url, { headers: requestHeaders })
    else if (method === 'post' || method === 'put') return axios[method](url, body, { headers })
  } catch (err) {
    _handleError(err)
  }
}

function _handleError(err) {
  // handle error
  if (err && err.response) {
    if (err.response.status === 401 || err.response.status === 403) {
      // remove user data
      SessionService.destroy()
      sessionStorageService.destroy()
      window.location.replace('/login')
    }
    let errorText
    if (err.response.data && err.response.data.error && err.response.data.error.message) {
      errorText = err.response.data.error.message
    } else {
      errorText = err.response.statusText
    }
    ToastService.show('error', errorText)
  }

  throw err
}

export default {
  apiRequest,
  outsideRequest,
}
