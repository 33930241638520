import classNames from 'classnames'
import PropTypes from 'prop-types'

const PageTitle = ({ title, cssClassName, subtitle, ...attrs }) => {
  const classes = classNames(cssClassName, 'text-center', 'text-md-left', 'mb-sm-0')

  return (
    <div className={classes} {...attrs}>
      <span className='text-uppercase page-subtitle'>{subtitle}</span>
      <h3 className='page-title text-center'>{title}</h3>
    </div>
  )
}

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,
  cssClassName: PropTypes.string,
}

export default PageTitle
