import { Line } from 'react-chartjs-2'
import { Card, CardBody, CardHeader } from 'reactstrap'

import styles from './Chart.module.scss'

const options = {
  responsive: true,
  maintainAspectRatio: true,
  legend: {
    position: 'top',
  },
  elements: {
    line: {
      // A higher value makes the line look skewed at this ratio.
      tension: 0.3,
    },
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      gridLines: false,
      ticks: {
        callback(tick, index) {
          // Jump every 7 values on the X axis labels to avoid clutter.
          return index % 7 !== 0 ? '' : tick
        },
      },
    },

    y: {
      ticks: {
        suggestedMax: 45,
        callback(tick) {
          if (tick === 0) {
            return tick
          }
          // Format the amounts using Ks for thousands.
          return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick
        },
      },
    },
  },
  hover: {
    mode: 'nearest',
    intersect: false,
  },
  tooltips: {
    custom: false,
    mode: 'nearest',
    intersect: false,
  },
}

const LineChartBig = (data) => {
  return (
    <Card className='mb-4 card-custom h-100 '>
      <CardHeader className='card-charts-title'>
        <h6 className='select-none'>{data.title}</h6>
      </CardHeader>
      <CardBody className='line-lg-charts-box'>
        <div className={`${'chartjs-size-monitor'} ${styles.lineChartBox}`}>
          <Line data={data.data} options={options} redraw={true} />
        </div>
      </CardBody>
    </Card>
  )
}

export default LineChartBig
