import { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { Card, CardBody, InputGroup, InputGroupText } from 'reactstrap'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import dayjs from 'dayjs'

const selectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
}

const Confirmation = ({ onSelect, fromDate }) => {
  const [showPicker, setShowPicker] = useState(false)

  const handleSelect = (data) => {
    if (onSelect) onSelect(data)
    closePicker()
  }

  const openPicker = () => {
    setShowPicker(true)
  }

  const closePicker = () => {
    setShowPicker(false)
  }

  return (
    <div className='static-modal'>
      <Card className='mb-4 card-custom date-picker-card'>
        <CardBody className=''>
          <InputGroup>
            <InputGroupText style={{ background: '#121621' }}>
              From Date: {dayjs(fromDate).format('YYYY-MM-DD')}
            </InputGroupText>
            <button type='submit' className='btn btn-lightblue' onClick={openPicker}>
              Change
            </button>
          </InputGroup>

          {/*<Row>*/}
          {/*<Col sm="8" xs="12" className="mb-4  col-lg">*/}
          {/*<label htmlFor="fromDate">From Date:</label>*/}
          {/*<input*/}
          {/*ref="input"*/}
          {/*type="text"*/}
          {/*className="form-control"*/}
          {/*name="fromDate"*/}
          {/*id="fromDate"*/}
          {/*value={moment(fromDate).format('YYYY-MM-DD')}*/}
          {/*/>*/}
          {/*</Col>*/}
          {/*<Col sm="4" xs="12" className="mb-4  col-lg">*/}
          {/*<button type="submit" className="btn btn-purple" onClick={this.openPicker}>Change</button>*/}
          {/*</Col>*/}
          {/*</Row>*/}
        </CardBody>
      </Card>
      <Modal className='modal-light' size='lg' show={showPicker} onHide={closePicker}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          {showPicker ? (
            <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
          ) : null}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  )
}

Confirmation.propTypes = {
  onSelect: PropTypes.func,
  fromDate: PropTypes.instanceOf(Date),
}

export default Confirmation
