import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import organizationService from '../../services/organizationService'
import toastService from '../../services/toastService'
import styles from './OrganizationCallHistory.module.scss'
import PageTitle from '../../components/Common/PageTitle'
import Loader from '../../components/Loader/Loader'
import CustomPagination from '../../components/CustomPagination/CustomPagination'
import dayjs from 'dayjs'
import { formatSeconds } from '../../utils/utils'
import { MdCall, MdVideocam } from 'react-icons/md'

const itemsPerPage = 10
const siblingCount = 1
const PAGING_SELECT_OPTIONS = ['10', '20', '50', '100']

const OrganizationCallHistory = () => {
  const { organizationId } = useParams()
  const location = useLocation()

  const [organizationsCallHistory, setOrganizationsCallHistory] = useState([])
  const [totalCount, setTotalCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(itemsPerPage)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getOrganizations = () => {
      setLoading(true)
      organizationService
        .getOrganizationCallHistory({ organizationId, page: currentPage - 1, limit: limit })
        .then((response) => {
          setOrganizationsCallHistory(response?.organizationsCallHistory || [])
          setTotalCount(response?.total || 0)
          const totalPageCount = Math.ceil((response?.total || 0) / limit)
          if (currentPage > totalPageCount) setCurrentPage(1)
        })
        .catch((err) => {
          toastService.show('Error fetch organization call history' + err?.message || '')
        })
        .finally(() => {
          setLoading(false)
        })
    }
    getOrganizations()
  }, [currentPage, limit])

  const handlePageSizeChagne = (size) => {
    setLimit(size)
    setCurrentPage(1)
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  return (
    <div className='py-4'>
      <PageTitle title='' subtitle='Manage Call History' className={styles.title} />
      <h3 className={styles.header}>Organization: {location?.state?.name}</h3>
      <div className={styles.info}>
        <div>
          <span>total: {totalCount}</span>
        </div>
      </div>
      <div className={`${'p-0 pb-3 table-responsive card-body '}`}>
        <table className={`${'table mb-0'}`}>
          <thead className='lightblue-bg'>
            <tr>
              <th>Call Start</th>
              <th>Call End</th>
              <th>Duration</th>
              <th>From</th>
              <th>To</th>
              <th>Direction</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {organizationsCallHistory?.map((call) => (
              <tr key={call?._id}>
                <td>
                  {call?.callStart ? dayjs(call.callStart).format('MM DD YYYY, HH:mm:ss') : ''}
                </td>
                <td>{call?.callEnd ? dayjs(call.callEnd).format('MM DD YYYY, HH:mm:ss') : ''}</td>
                <td>{formatSeconds(call?.duration)}</td>
                <td>{call?.from}</td>
                <td>{call?.to}</td>
                <td>
                  {call?.direction ? call.direction : call?.groupCall ? 'group call' : 'one to one'}
                </td>
                <td>
                  {call?.type || ''}{' '}
                  {call?.type === 'video' ? (
                    <MdVideocam />
                  ) : call?.type === 'voice' ? (
                    <MdCall />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.paginationWrapper}>
        <CustomPagination
          totalCount={totalCount}
          currentPage={currentPage}
          pageSize={limit}
          siblingCount={siblingCount}
          onPageChage={setCurrentPage}
          options={PAGING_SELECT_OPTIONS}
          hasSelect={true}
          handleSelectChange={handlePageSizeChagne}
        />
      </div>
    </div>
  )
}

export default OrganizationCallHistory
