import { useEffect, useState, useContext } from 'react'
import { toast } from 'react-toastify'
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import organizationService from '../../services/organizationService'
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import PageTitle from '../../components/Common/PageTitle/PageTitle'
import { confirm } from '../../components/Common/Modals/ConfirmService'
import Confirmation from '../../components/Common/Modals/Confirmation'
import OrganizationStats from './OrganizationStats'
import QrCode from '../../components/Common/Modals/Qrcode'
import { MdEdit } from 'react-icons/md'
import { Tooltip as ReactTooltip } from 'react-tooltip'

// import { round } from '../../utils/utils'

import styles from './organizations.module.scss'
import Loader from '../../components/Loader/Loader'
import InviteUser from '../../components/InviteUser/InviteUser'
import sessionService from '../../services/sessionService'
import { ADMIN_ROLES } from '../../constants'
import toastService from '../../services/toastService'
import { MimicUserContext } from '../../context/MimicUserContext'

const options = {
  autoClose: 3000,
  hideProgressBar: true,
}

const getSubtitle = (main, organizations) => {
  let subtitle = ''
  if (main && !organizations?.length) {
    return subtitle
  } else if (main && organizations?.length > 0) {
    subtitle = 'Manage Organizations'
  } else {
    subtitle = 'Manage Groups of'
  }
  return subtitle
}

const Organizations = ({ main }) => {
  const [state, setState] = useState({
    organizations: [],
    unfinishedOrganizations: [],
    showConfirmationModal: false,
    showOrganizationStatsModal: false,
    organizationId: '',
    showQRCodeModal: false,
    showInviteModal: false,
  })

  const [loading, setLoading] = useState(true)
  const { organizationId: parentId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { mimicUser } = useContext(MimicUserContext)
  const [role] = useState(sessionService.getUserRole())
  const { mainOrganization } = location.state || {}

  useEffect(() => {
    loadOrganizations()
  }, [main, parentId])

  const loadOrganizations = async () => {
    try {
      setLoading(true)
      const result = main
        ? await organizationService.getMainByProfileUsername(parentId)
        : await organizationService.getByProfileUsername(parentId)
      if (result?.organizations?.length > 0) {
        const unfinishedOrganizations = []
        result.organizations.forEach((organization) => {
          if (organization.subscription && !organization.device) {
            unfinishedOrganizations.push(organization)
          }
        })
        setState((prev) => ({
          ...prev,
          organizations: [...result.organizations],
          unfinishedOrganizations,
          showConfirmationModal: unfinishedOrganizations.length > 0,
        }))
      } else {
        setState((prev) => ({
          ...prev,
          organizations: [],
        }))
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.response, options)
      setLoading(false)
    }
  }

  // const handleSubmitOrganization = async (organization) => {
  //   try {
  //     await organizationService.add(organization)
  //     toast.success('Organization is successfully created!', options)
  //     await loadOrganizations()
  //   } catch (err) {
  //     toast.error(err.response, options)
  //   }
  // }

  // const renderPlanComponent = (organization) => {
  //   setState((prev) => ({
  //     ...prev,
  //     organization: organization,
  //     activeClass: organization._id,
  //   }))
  // }

  const handleRemoveOrganization = (organizationId) => {
    confirm('Are you sure you want to remove organization?', { title: 'Confirm action' }).then(
      () => {
        organizationService
          .remove(organizationId)
          .then(() => {
            toast.success('Organization is successfully deleted!', options)
            loadOrganizations()
          })
          .catch((error) => {
            toast.error(error.response, options)
          })
      },
      () => {},
    )
  }

  const showOrganizationStats = (organization) => {
    setState((prev) => ({
      ...prev,
      showOrganizationStatsModal: true,
      statsOrganization: organization,
    }))
  }

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      showConfirmationModal: false,
      showOrganizationStatsModal: false,
      statsOrganization: null,
    }))
  }

  const openQrCodeModal = (id) => {
    setState((prev) => ({
      ...prev,
      showQRCodeModal: true,
      organizationId: id,
    }))
  }

  const closeQrCodeModal = () => {
    setState((prev) => ({
      ...prev,
      showQRCodeModal: false,
      organizationId: null,
    }))
  }

  const openInviteModal = (id, parentId) => {
    setState((prev) => ({
      ...prev,
      showInviteModal: true,
      organizationId: id,
      parentId: parentId,
    }))
  }

  const closeInviteModal = () => {
    setState((prev) => ({
      ...prev,
      showInviteModal: false,
    }))
  }

  const removeAdminHandler = (organization) => {
    if (!organization?._id) return
    try {
      confirm(`Do you want to remove admin: ${organization?.groupAdminUsername || ''}`).then(
        async () => {
          const response = await organizationService.removeAdmin({
            organizationId: organization._id,
          })
          toastService.show('success', 'The admin has been removed')
          setState((prev) => ({
            ...prev,
            organizations: prev.organizations.map((org) =>
              org._id === organization._id ? response : org,
            ),
          }))
        },
        () => {},
      )
    } catch (error) {
      toastService.show('error', 'Error removing group admin' + error?.message || '')
    }
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  const returnUnifinishedOrganizations = () => {
    const { unfinishedOrganizations } = state
    return (
      <div>
        <Row className='page-header py-4'>
          <PageTitle
            title='You have unfinished organization setup'
            subtitle='finish organization setup'
            className='text-sm-left'
          />
        </Row>
        <Card className='mb-4 card-custom'>
          <CardHeader className='border-bottom card-header-custom'>
            <h6 className='m-0'>Please setup caller Id for this organizations</h6>
          </CardHeader>
          <CardBody className='p-0 pb-3 table-responsive'>
            <table className='table  mb-0'>
              <thead className='lightblue-bg'>
                <tr>
                  <th className='border-0'>Unfinished organization names</th>
                </tr>
              </thead>
              <tbody>
                {unfinishedOrganizations.map((organization, i) => (
                  <tr key={'unfinished-organization-' + i}>
                    <td>
                      <NavLink to={`/dashboard/payment/${organization._id}`}>
                        {organization.name}
                      </NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    )
  }

  const renderAddOrganizationBtn = () => {
    if (main) {
      if (
        !organizations.length &&
        role !== ADMIN_ROLES.gropu_admin &&
        role !== ADMIN_ROLES.viewer &&
        role !== ADMIN_ROLES.master &&
        role !== ADMIN_ROLES.agent
      ) {
        return (
          <NavLink to={`/dashboard/organization/add`}>
            <button type='submit' className='btn btn-success'>
              <i className='bi bi-plus'></i> Add Main organization
            </button>
          </NavLink>
        )
      }
    } else {
      if (
        role === ADMIN_ROLES.organization_admin ||
        (role === ADMIN_ROLES.master && mimicUser?._id)
      ) {
        return (
          <NavLink to={`/dashboard/organization/${parentId}/add`}>
            <button type='submit' className='btn btn-lightblue'>
              <i className='bi bi-plus'></i> Add new Group
            </button>
          </NavLink>
        )
      }
      return null
    }
  }

  const {
    organizations,
    showConfirmationModal,
    showOrganizationStatsModal,
    statsOrganization,
    showQRCodeModal,
    organizationId,
    showInviteModal,
  } = state

  const organizationCallHistoryNavigate = (name, id) => {
    navigate(`/dashboard/organization/${id}/callHistory/list`, {
      state: { name },
    })
  }

  const editOrganizations = (organization) => {
    navigate(`/dashboard/organization/${organization._id}/edit`, {
      state: { editAdress: true, address: organization.address, parentId: organization.parentId },
    })
  }

  return (
    <div className='wrapper organization-page-container'>
      {showQRCodeModal && organizationId ? (
        <QrCode
          show={showQRCodeModal}
          generateQRCode={organizationId}
          handleClose={closeQrCodeModal}
        />
      ) : null}
      {showOrganizationStatsModal ? (
        <OrganizationStats
          show={showOrganizationStatsModal}
          handleClose={handleClose}
          organization={statsOrganization}
          organizationId={statsOrganization._id}
        />
      ) : null}
      {showConfirmationModal ? (
        <Confirmation
          show={showConfirmationModal}
          proceed={handleClose}
          showNoOption={false}
          okLabbel='OK'
          handleClose={handleClose}
          confirmation={returnUnifinishedOrganizations()}
        />
      ) : null}
      {showInviteModal ? (
        <InviteUser
          show={showInviteModal}
          handleClose={closeInviteModal}
          organizationId={organizationId}
          parentId={parentId}
        />
      ) : null}

      <Row className='page-header py-4'>
        <Col md='6'>
          <PageTitle
            title={
              main && state?.organizations?.length > 0
                ? state.organizations[0].name
                : mainOrganization?.name
                ? `${mainOrganization.name}`
                : ''
            }
            subtitle={getSubtitle(main, state?.organizations || [])}
            cssClassName='text-sm-left text-white'
          />
        </Col>
        <Col md='6' className='text-md-end text-sm-start'>
          <p> {renderAddOrganizationBtn()}</p>
        </Col>
      </Row>

      {organizations.length > 0 ? (
        <Row>
          {organizations.map((organization) => (
            <Col key={organization._id} xs='12' md={organization?.default === true ? '12' : '6'}>
              <Card className={`mb-4 card-custom`}>
                <CardHeader
                  className={`${'border-bottom card-header-custom d-flex'} ${styles.header} `}
                >
                  <h4 className='text-white'>{organization.name} </h4>
                  {organization.groupAdminUsername ? (
                    <span>Admin: {organization.groupAdminUsername}</span>
                  ) : null}
                  {role === ADMIN_ROLES.master || role === ADMIN_ROLES.organization_admin ? (
                    <>
                      <div>
                        <MdEdit
                          className={styles.editIcon}
                          onClick={() => editOrganizations(organization)}
                        />
                        <span
                          className='icon pull-right pt-1'
                          aria-hidden='true'
                          onClick={() => handleRemoveOrganization(organization._id)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='17.5'
                            height='20'
                            viewBox='0 0 17.5 20'
                          >
                            <path
                              id='Path_269'
                              data-name='Path 269'
                              d='M10.469-1.25h.938a.469.469,0,0,0,.469-.469v-8.437a.469.469,0,0,0-.469-.469h-.937a.469.469,0,0,0-.469.469v8.438A.469.469,0,0,0,10.469-1.25Zm6.406-13.125H13.656L12.328-16.59a2.079,2.079,0,0,0-1.608-.91H6.781a2.079,2.079,0,0,0-1.608.91L3.844-14.375H.625A.625.625,0,0,0,0-13.75v.625a.625.625,0,0,0,.625.625H1.25V.625A1.876,1.876,0,0,0,3.125,2.5h11.25A1.876,1.876,0,0,0,16.25.625V-12.5h.625a.625.625,0,0,0,.625-.625v-.625A.625.625,0,0,0,16.875-14.375ZM6.712-15.511a.26.26,0,0,1,.2-.114h3.673a.26.26,0,0,1,.2.114l.682,1.136H6.031ZM14.375.625H3.125V-12.5h11.25ZM6.094-1.25h.938A.469.469,0,0,0,7.5-1.719v-8.437a.469.469,0,0,0-.469-.469H6.094a.469.469,0,0,0-.469.469v8.438A.469.469,0,0,0,6.094-1.25Z'
                              transform='translate(0 17.5)'
                              fill='#6c7486'
                            />
                          </svg>
                        </span>
                      </div>
                    </>
                  ) : null}
                </CardHeader>

                <CardBody className='p-10 pb-3 card-body-custom'>
                  <div className='card-body-list'>
                    <div className={`${'card-list-items border-bottom'} ${styles.planBox}`}>
                      <div className='card-list-item'>
                        <h6>Plan</h6>
                        <p>
                          {organization.subscription ? organization.subscription.planName : 'N/A'}
                        </p>
                      </div>
                      <div className='card-list-item'>
                        <h6> VoIP number </h6>
                        {/* <p>
                          {organization.device
                            ? organization.device && organization.device.number
                              ? organization.device.number.number
                              : 'N/A'
                            : 'N/A'}
                        </p> */}
                        <p>{organization.twilioNumber ? organization.twilioNumber : 'N/A'}</p>
                      </div>
                      <div className='card-list-item'>
                        <h6> Automatic billing </h6>
                        <p>
                          {organization.subscription
                            ? organization.subscription.neverExpires
                              ? 'ON'
                              : 'OFF'
                            : 'N/A'}
                        </p>
                      </div>
                      <div className='card-list-item'>
                        <h6> Expiration date</h6>
                        {organization.subscription
                          ? organization.subscription.cancel_at_period_end
                            ? new Date(organization.subscription.cancel_at * 1000).toDateString()
                            : 'N/A'
                          : 'N/A'}
                        <p></p>
                      </div>
                    </div>
                    <div className={`${'card-list-items border-bottom'} ${styles.planBox}`}>
                      <div className='card-list-item'>
                        <h6> Available minutes </h6>
                        <p>
                          {organization.limit
                            ? Math.floor(
                                organization.limit !== 0
                                  ? organization.limit / 60
                                  : organization.limit,
                                0,
                              )
                            : 'N/A'}
                        </p>
                      </div>
                      <div className='card-list-item'>
                        <h6> Additional minutes </h6>
                        <p>
                          {organization.extraLimit
                            ? Math.floor(
                                organization.extraLimit !== 0
                                  ? organization.extraLimit / 60
                                  : organization.extraLimit,
                                0,
                              )
                            : 'N/A'}
                        </p>
                      </div>
                      <div className='card-list-item'>
                        <h6> Available SMS </h6>
                        <p>{organization?.sms ? organization.sms : 'N/A'}</p>
                      </div>
                      <div className='card-list-item'>
                        <h6> Additional SMS </h6>
                        <p>{organization?.extraSms ? organization.extraSms : 'N/A'}</p>
                      </div>
                    </div>
                    <div className='button-organization'>
                      {organization.subscription ? (
                        <div className='card-list-btns'>
                          {organization._id ? (
                            <>
                              <button
                                type='submit'
                                className='btn btn-outline-lightblue'
                                onClick={() => openQrCodeModal(organization._id)}
                                data-tooltip-id='qr-code-tooltip'
                              >
                                Get QR Code
                              </button>
                              <ReactTooltip
                                id='qr-code-tooltip'
                                place='bottom'
                                className={styles.qrCodeToolTip}
                                content='This is an alternate way for a primary user, to add new members to the organization.
                                In the Options menu on the dialer screen of the Airtouch app, there is a Settings page and if the user taps that menu, 
                                they will be put on a page with a Scan QR code button. By tapping on the button, 
                                the app will be paired to the organization and an extension will be created. The user is created and ready to work.'
                              />
                            </>
                          ) : null}
                          {role === ADMIN_ROLES.gropu_admin ? null : (
                            <NavLink to={`/dashboard/organization/${organization._id}`}>
                              {' '}
                              <button type='submit' className='btn btn-outline-lightblue'>
                                Plans & Bundles{' '}
                              </button>
                            </NavLink>
                          )}

                          {organization.device ? (
                            <NavLink to={`/dashboard/extensions/${organization._id}`}>
                              {' '}
                              <button type='submit' className='btn btn-outline-lightblue'>
                                Extensions{' '}
                              </button>
                            </NavLink>
                          ) : null}
                          {/* {organization.device ? (
                            <NavLink to={`/dashboard/transfers/${organization._id}`}>
                              {' '}
                              <button type='submit' className='btn btn-outline-lightblue'>
                                Organization transfer{' '}
                              </button>
                            </NavLink>
                          ) : null} */}
                          {/* {organization.device ? (
                            <NavLink
                              to={`/dashboard/payment/${organization._id}`}
                              state={{ detail: organization }}
                            >
                              {' '}
                              <button type='submit' className='btn btn-outline-lightblue'>
                                Set Caller ID{' '}
                              </button>
                            </NavLink>
                          ) : null} */}
                          <button
                            onClick={() =>
                              organizationCallHistoryNavigate(organization?.name, organization?._id)
                            }
                            type='submit'
                            className='btn btn-outline-lightblue'
                          >
                            Call History{' '}
                          </button>
                          <button
                            type='submit'
                            className='btn btn-outline-lightblue'
                            onClick={() => showOrganizationStats(organization)}
                          >
                            Reports
                          </button>

                          {role === ADMIN_ROLES.gropu_admin || organization.default ? null : (
                            <div>
                              {organization.groupAdminEmail ? (
                                <button
                                  onClick={() => removeAdminHandler(organization)}
                                  type='submit'
                                  className='btn btn-outline-lightblue'
                                >
                                  Remove admin{' '}
                                </button>
                              ) : (
                                <button
                                  onClick={() =>
                                    openInviteModal(organization?._id, organization.parentId)
                                  }
                                  type='submit'
                                  className='btn btn-outline-lightblue'
                                >
                                  Assign admin{' '}
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <div className='card-list-btns'>
                            {role === ADMIN_ROLES.gropu_admin ? null : (
                              <NavLink to={`/dashboard/organization/${organization._id}`}>
                                <button type='submit' className='btn btn-outline-lightblue'>
                                  Buy Plans & Bundles{' '}
                                </button>
                              </NavLink>
                            )}

                            <button
                              type='submit'
                              className='btn btn-outline-lightblue'
                              onClick={() => showOrganizationStats(organization)}
                            >
                              Reports
                            </button>
                            {role === ADMIN_ROLES.gropu_admin || organization.default ? null : (
                              <div>
                                {organization.groupAdminEmail ? (
                                  <button
                                    onClick={() => removeAdminHandler(organization)}
                                    type='submit'
                                    className='btn btn-outline-lightblue'
                                  >
                                    Remove admin{' '}
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      openInviteModal(organization?._id, organization.parentId)
                                    }
                                    type='submit'
                                    className='btn btn-outline-lightblue'
                                  >
                                    Assign admin{' '}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {organization?.default === true ? (
                        <NavLink
                          to={`/dashboard/organization/${organization?._id}/list`}
                          state={{ mainOrganization: organization }}
                        >
                          <button type='submit' className='btn btn-lightblue mb-2 me-2'>
                            List Groups
                          </button>
                        </NavLink>
                      ) : null}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <div className='alert alert-info'>{`You don't have any ${
          mainOrganization ? 'groups' : 'organizations'
        }!`}</div>
      )}
    </div>
  )
}

export default Organizations

Organizations.propTypes = {
  main: PropTypes.bool,
}
