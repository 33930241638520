import { useState } from 'react'
import styles from './Limits.module.scss'
import toastService from '../../services/toastService'
import settingsService from '../../services/settingsService'

const Limits = () => {
  const [commission, setCommission] = useState('')

  const handleCommission = (event) => {
    setCommission(event.target.value)
  }

  const handleNumber = (e) => {
    const inputValue = e.target.value
    e.target.value = inputValue.replace(/\D/g, '')
  }

  const onClickCommission = () => {
    settingsService
      .addCommission({ commission })
      .then(() => {
        toastService.show('success', `Successfully added agent commission`)
      })
      .catch((error) => {
        toastService.show('error', 'Error add agent commission' + error?.message || '')
      })
  }

  return (
    <div className={styles.wrapper}>
      <p className='mt-4'>Add agent commission: </p>
      <div className={styles.agentLimit}>
        <input
          style={{ background: '#121621' }}
          className='form-control bordered me-2'
          type='text'
          placeholder='Add the percentage'
          onInput={handleNumber}
          onChange={handleCommission}
        />
        <button onClick={onClickCommission} className='btn btn-lightblue'>
          Add
        </button>
      </div>
    </div>
  )
}

export default Limits
