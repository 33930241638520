const codes = [
  { code: 'AR', country: 'Argentina', dialingCode: '54' },
  { code: 'AU', country: 'Australia', dialingCode: '61' },
  { code: 'AT', country: 'Austria', dialingCode: '43' },
  { code: 'BH', country: 'Bahrain', dialingCode: '973' },
  { code: 'BE', country: 'Belgium', dialingCode: '32' },
  { code: 'BR', country: 'Brazil', dialingCode: '55' },
  { code: 'BG', country: 'Bulgaria', dialingCode: '359' },
  { code: 'CA', country: 'Canada', dialingCode: '1' },
  { code: 'CL', country: 'Chile', dialingCode: '56' },
  { code: 'CO', country: 'Colombia', dialingCode: '57' },
  { code: 'HR', country: 'Croatia', dialingCode: '385' },
  { code: 'CY', country: 'Cyprus', dialingCode: '357' },
  { code: 'CZ', country: 'Czech Republic', dialingCode: '420' },
  { code: 'DK', country: 'Denmark', dialingCode: '45' },
  { code: 'DO', country: 'Dominican Republic', dialingCode: '18' },
  { code: 'SV', country: 'El Salvador', dialingCode: '503' },
  { code: 'EE', country: 'Estonia', dialingCode: '372' },
  { code: 'FI', country: 'Finland', dialingCode: '358' },
  { code: 'FR', country: 'France', dialingCode: '33' },
  { code: 'GE', country: 'Georgia', dialingCode: '995' },
  { code: 'DE', country: 'Germany', dialingCode: '49' },
  { code: 'GR', country: 'Greece', dialingCode: '30' },
  { code: 'HK', country: 'Hong Kong', dialingCode: '852' },
  { code: 'HU', country: 'Hungary', dialingCode: '36' },
  { code: 'IE', country: 'Ireland', dialingCode: '353' },
  { code: 'IL', country: 'Israel', dialingCode: '972' },
  { code: 'IT', country: 'Italy', dialingCode: '39' },
  { code: 'JP', country: 'Japan', dialingCode: '81' },
  { code: 'LV', country: 'Latvia', dialingCode: '371' },
  { code: 'LT', country: 'Lithuania', dialingCode: '370' },
  { code: 'LU', country: 'Luxembourg', dialingCode: '352' },
  { code: 'MY', country: 'Malaysia', dialingCode: '60' },
  { code: 'MT', country: 'Malta', dialingCode: '356' },
  { code: 'MX', country: 'Mexico', dialingCode: '52' },
  { code: 'NL', country: 'Netherlands', dialingCode: '31' },
  { code: 'NZ', country: 'New Zealand', dialingCode: '64' },
  { code: 'NO', country: 'Norway', dialingCode: '47' },
  { code: 'PA', country: 'Panama', dialingCode: '507' },
  { code: 'PE', country: 'Peru', dialingCode: '51' },
  { code: 'PL', country: 'Poland', dialingCode: '48' },
  { code: 'PT', country: 'Portugal', dialingCode: '351' },
  { code: 'PR', country: 'Puerto Rico', dialingCode: '1787' },
  { code: 'RO', country: 'Romania', dialingCode: '40' },
  { code: 'RU', country: 'Russia', dialingCode: '7' },
  { code: 'SG', country: 'Singapore', dialingCode: '65' },
  { code: 'SI', country: 'Slovenia', dialingCode: '386' },
  { code: 'ZA', country: 'South Africa', dialingCode: '27' },
  { code: 'KR', country: 'South Korea', dialingCode: '82' },
  { code: 'ES', country: 'Spain', dialingCode: '34' },
  { code: 'SE', country: 'Sweden', dialingCode: '46' },
  { code: 'CH', country: 'Switzerland', dialingCode: '41' },
  { code: 'TR', country: 'Turkey', dialingCode: '90' },
  { code: 'GB', country: 'United Kingdom', dialingCode: '44' },
  { code: 'US', country: 'United States', dialingCode: '1' },
]
export default codes
