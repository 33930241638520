import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import styles from './CollapsedCharts.module.scss'
import { MdOutlineArrowDownward } from 'react-icons/md'

const CollapsedCharts = ({ children, title, id }) => {
  const [open, setOpen] = useState(false)
  const contentRef = useRef(null)

  const clickHand = () => {
    setOpen(!open)
  }

  return (
    <div id={id} className={styles.wrapper}>
      <button onClick={clickHand} className={`${styles.btn} ${'btn btn-lightblue'}`}>
        {title}
        <MdOutlineArrowDownward />
      </button>
      <div
        ref={contentRef}
        className={styles.content}
        style={{
          maxHeight: open && contentRef.current ? contentRef.current.scrollHeight + 'px' : '0',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default CollapsedCharts

CollapsedCharts.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  id: PropTypes.string,
}
