import SessionService from './sessionService'

import httpService from './httpService'
async function add(serialNo, data) {
  return httpService.apiRequest('post', `/profile/device/${serialNo}/extension`, data)
}

async function deleteExtension(serialNo, extensionId, accountId) {
  return httpService.apiRequest(
    'delete',
    `/profile/${SessionService.getUserUsername()}/device/${serialNo}/${accountId}/extension/${extensionId}`,
  )
}

async function deleteMainExtension(accountId, organizationId, extensionId) {
  return httpService.apiRequest(
    'delete',
    `/profile/${SessionService.getUserUsername()}/device/${accountId}/extension/${organizationId}/${extensionId}`,
  )
}

async function activate(serialNo, extensionId, organizationId) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/device/${serialNo}/extension/${extensionId}/${organizationId}/activate`,
  )
}

async function deactivate(serialNo, extensionId) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/device/${serialNo}/extension/${extensionId}/deactivate`,
  )
}

async function getByDevice(serialNo) {
  return httpService.apiRequest(
    'get',
    `/profile/${SessionService.getUserUsername()}/device/${serialNo}/extensions?onlyService=true`,
  )
}

async function updateById(extensionId, data) {
  return httpService.apiRequest(
    'patch',
    `/profile/${SessionService.getUserUsername()}/extension/${extensionId}`,
    data,
  )
}

export default {
  add,
  deleteExtension,
  activate,
  deactivate,
  getByDevice,
  updateById,
  deleteMainExtension,
}
