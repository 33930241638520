import { useState, useEffect, useContext } from 'react'
import { Button } from 'react-bootstrap'
import { MdSearch } from 'react-icons/md'
import { BiUserCheck } from 'react-icons/bi'
import CustomPagination from '../CustomPagination/CustomPagination'
import Loader from '../Loader/Loader'
import toastService from '../../services/toastService'
import usersAndAccountsService from '../../services/usersAndAccountsService'
import { confirm } from '../Common/Modals/ConfirmService'
import sessionStorageService from '../../services/sessionStorageService'

import styles from './TableUsers.module.scss'
import { MimicUserContext } from '../../context/MimicUserContext'
import sessionService from '../../services/sessionService'
import UserInformations from '../UserInformations/UserInformations'

const itemsPerPage = 10
const siblingCount = 1
const PAGING_SELECT_OPTIONS = ['10', '20', '50', '100']

const TableUsers = () => {
  const [users, setUsers] = useState([])
  const [totalCount, setTotalCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(itemsPerPage)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const { mimicUser, setMimicUser } = useContext(MimicUserContext)
  const loggedInUser = sessionService.getUser()

  const [selectedOptions, setSelectedOptions] = useState('')

  const [usersModalShow, setusersModalShow] = useState(false)
  const [currentUser, setCurrentUser] = useState('')
  const [disableBtn, setDisableBtn] = useState(true)

  useEffect(() => {
    const getUsers = () => {
      setLoading(true)
      usersAndAccountsService
        .getUsers({ page: currentPage - 1, limit, username: search })
        .then((response) => {
          setUsers(response?.users || [])
          setTotalCount(response?.total || 0)
          const totalPageCount = Math.ceil((response?.total || 0) / limit)
          if (currentPage > totalPageCount) setCurrentPage(1)
        })
        .catch((err) => {
          toastService.show('error', 'Error fetch users' + err?.message || '')
        })
        .finally(() => {
          setLoading(false)
        })
    }
    getUsers()
  }, [currentPage, limit])

  const handlePageSizeChagne = (size) => {
    setLimit(size)
    setCurrentPage(1)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      setLoading(true)
      usersAndAccountsService
        .getUsers({ page: currentPage - 1, limit, username: search })
        .then((response) => {
          setUsers(response?.users || [])
          setTotalCount(response?.total || 0)
          const totalPageCount = Math.ceil((response?.total || 0) / limit)
          if (currentPage > totalPageCount) setCurrentPage(1)
        })
        .catch((err) => {
          toastService.show('error', 'Error fetch users' + err?.message || '')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleMimicUser = (user) => {
    if (!user?._id) return
    confirm(`Do you want to mimic user ${user?.username || ''}`).then(
      () => {
        sessionStorageService.create(user, 'mimic-user')
        setMimicUser(user)
      },
      () => {},
    )
  }

  const handleDeactivateUser = (user) => {
    if (!user?._id) return
    const action = user.status === 1 ? 'deactivate' : 'activate'
    try {
      confirm(`Do you want to ${action} user ${user?.username || ''}`).then(
        async () => {
          const response = await usersAndAccountsService.activeDeactiveUser({
            username: user.username,
            status: user.status,
          })
          setUsers((prevUsers) => {
            return prevUsers.map((mapUser) =>
              mapUser._id === user._id ? { ...mapUser, status: response.user.status } : mapUser,
            )
          })
        },
        () => {},
        setusersModalShow(false),
      )
    } catch (error) {
      toastService.show('error', 'Error updating user status' + error?.message || '')
    }
  }

  const handleUserSelectChange = (event) => {
    const { value } = event.target
    setSelectedOptions(value)
    setDisableBtn(false)
  }

  const handleChangeRole = (user) => {
    try {
      confirm(`Do you want to set admin role to user ${user?.username || ''}`).then(
        async () => {
          const response = await usersAndAccountsService.setAdminRole({
            username: user.username,
            role: selectedOptions,
          })
          setUsers((prevUsers) => {
            return prevUsers.map((mapUser) =>
              mapUser._id === user._id ? { ...mapUser, role: response.user.role } : mapUser,
            )
          })
        },

        () => {},
        setusersModalShow(false),
      )
    } catch (error) {
      toastService.show('error', 'Error updating user role' + error?.message || '')
    }
  }

  const onClickShowModal = (user) => {
    setusersModalShow(true)
    setCurrentUser(user)
  }
  const handleClose = () => {
    setusersModalShow(false)
    setDisableBtn(true)
    setSelectedOptions('')
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  return (
    <div>
      {usersModalShow ? (
        <UserInformations
          show={usersModalShow}
          handleClose={handleClose}
          user={currentUser}
          handleDeactivateUser={handleDeactivateUser}
          handleUserSelectChange={handleUserSelectChange}
          selectedOptions={selectedOptions}
          handleChangeRole={handleChangeRole}
          disableBtn={disableBtn}
          setusersModalShow={setusersModalShow}
          setUsers={setUsers}
        />
      ) : null}
      <div className={styles.info}>
        <div>
          <h3>USERS</h3>
          <span>total: {totalCount}</span>
        </div>
        <div className={styles.inputWrapper}>
          <input
            placeholder='Search username'
            className='form-control bordered'
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
            value={search}
          />
          <MdSearch className={styles.searchIcon} onClick={handleKeyDown} />
        </div>
      </div>
      <div className={`${'p-0 pb-3 table-responsive card-body'}`}>
        <table className={`${'table mb-0'}`}>
          <thead className='lightblue-bg'>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Actions</th>
              <th>Status</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => (
              <tr key={index}>
                <td
                  onClick={() => {
                    onClickShowModal(user)
                  }}
                >
                  {user.username}
                </td>
                <td
                  onClick={() => {
                    onClickShowModal(user)
                  }}
                >
                  {user.email}
                </td>
                <td>
                  {loggedInUser?._id !== user._id ? (
                    <>
                      <Button
                        className='btn btn-lightblue sm'
                        size='sm'
                        onClick={() => handleMimicUser(user)}
                      >
                        Mimic user
                      </Button>
                      {mimicUser?._id === user._id ? (
                        <BiUserCheck style={{ marginLeft: '5px' }} />
                      ) : null}
                    </>
                  ) : null}
                </td>
                <td>{user.status === 1 ? 'Active' : 'Inactive'}</td>
                <td>{user.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <CustomPagination
          totalCount={totalCount}
          currentPage={currentPage}
          pageSize={limit}
          siblingCount={siblingCount}
          onPageChage={setCurrentPage}
          options={PAGING_SELECT_OPTIONS}
          hasSelect={true}
          handleSelectChange={handlePageSizeChagne}
        />
      </div>
    </div>
  )
}

export default TableUsers
