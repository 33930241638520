import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'

import 'react-quill/dist/quill.snow.css'
import './QuillTextarea.scss'

const QuillTextarea = ({ handleChangeDescription, value }) => {
  const modules = {
    toolbar: [
      [{ color: [] }, { background: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      ['link', 'image', 'video'],

      [{ script: 'sub' }, { script: 'super' }],
      [{ direction: 'rtl' }],

      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, true] }],

      ['clean'],
    ],
  }

  return (
    <div className='wrapper'>
      <ReactQuill
        theme='snow'
        modules={modules}
        value={value}
        onChange={handleChangeDescription}
        className='reactQuillInput'
      ></ReactQuill>
    </div>
  )
}

export default QuillTextarea

QuillTextarea.propTypes = {
  handleChangeDescription: PropTypes.func,
  value: PropTypes.any,
}
