import httpService from './httpService'
import SessionService from './sessionService'

async function dashboardStats(data) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/dashboardStats`,
    data,
  )
}

export default { dashboardStats }
