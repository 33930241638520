import { useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import toastService from '../../services/toastService'
import usersAndAccountsService from '../../services/usersAndAccountsService'
import CustomPagination from '../CustomPagination/CustomPagination'
import Loader from '../Loader/Loader'
import { confirm } from '../Common/Modals/ConfirmService'
import accountService from '../../services/accountService'

import AccountInformations from '../AccountInformations/AccountInformations'
import styles from './TableAccounts.module.scss'

const itemsPerPage = 10
const siblingCount = 1
const PAGING_SELECT_OPTIONS = ['10', '20', '50', '100']

const TableAccounts = () => {
  const [accounts, setAccounts] = useState([])
  const [totalCount, setTotalCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(itemsPerPage)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const [accountsModalShow, setAccountsModalShow] = useState(false)
  const [currentAccount, setCurrentAccount] = useState('')

  useEffect(() => {
    const getAccounts = () => {
      setLoading(true)
      usersAndAccountsService
        .getAccounts({
          page: currentPage - 1,
          limit: limit,
          name: search,
        })
        .then((response) => {
          setAccounts(response?.accounts || [])
          setTotalCount(response?.total || 0)
          const totalPageCount = Math.ceil((response?.total || 0) / limit)
          if (currentPage > totalPageCount) setCurrentPage(1)
        })
        .catch((err) => {
          toastService.show('error', 'Error fetch organizations' + err?.message || '')
        })
        .finally(() => setLoading(false))
    }
    getAccounts()
  }, [currentPage, limit])

  const handlePageSizeChagne = (size) => {
    setLimit(size)
    setCurrentPage(1)
  }

  const handleSearch = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      setLoading(true)
      usersAndAccountsService
        .getAccounts({
          page: currentPage - 1,
          limit: limit,
          name: search,
        })
        .then((response) => {
          setAccounts(response?.accounts || [])
          setTotalCount(response?.total || 0)
          const totalPageCount = Math.ceil((response?.total || 0) / limit)
          if (currentPage > totalPageCount) setCurrentPage(1)
        })
        .catch((err) => {
          toastService.show('error', 'Error fetch users' + err?.message || '')
        })
        .finally(() => setLoading(false))
    }
  }

  const onClickShowModal = (user) => {
    setAccountsModalShow(true)
    setCurrentAccount(user)
  }

  const handleClose = () => {
    setAccountsModalShow(false)
  }

  const handleDeactivateAccount = (account) => {
    if (!account?._id) return
    const message = account.active === 1 ? 'deactivate' : 'activate'
    const action = account.active === 1 ? accountService.deactivate : accountService.activate
    try {
      confirm(`Do you want to ${message} account ${account?.displayName || ''}`).then(
        async () => {
          try {
            const { account: updatedAccount } = await action(account._id)
            const updatedAccounts = accounts.map((acc) => {
              if (acc._id === updatedAccount._id) {
                return updatedAccount
              }
              return acc
            })
            setAccounts(updatedAccounts)
            toastService.show(
              'success',
              `Successfully ${message}d account ${account?.displayName || ''}`,
            )
          } catch (err) {
            toastService.show('error', 'Error update account')
          }
        },
        () => {},
        setAccountsModalShow(false),
      )
    } catch (error) {
      toastService.show('error', 'Error updating user status' + error?.message || '')
    }
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  return (
    <div>
      {accountsModalShow ? (
        <AccountInformations
          show={accountsModalShow}
          handleClose={handleClose}
          account={currentAccount}
          handleDeactivateAccount={handleDeactivateAccount}
        />
      ) : null}
      <div className={styles.info}>
        <div>
          <h3>ACCOUNTS</h3>
          <span>total: {totalCount}</span>
        </div>
        <div className={styles.inputWrapper}>
          <input
            placeholder='Search by display name'
            className='form-control bordered'
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleSearch}
            value={search}
          />
          <MdSearch className={styles.searchIcon} onClick={handleSearch} />
        </div>
      </div>
      <div className={`${'p-0 pb-3 table-responsive card-body '}`}>
        <table className={`${'table mb-0'}`}>
          <thead className='lightblue-bg'>
            <tr>
              <th>Display Name</th>
              <th>Number or Email</th>
              <th>Active Package</th>
              <th>Twilio Number</th>
            </tr>
          </thead>
          <tbody>
            {accounts?.map((account, index) => (
              <tr
                onClick={() => {
                  onClickShowModal(account)
                }}
                key={index}
              >
                <td>{account.displayName}</td>
                <td>{account.number ? account.number : account.email}</td>
                <td>{account.activePackage}</td>
                <td>{account.twilioNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.paginationWrapper}>
        <CustomPagination
          totalCount={totalCount}
          currentPage={currentPage}
          pageSize={limit}
          siblingCount={siblingCount}
          onPageChage={setCurrentPage}
          options={PAGING_SELECT_OPTIONS}
          hasSelect={true}
          handleSelectChange={handlePageSizeChagne}
        />
      </div>
    </div>
  )
}

export default TableAccounts
