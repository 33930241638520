import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'

import styles from './AccountInformations.module.scss'

const AccountInformations = ({ show, handleClose, account, handleDeactivateAccount }) => {
  const status = {
    0: (
      <>
        <div className={styles.spanDiv}>
          <span className='label-color'>Status:</span>
        </div>
        <h5>inactive</h5>
      </>
    ),
    1: (
      <>
        <div className={styles.spanDiv}>
          <span className='label-color'>Status:</span>
        </div>
        <h5>active</h5>
      </>
    ),
  }
  return (
    <div>
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title>Account informations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.infoWrapper}>
            <div className={styles.oneRedcord}>
              <br />
              {account.displayName ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Display Number: </span>
                  </div>
                  <h5>{account.displayName}</h5>
                </div>
              ) : null}
              {account.email ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Email: </span>
                  </div>
                  <h5>{account.email}</h5>
                </div>
              ) : (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Number: </span>
                  </div>
                  <h5>{account.number}</h5>
                </div>
              )}
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>Active Package: </span>
                </div>
                <h5>{account.activePackage}</h5>
              </div>
              {account.twilioNumber ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Twilio Number: </span>
                  </div>
                  <h5> {account.twilioNumber}</h5>
                </div>
              ) : null}
              <div className={styles.infoDiv}>{status[account?.active]}</div>
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>
                    {account.active === 1 ? 'Deactivate account:' : 'Activate account:'}
                  </span>
                </div>
                <Button
                  className={`${
                    account.active === 1 ? 'btn light-grey-bg sm' : 'btn btn-success sm'
                  }`}
                  size='sm'
                  onClick={() => {
                    handleDeactivateAccount(account)
                  }}
                >
                  {account.active === 1 ? 'Deactivate' : 'Activate'}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.oganizationBgColor}>
          <button className='btn btn-lightblue' onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AccountInformations

AccountInformations.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  account: PropTypes.object,
  handleDeactivateAccount: PropTypes.func.isRequired,
}
