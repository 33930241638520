import httpService from './httpService'
import SessionService from './sessionService'

async function purchase(organizationId, paymentPackage, subscribe) {
  const data = {
    payment_package: paymentPackage,
    subscribe: subscribe,
  }
  return httpService.apiRequest(
    'post',
    `/payment/purchase/${SessionService.getUserUsername()}/organization/${organizationId}`,
    data,
  )
}

async function purchaseExtraMinutes(organizationId, nonce, paymentPackage) {
  const data = {
    payment_method_nonce: nonce,
    payment_package: paymentPackage,
  }
  return httpService.apiRequest(
    'post',
    `/payment/purchase/${SessionService.getUserUsername()}/extraMinutes/${organizationId}`,
    data,
  )
}

async function cancelPurchase(subscriptionId, organization, office) {
  const data = {}
  if (organization) {
    data.organizationId = organization._id
  } else if (office) {
    data.officeId = office._id
    data.office = office
  }
  return httpService.apiRequest(
    'post',
    `/payment/purchase/${SessionService.getUserUsername()}/cancel/${subscriptionId}`,
    data,
  )
}

async function generateToken() {
  return httpService.apiRequest('get', `/payment/client_token/${SessionService.getUserUsername()}`)
}
export default {
  purchase,
  purchaseExtraMinutes,
  cancelPurchase,
  generateToken,
}
