import classNames from 'classnames'
import PropTypes from 'prop-types'
// import { Col } from 'shards-react'

const PageTitle = ({ title, subtitle, cssClassName, ...attrs }) => {
  const classes = classNames(cssClassName, 'row', 'text-left', 'text-md-left', 'mb-sm-0')

  return (
    <div className={classes} {...attrs}>
      <span className='text-uppercase page-subtitle'>{subtitle}</span>
      <h3 className='page-title'>{title}</h3>
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cssClassName: PropTypes.string,
}

export default PageTitle
