import { useEffect, useState } from 'react'
import styles from './TableAgentOrganizations.module.scss'
import agentService from '../../services/agentService'
import toastService from '../../services/toastService'
import Loader from '../Loader/Loader'

const TableAgentOrganizations = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    agentService
      .getAgentOrganizations()
      .then((organizations) => {
        setOrganizations(organizations)
      })
      .catch(() => {
        toastService.show('error', 'Error get agents')
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className={styles.info}>
            <div>
              <h3>Organizations</h3>
              <span>total: {organizations?.length}</span>
            </div>
          </div>
          <div className={`${'p-0 pb-3 table-responsive card-body'}`}>
            <table className={`${'table mb-0'}`}>
              <thead className='lightblue-bg'>
                <tr>
                  <th>Organization Name</th>
                  <th>Admin</th>
                  <th className='text-center'>Twilio Number</th>
                  <th className='text-center'>Unique Plan Identifier</th>
                  <th className='text-center'>Plan Price</th>
                </tr>
              </thead>
              <tbody>
                {organizations?.map((organization, index) => (
                  <tr key={index}>
                    <td>{organization.name}</td>
                    <td>{organization.username}</td>
                    <td>{organization.twilioNumber}</td>
                    <td className='text-center'>{organization?.subscription?.planId}</td>
                    <td className='text-center'>
                      {organization?.subscription?.price
                        ? organization.subscription.price + '$'
                        : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default TableAgentOrganizations
