import { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import { MdVideocam, MdCall } from 'react-icons/md'
import PageTitle from '../../components/Common/PageTitle/PageTitle'
import CustomPagination from '../../components/CustomPagination/CustomPagination'
import callHistoryService from '../../services/callHistoryService'
import { formatSeconds } from '../../utils/utils'
import toastService from '../../services/toastService'

const itemsPerPage = 10
const siblingCount = 1
const PAGING_SELECT_OPTIONS = ['10', '20', '50', '100']

const ExtensionCallHistory = () => {
  const { accountId } = useParams()
  const [callHistory, setCallHisotry] = useState([])
  const [totalCount, setTotalCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(itemsPerPage)
  const location = useLocation()

  useEffect(() => {
    const getCallHistry = async () => {
      try {
        const response = await callHistoryService.getCallHistory(accountId, currentPage, limit)
        setCallHisotry(response?.callHistory || [])
        setTotalCount(response?.total || 0)
      } catch (err) {
        toastService.show('error', 'Error get a call history ' + err?.message || '')
      }
    }
    if (accountId) getCallHistry()
  }, [accountId, currentPage, limit])

  const handlePageSizeChagne = (size) => {
    setLimit(size)
    setCurrentPage(1)
  }

  if (!accountId) return null
  return (
    <div className='wrapper organization-page-container'>
      <Row className='page-header py-4'>
        <Col md='6'>
          <PageTitle
            title='Call History'
            subtitle={`Call history for the account ${
              location?.state?.extension?.accountNumber || ''
            }`}
            cssClassName='text-sm-left text-white'
          />
        </Col>
        <Card className='mb-4 card-custom'>
          <CardHeader className='border-bottom card-header-custom'>
            <h6 className='m-0'>Extensions</h6>
          </CardHeader>
          <CardBody className='p-0 pb-3 table-responsive'>
            <table className='table  mb-0'>
              <thead className='lightblue-bg'>
                <tr>
                  <th className='border-0'>Call start</th>
                  <th className='border-0'>Call end</th>
                  <th className='border-0'>Duration</th>
                  <th className='border-0'>From</th>
                  <th className='border-0'>To</th>
                  <th className='border-0'>Direction</th>
                  <th className='border-0 '>Type</th>
                </tr>
              </thead>
              <tbody>
                {callHistory.map((call) => (
                  <tr key={call?._id}>
                    <td>
                      {call?.callStart ? dayjs(call.callStart).format('MM DD YYYY, HH:mm:ss') : ''}
                    </td>
                    <td>
                      {call?.callEnd ? dayjs(call.callEnd).format('MM DD YYYY, HH:mm:ss') : ''}
                    </td>
                    <td>{formatSeconds(call?.duration)}</td>
                    <td>{call?.from}</td>
                    <td>{call?.to}</td>
                    <td>
                      {call?.direction
                        ? call.direction
                        : call?.groupCall
                        ? 'group call'
                        : 'one to one'}
                    </td>
                    <td>
                      {call?.type || ''}{' '}
                      {call?.type === 'video' ? (
                        <MdVideocam />
                      ) : call?.type === 'voice' ? (
                        <MdCall />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Row>

      <CustomPagination
        totalCount={totalCount}
        currentPage={currentPage}
        pageSize={limit}
        siblingCount={siblingCount}
        onPageChage={setCurrentPage}
        options={PAGING_SELECT_OPTIONS}
        hasSelect={true}
        handleSelectChange={handlePageSizeChagne}
      />
    </div>
  )
}

export default ExtensionCallHistory
