import httpService from './httpService'
import SessionService from './sessionService'

async function activate(accountId) {
  return httpService.apiRequest(
    'put',
    `/account/${SessionService.getUserUsername()}/activateAccount`,
    {
      accountId,
    },
  )
}

async function deactivate(accountId) {
  return httpService.apiRequest(
    'put',
    `/account/${SessionService.getUserUsername()}/deactivateAccount`,
    {
      accountId,
    },
  )
}

async function cancel() {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/cancelAccount`,
  )
}

async function deleteAccount() {
  return httpService.apiRequest(
    'delete',
    `/profile/${SessionService.getUserUsername()}/deleteAccount`,
  )
}

async function inviteAccountToOrganization(organizationId, number, serialNo) {
  return httpService.apiRequest(
    'post',
    `/profile/${SessionService.getUserUsername()}/organization/${organizationId}/invites`,
    {
      number,
      serialNo,
    },
  )
}

export default {
  activate,
  deactivate,
  cancel,
  deleteAccount,
  inviteAccountToOrganization,
}
