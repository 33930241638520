import { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { confirm } from '../../components/Common/Modals/ConfirmService'
import PageTitle from '../../components/Common/PageTitle'
import Loader from '../../components/Loader/Loader'
import agentService from '../../services/agentService'
import toastService from '../../services/toastService'
import styles from './Agents.module.scss'
import RejectIinput from './RejectIinput'
import AgentInformations from '../../components/AgentInformations/AgentInformations'

const accept = 'accepted'
const reject = 'rejected'

const Agents = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [agents, setAgents] = useState([])
  const [usersModalShow, setusersModalShow] = useState(false)
  const [agentInfo, setAgentInfo] = useState(false)
  const [currentUser, setCurrentUser] = useState('')
  const [currentAgent, setCurrentAgent] = useState('')

  const pendingAgents = agents.filter((agent) => agent.requestedStatus === 'pending')

  useEffect(() => {
    agentService
      .getAgents()
      .then((agents) => {
        setAgents(agents)
      })
      .catch(() => {
        toastService.show('error', 'Error get agents')
      })
      .finally(() => setIsLoading(false))
  }, [])

  const accceptHandler = (agent, value) => {
    if (!agent?._id) return
    const action = value === accept ? 'acept' : 'reject'
    try {
      confirm(`Do you want to ${action} user ${agent?.username || ''} request for agent`).then(
        async () => {
          const response = await agentService.acceptOrReject({
            username: agent.username,
            requestedStatus: value,
            email: agent.email,
          })
          toastService.show('success', `Agent is successfully ${value}!`)
          setAgents((prevAgent) => {
            return prevAgent.map((mapAgent) =>
              mapAgent._id === agent._id
                ? { ...mapAgent, requestedStatus: response.user.requestedStatus }
                : mapAgent,
            )
          })
        },
        () => {},
      )
    } catch (error) {
      toastService.show('error', 'Error updating user status' + error?.message || '')
    }
  }

  const onClickShowModal = (agent, value) => {
    setusersModalShow(true)
    setCurrentUser({
      ...agent,
      requestedStatus: value,
    })
  }
  const handleClose = () => {
    setusersModalShow(false)
  }

  const onClickAgent = (agent) => {
    setAgentInfo(true)
    setCurrentAgent(agent)
  }
  const handleCloseAgentInfo = () => {
    setAgentInfo(false)
  }

  return (
    <div className='py-4'>
      <PageTitle title='' subtitle='Manage Master admin' className={styles.title} />
      <h3 className={styles.header}>Agents Requests</h3>
      {usersModalShow ? (
        <RejectIinput
          setusersModalShow={setusersModalShow}
          show={usersModalShow}
          handleClose={handleClose}
          user={currentUser}
          setAgents={setAgents}
        />
      ) : null}
      {agentInfo ? (
        <AgentInformations
          show={agentInfo}
          handleClose={handleCloseAgentInfo}
          user={currentAgent}
          setAgentInfo={setAgentInfo}
          setAgents={setAgents}
        />
      ) : null}
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <Card className='mb-4 card-custom'>
            {pendingAgents.length > 0 ? (
              <>
                <h6 className='m-2'>Agent users on pending</h6>
                <CardBody className='p-0 pb-3 table-responsive'>
                  <table className='table  mb-0'>
                    <thead className='lightblue-bg'>
                      <tr>
                        <th className='border-0'> #</th>
                        <th className='border-0'>Email</th>
                        <th className='border-0'>Username</th>
                        <th className='border-0'>First Name</th>
                        <th className='border-0'>Last Name</th>
                        <th className='border-0 '>Status</th>
                        <th className='border-0 '>Role</th>
                        <th className='border-0 text-center '>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agents
                        ?.filter((agent) => agent.requestedStatus === 'pending')
                        .map((agent, i) => (
                          <tr className={styles.tr} key={agent._id}>
                            <td>{i + 1}</td>
                            <td>{agent?.email}</td>
                            <td>{agent?.username}</td>
                            <td>{agent?.firstName}</td>
                            <td>{agent?.lastName}</td>
                            <td>{agent.requestedStatus}</td>
                            <td>{agent.role}</td>
                            <td className='text-center'>
                              <button
                                onClick={() => accceptHandler(agent, accept)}
                                className='btn btn-lightblue btn-sm me-2'
                              >
                                Accept
                              </button>
                              <button
                                onClick={() => onClickShowModal(agent, reject)}
                                className='btn btn-sm btn-danger'
                              >
                                Reject
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </CardBody>
              </>
            ) : null}
            <>
              <h6 className='m-2'>Agent users</h6>
              <CardBody className='p-0 pb-3 table-responsive'>
                <table className='table  mb-0'>
                  <thead className='lightblue-bg'>
                    <tr>
                      <th className='border-0'> #</th>
                      <th className='border-0'>Email</th>
                      <th className='border-0'>Username</th>
                      <th className='border-0 '>First Name</th>
                      <th className='border-0 '>Last Name</th>
                      <th className='border-0 '>Status</th>
                      <th className='border-0 '>Role</th>
                      <th className='border-0  text-center '>Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agents
                      ?.filter((agent) => agent.requestedStatus !== 'pending')
                      .map((agent, i) => (
                        <tr
                          onClick={() => onClickAgent(agent)}
                          className={styles.tr}
                          key={agent._id}
                        >
                          <td>{i + 1}</td>
                          <td>{agent?.email}</td>
                          <td>{agent?.username}</td>
                          <td>{agent?.firstName}</td>
                          <td>{agent?.lastName}</td>
                          <td>{agent?.requestedStatus}</td>
                          <td>{agent?.role}</td>
                          <td className='text-center'>
                            {agent?.commission ? agent.commission + '%' : '-'}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardBody>
            </>
          </Card>
        )}
      </div>
    </div>
  )
}

export default Agents
