import { useState } from 'react'
import profileService from '../../services/profileService'
import logo from '../../assets/images/logo.png'

import styles from './forgotPassword.module.scss'

const ForgotPassword = () => {
  const [state, setState] = useState({ email: '', message: null })
  const [error, setError] = useState('')

  const handleChange = (event) => {
    setState({ [event.target.name]: event.target.value })
    setError('')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const { email } = state
    if (validateForm()) {
      profileService
        .forgotPassword(email)
        .then(() => {
          setState({
            message: 'Email with link to reset password is sent to your email!',
          })
        })
        .catch(() => {})
    } else {
      setError('Email is required!')
    }
  }

  const validateForm = () => {
    return state.email
  }

  return (
    <div className='auth-page-container container-fluid'>
      <div className={`${'auth-frame jumbotron'} ${styles.forgotJumbotron}`}>
        <div className='auth-img-box '>
          <img className='auth-logo' src={logo} alt='AirTouch logo' />
        </div>
        {!state.message ? (
          <form onSubmit={handleSubmit} className='text-left'>
            <div className='form-group'>
              <p className={`${'text-start'} ${styles.textEmail}`} htmlFor='email'>
                Enter email:
              </p>
              <input
                id='email'
                className={`${'form-control'} ${styles.forgotInput}`}
                type='text'
                name='email'
                value={state.email}
                onChange={handleChange}
              />
            </div>
            {error && <div className={'alert alert-danger mt-3'}>{error}</div>}
            <div className='text-end pt-3'>
              <button type='submit' className={`${'btn btn-lightblue btn-info'} ${styles.sendBtn}`}>
                Send
              </button>
            </div>
          </form>
        ) : (
          <div className={'alert alert-info'}>{state.message}</div>
        )}
      </div>
    </div>
  )
}

export default ForgotPassword
