import PageTitle from '../../components/Common/PageTitle'
import Limits from '../../components/Limits/Limits'
import styles from './Settings.module.scss'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

const Settings = () => {
  return (
    <div className='py-4'>
      <PageTitle title='' subtitle='Manage  admin settings' className={styles.title} />
      <h3 className={styles.header}>Admin Settings</h3>
      <div className={styles.usersAndAccountsWrapper}>
        <Tabs>
          <TabList>
            <Tab>Settings</Tab>
          </TabList>
          <TabPanel>
            <Limits />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default Settings
