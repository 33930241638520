/**
 * Created by laslo on 19.3.19..
 */

const e164Regex = /^\+[1-9]\d{5,15}$/

const _updateObject = (obj, paths, value) => {
  if (paths.length > 1) {
    const newObjPath = paths.shift()
    let newObj = obj[newObjPath]
    if (!newObj) {
      newObj = {}
      obj[newObjPath] = newObj
    }
    _updateObject(newObj, paths, value)
  } else {
    obj[paths[0]] = value
  }
}

const _round = (value, places) => {
  const number = Number(value + ('e+' + places))
  return +(Math.round(number) + ('e-' + places))
}

const _validateE164Number = (phoneNumber) => {
  return phoneNumber && e164Regex.test(phoneNumber)
}

const _formatSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  // Add leading zeros if necessary
  const formattedMinutes = minutes + 'm '
  const formattedSeconds = remainingSeconds + 's'

  let formattedTime = formattedSeconds
  if (formattedMinutes !== '0m ') {
    formattedTime = formattedMinutes + formattedSeconds
  } else {
    if (hours) formattedTime = formattedMinutes + formattedSeconds
  }
  if (hours) {
    const formattedHours = hours + 'h '
    formattedTime = formattedHours + formattedTime
  }

  return formattedTime
}

const _convertMinutesToMinutesAndSeconds = (totalMinutes) => {
  totalMinutes = Number(totalMinutes).toFixed(1)
  if (isNaN(totalMinutes)) return 0
  const minutes = Math.floor(totalMinutes)
  const seconds = Math.round((totalMinutes - minutes) * 60)

  return `${minutes}:${seconds}`
}

export const updateObject = _updateObject
export const round = _round
export const validateE164Number = _validateE164Number
export const formatSeconds = _formatSeconds
export const convertMinutesToMinutesAndSeconds = _convertMinutesToMinutesAndSeconds
