import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

import styles from './InviteUser.module.scss'
import { useState } from 'react'
import profileService from '../../services/profileService'
import toastService from '../../services/toastService'
import { ADMIN_TYPES } from '../../constants'

const InviteUser = ({ show, handleClose, organizationId, parentId }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleChange = (e) => {
    setEmail(e.target.value)
    setError('')
  }

  const handleSendInvite = () => {
    const validationError = validateForm()
    if (!validationError) {
      const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (!emailRegex.test(email)) {
        setError('Invalid email format')
        return
      }
      profileService
        .emailInvite({ email, organizationId, parentId, type: ADMIN_TYPES.groupAdmin })
        .then(() => {
          toastService.show('success', 'The email has been sent')
          handleClose()
        })
        .catch((err) => {
          toastService.show('error', 'Error email was not send' + err?.message || '')
        })
    } else {
      setError(validationError)
    }
  }

  const validateForm = () => {
    if (!email) {
      return 'email is required'
    }
    return null
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeVariant='white' closeButton>
          <Modal.Title>Invite User</Modal.Title>
        </Modal.Header>
        <div className='p-4' style={{ background: '#121621' }}>
          <Modal.Body className='text-center'>
            <div>
              {' '}
              <input
                onChange={handleChange}
                className='form-control bordered'
                placeholder='Enter email'
                type='email'
                required
              />
            </div>
            <div className={styles.btnWrapper}>
              <button onClick={handleSendInvite} className='btn btn-lightblue btn-sm mt-4'>
                Send Invite
              </button>
            </div>
            {error && <div className={'alert alert-danger mt-3'}>{error}</div>}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}

export default InviteUser

InviteUser.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  user: PropTypes.object,
  organizationId: PropTypes.string,
  parentId: PropTypes.string,
}
