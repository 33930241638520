import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import profileService from '../../../services/profileService'

import { QRCodeSVG } from 'qrcode.react'

const QrCode = ({ show, handleClose, generateQRCode }) => {
  const [code, setCode] = useState('')

  useEffect(() => {
    const getCode = async () => {
      try {
        const response = await profileService.getBindingCode()
        setCode(response.code)
      } catch (err) {
        toast.error('Error loading QR code', { autoClose: 3000, hideProgressBar: true })
      }
    }
    if (!generateQRCode) getCode()
  }, [])

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ background: '#121621' }} closeVariant='white' closeButton>
          <Modal.Title>Scan QR Code</Modal.Title>
        </Modal.Header>
        <div className='p-5' style={{ background: '#121621' }}>
          <Modal.Body style={{ background: 'white' }} className='text-center'>
            <span>
              <QRCodeSVG value={generateQRCode || code} />
            </span>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}

QrCode.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  generateQRCode: PropTypes.string,
}

export default QrCode
