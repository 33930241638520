import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import styles from './AgentInformations.module.scss'
import { useState } from 'react'
import agentService from '../../services/agentService'
import toastService from '../../services/toastService'

const AgentInformations = ({ show, handleClose, user, setAgentInfo, setAgents }) => {
  const [commission, setCommission] = useState('')

  const handleCommission = (event) => {
    setCommission(event.target.value)
  }

  const handleNumber = (e) => {
    const inputValue = e.target.value
    e.target.value = inputValue.replace(/\D/g, '')
  }

  const onClickCommission = () => {
    if (!user._id) return
    agentService
      .addCommission({ username: user.username, commission: commission })
      .then((response) => {
        setAgents((prevAgent) => {
          return prevAgent.map((mapAgent) =>
            mapAgent._id === user._id
              ? { ...mapAgent, commission: response.user.commission }
              : mapAgent,
          )
        })
        toastService.show('success', `Successfully edited agent commission`)
      })
      .catch((error) => {
        toastService.show('error', 'Error add agent commission' + error?.message || '')
      })
      .finally(() => setAgentInfo(false))
  }

  return (
    <div>
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title>User informations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.infoWrapper}>
            <div className={styles.oneRedcord}>
              <br />
              {user?.username ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Username: </span>
                  </div>
                  <h5>{user.username}</h5>
                </div>
              ) : null}
              {user?.firstName ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>First Name: </span>
                  </div>
                  <h5>{user.firstName}</h5>
                </div>
              ) : null}
              {user?.lastName ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Last Name: </span>
                  </div>
                  <h5>{user.lastName}</h5>
                </div>
              ) : null}
              {user?.email ? (
                <div className={styles.infoDiv}>
                  <div className={styles.spanDiv}>
                    <span className='label-color'>Email: </span>
                  </div>
                  <h5>{user.email}</h5>
                </div>
              ) : null}
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>Status: </span>
                </div>
                <h5>{user?.status === 1 ? 'Active' : 'Inactive'}</h5>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>Agent commission: </span>
                </div>
                <h5>{user?.commission}%</h5>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.spanDiv}>
                  <span className='label-color'>Edit commission: </span>
                </div>
                <div className='custom-form-inline'>
                  <input
                    style={{ background: '#121621' }}
                    className='form-control bordered me-2'
                    type='text'
                    placeholder='Add the percentage'
                    onInput={handleNumber}
                    onChange={handleCommission}
                  />
                  <button onClick={onClickCommission} className='btn btn-lightblue'>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.oganizationBgColor}>
          <button className='btn btn-lightblue' onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default AgentInformations
AgentInformations.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setAgentInfo: PropTypes.func.isRequired,
  setAgents: PropTypes.func.isRequired,
}
