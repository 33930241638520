import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import transferService from '../../services/transferService'
import { confirm } from '../../components/Common/Modals/ConfirmService'
import PageTitle from '../../components/Common/PageTitle/PageTitle'
import organizationService from '../../services/organizationService'

const options = {}

const Transfers = () => {
  const [state, setState] = useState({
    name: '',
    transferTo: '',
    transfers: [],
    organization: {},
  })

  const { organizationId } = useParams()

  useEffect(() => {
    loadOrganizationById()
  }, [])

  const loadMyApprovedOrganizationTransfer = () => {
    transferService
      .loadApprovedFromOrganizationTransfer()
      .then((response) => {
        setState((prev) => ({
          ...prev,
          transfers: response.transfers,
        }))
      })
      .catch(() => {})
  }

  const loadOrganizationById = () => {
    organizationService
      .getById(organizationId)
      .then((result) => {
        setState((prev) => ({
          ...prev,
          organization: result,
        }))
        loadMyApprovedOrganizationTransfer()
      })
      .catch(() => {
        toast.error('Error loading organization', options)
      })
  }

  const handleChangeTransfer = (event) => {
    const { name, value } = event.target
    setState((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmitTransfer = (e) => {
    e.preventDefault()
    transferService
      .createTransferRequest(state.transferTo, state.organization._id, state.organization.name)
      .then(() => {
        toast.success('Transfer is successfully created!', options)
      })
      .catch((error) => {
        toast.error(error.response, options)
      })
  }

  const handleDeclineStatus = (id) => {
    confirm('Are you sure you want to decline request?').then(
      () => {
        transferService
          .declineTransferRequest(id)
          .then(() => {
            toast.success('Transfer is declined successfully!', options)
            loadMyApprovedOrganizationTransfer()
          })
          .catch((error) => {
            toast.error(error.response, options)
          })
      },
      () => {},
    )
  }

  const { transferTo, transfers, organization } = state
  return (
    <div className=''>
      <div style={{ width: '100%' }} className='page-header py-4 row'>
        <PageTitle
          sm='4'
          title='Connect request'
          subtitle='MANAGE CONNECT REQUEST'
          className='text-sm-start  col-lx-12 col-sm-5'
        />
        <Col sm='12' md='12' lg='8' xl='6'>
          <p className='m-0 light-grey'>
            This page helps the selected organization to connect to another organization outside
            your account under AirTouch® Office Connect service. Office Connect service can link up
            to 5 organizations to share organization extension lists in Office Connect organization
            contact list inside your app and among extensions from 5 organizations can communicate
            with each other by intercom, video call, organization chats.
          </p>
        </Col>
      </div>
      <Row>
        <Col sm='12' md='12' lg='8'>
          <Card className='mb-4 card-custom'>
            <div className='group-tranfer'>
              <CardHeader className='border-bottom'>
                <h6 className='m-0'>
                  Create organization transfer for organization {organization.name}
                </h6>
              </CardHeader>
              <CardBody className='p-20 pb-3 table-responsive'>
                <form onSubmit={handleSubmitTransfer}>
                  <div className='form-group'>
                    <label className='text-white' htmlFor='transferTo'>
                      Send transfer request to user:
                    </label>
                    <div className='custom-form-inline'>
                      <input
                        style={{ background: '#121621' }}
                        id='transferTo'
                        className='form-control bordered me-2 mb-3'
                        type='text'
                        name='transferTo'
                        value={transferTo}
                        placeholder="Enter user's username..."
                        onChange={handleChangeTransfer}
                      />
                      <div>
                        <button className='btn btn-lightblue  bt-w-95'>Send</button>
                      </div>
                    </div>
                  </div>
                </form>
                <table className='table mb-0'>
                  <thead className='lightblue-bg'>
                    <tr>
                      <th className='border-0'>Username </th>
                      <th className='border-0'>Status</th>
                      <th className='border-0 text-center'>Decline </th>
                    </tr>
                  </thead>
                  {transfers.length > 0 ? (
                    <tbody>
                      {transfers.map((transfer, i) => (
                        <tr key={i}>
                          <td>{transfer.to}</td>
                          {transfer.status === 1 ? <td>Pending</td> : null}
                          <td className='text-center'>
                            {' '}
                            <i
                              className='bi bi-x red hover-el'
                              style={{ fontSize: '24px' }}
                              onClick={() => handleDeclineStatus(transfer._id)}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : null}
                </table>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Transfers
