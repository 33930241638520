import { useEffect, useState } from 'react'
import profileService from '../../services/profileService'
import { toast } from 'react-toastify'
import sessionService from '../../services/sessionService'

import styles from './profile.module.scss'

const ChangeEmail = () => {
  const [user, setUser] = useState({ email: '', username: '' })
  const [edit, setEdit] = useState(false)

  const options = {
    autoClose: 3000,
    hideProgressBar: true,
  }

  useEffect(() => {
    let user = sessionService.getUser()
    setUser({ email: user.email, username: user.username })
  }, [])

  const showEditEmail = () => setEdit(true)
  const hideEditEmail = () => setEdit(false)

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    profileService
      .update(user.email)
      .then((response) => {
        sessionService.updateUserEmail(response)
        toast.success('Email is successfully updated!', options)
        setEdit(false)
      })
      .catch((error) => {
        toast.error(error.response, options)
      })
  }

  return (
    <div>
      <div className='subtitle-border-bottom'>
        <ul>
          <li>
            <p className='grey'>Username:</p> <span>{user.username}</span>
          </li>
          {!edit ? (
            <li>
              <p className='grey'>Email:</p>
              <div className='d-flex flex-row'>
                <span className={styles.spanEdit}>{user.email}</span>
                <span className='link-look pull-right' onClick={showEditEmail}>
                  Edit
                </span>
              </div>
            </li>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className='d-flex flex-row'>
                <h6 className={`${'mt-3 mb-3 blue'} ${styles.spanEdit}`}>Change your email</h6>
                <span
                  className='link-look pull-right d-flex align-items-center'
                  onClick={hideEditEmail}
                >
                  Close
                </span>
              </div>
              <hr />
              <div className='form-group'>
                <div className='custom-form-inline'>
                  <input
                    id='email'
                    className={`${'form-control bordered'} ${styles.inputBgColor}`}
                    type='text'
                    name='email'
                    value={user.email}
                    onChange={(e) => handleChange(e)}
                  />
                  <button
                    className={`${'btn btn-lightblue  pull-right bt-w-95'} ${styles.updateBtn}`}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          )}
        </ul>
      </div>
    </div>
  )
}

export default ChangeEmail
