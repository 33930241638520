import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { MdCheckCircleOutline, MdOutlineViewList, MdRemoveRedEye, MdSearch } from 'react-icons/md'
import { TbBan, TbTrash } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Card, CardBody, CardHeader, Row } from 'reactstrap'
import { confirm } from '../../components/Common/Modals/ConfirmService'
import Extension from '../../components/Common/Modals/Extension'
import PageTitle from '../../components/Common/PageTitle/PageTitle'
import accountService from '../../services/accountService'
import extensionService from '../../services/extensionService'
import organizationService from '../../services/organizationService'
import profileService from '../../services/profileService'
import { validateE164Number } from '../../utils/utils'

import ExtensionsTransferCall from '../../components/ExtensionsTransferCall/ExtensionsTransferCall'
import styles from './Extensions.module.scss'

const options = {
  hideProgressBar: true,
}
const Extensions = () => {
  const [state, setState] = useState({
    number: '',
    organization: {},
    // id: this.props.match.params.organizationId,
    serialNo: null,
    extensions: [],
    extension: {},
    show: false,
    showModalExtension: false,
    editingExtensionId: null,
    editingExtensionName: null,
  })
  const [search, setSearch] = useState('')

  const [socketExtensionsMap, setSocketExtensionsMap] = useState({})
  const [usersModalShow, setusersModalShow] = useState(false)
  const [currentExtension, setCurrentExtension] = useState('')

  const { organizationId } = useParams()
  const navigate = useNavigate()

  const [callInfo, setCallInfo] = useState({})

  useEffect(() => {
    const eventHandler = (event) => {
      const eventData = event.detail
      const receivedExtensionId = eventData.event.extensionId
      setSocketExtensionsMap((prevMap) => ({
        ...prevMap,
        [receivedExtensionId]: eventData.event.data.callLines,
      }))
    }

    document.addEventListener('socket-response', eventHandler)

    return () => {
      document.removeEventListener('socket-response', eventHandler)
    }
  }, [])

  useEffect(() => {
    loadOrganizationById()
  }, [])

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      showModalExtension: false,
    }))
  }

  const handleShowModalExtension = (extension) => {
    setState((prev) => ({
      ...prev,
      showModalExtension: true,
      extension: extension ? extension : null,
    }))
  }

  const loadOrganizationById = () => {
    organizationService
      .getById(organizationId)
      .then((result) => {
        const serialNo = result?.device?.serialNo || null
        setState((prev) => ({
          ...prev,
          organization: result,
          // name: result.name,
          serialNo,
        }))
        if (serialNo) {
          loadExtensions(serialNo)
          loadPendingInvites()
        }
      })
      .catch(() => {
        toast.error('Error loading organization', options)
      })
  }

  const loadExtensions = (serialNo) => {
    extensionService.getByDevice(serialNo).then((response) => {
      setState((prev) => ({
        ...prev,
        extensions: response?.extensions || [],
      }))
    })
  }

  const loadPendingInvites = () => {
    profileService.getOrganizationPendingInvites(organizationId).then((response) => {
      setState((prev) => ({
        ...prev,
        pending: response || [],
      }))
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = () => {
    if (validateE164Number(state.number)) {
      setState((prev) => ({ ...prev, numberError: null }))
      accountService
        .inviteAccountToOrganization(state.organization._id, state.number, state.serialNo)
        .then(() => {
          toast.success('Invitation sent!', options)
          loadPendingInvites()
        })
        .catch(() => {
          // ERROR HANDLEING IS IN httpService
        })
    } else {
      setState((prev) => ({
        ...prev,
        numberError:
          'Phone Number is not a valid E164 number. Proper format is: \n + (Country Code) (Area Code) Number',
      }))
    }
  }

  // TODO: THIK HOW TO HENDLE REMOVE EXTENSION
  const handleRemoveExtension = (extension) => {
    confirm('Are you sure you want to remove extension?').then(
      () => {
        extensionService
          .deleteExtension(state.serialNo, extension._id, extension.accountId)
          .then(() => {
            toast.success('Extension deleted successuffuly!', options)
            loadExtensions(state.serialNo)
          })
          .catch(() => {})
      },
      () => {},
    )
  }

  const removeExtensionsFromMainOrg = (extension) => {
    confirm(
      `Extensions for this number will be deleted from all groups. Are you sure you want to`,
    ).then(
      () => {
        extensionService
          .deleteMainExtension(extension.accountId, organizationId, extension._id)
          .then(() => {
            toast.success('Extensions deleted successuffuly!', options)
            loadExtensions(state.serialNo)
          })
          .catch(() => {})
      },
      () => {},
    )
  }

  const handleActivateDeactivateExtension = (extension) => {
    if (!extension?._id || !state?.serialNo) return
    const action = extension?.active === 1 ? extensionService.deactivate : extensionService.activate
    const message = extension?.active === 1 ? 'deactivate' : 'activate'
    confirm(`Are you sure you want to ${message} extension ${extension?.extensionNumber}?`).then(
      () => {
        action(state.serialNo, extension._id, organizationId)
          .then(() => {
            toast.success(`Extension ${message}d successuffuly!`, options)
            loadExtensions(state.serialNo)
          })
          .catch(() => {
            toast.error(`Error ${message} extension ${extension?.extensionNumber}`, options)
          })
      },
      () => {},
    )
  }

  const showExtensionStatus = (extension) => {
    if (extension.active === 0) {
      return <td> INACTIVE </td>
    } else if (extension.active === 1) {
      return <td> ACTIVE </td>
    } else {
      return <td> </td>
    }
  }

  const handleRemoveInvite = (inviteId) => {
    confirm('Are you sure you want to remove this pending invite?').then(
      async () => {
        await profileService.removeOrganizationExtensionsInvite(organizationId, inviteId)
        loadPendingInvites()
      },
      () => {},
    )
  }

  // TODO: COMMENTS FOR NOW EDIT EXTENSION NAME

  // const editExtensionName = (extension) => {
  //   setState((prev) => ({
  //     ...prev,
  //     editingExtensionName: extension.name,
  //     editingExtensionId: extension._id,
  //   }))
  // }

  // const handleExtensionNameChange = (event) => {
  //   const { value } = event.target
  //   setState((prev) => ({ ...prev, editingExtensionName: value }))
  // }

  // const changeExtensionName = async () => {
  //   const { editingExtensionId, editingExtensionName } = state
  //   await extensionService.updateById(editingExtensionId, { name: editingExtensionName })
  //   setState((prev) => ({ ...prev, editingExtensionName: null, editingExtensionId: null }))
  //   loadExtensions(state.serialNo)
  // }

  const henleShowCallHistory = (accountId, extension) => {
    navigate(`/dashboard/extensions/${organizationId}/callhistory/${accountId}`, {
      state: { extension },
    })
  }

  const openTransferCall = (extension, slot) => {
    setusersModalShow(true)
    setCurrentExtension(extension)
    setCallInfo(slot)
  }
  const handleCloseTransfer = () => {
    setusersModalShow(false)
  }

  const {
    number,
    organization,
    extensions,
    showModalExtension,
    extension,
    pending,
    // editingExtensionId,
    // editingExtensionName,
    numberError,
  } = state

  const filteredExtensions = (extensions || []).filter((extension) => {
    if (!search) return extension
    const accountNumber = extension?.accountNumber.toLowerCase() || ''
    const displayName = extension?.displayName?.toLowerCase() || ''
    return accountNumber?.includes(search) || displayName?.includes(search)
  })

  return (
    <div>
      {showModalExtension ? (
        <Extension
          show={showModalExtension}
          handleClose={handleClose}
          serialNo={organization.device.serialNo}
          extension={extension}
          extensionNumber={extension.extensionNumber}
          name={extension.name}
          alias={extension.endpoint.alias}
          username={extension.endpoint.username}
          number={extension.accountNumber}
        />
      ) : null}
      {usersModalShow ? (
        <ExtensionsTransferCall
          handleClose={handleCloseTransfer}
          show={usersModalShow}
          extensions={filteredExtensions}
          currentExtension={currentExtension}
          callInfo={callInfo}
          setusersModalShow={setusersModalShow}
        />
      ) : null}
      <div className='gpc-extension'>
        <Row className='page-header py-4'>
          <PageTitle
            title={`${organization?.name} members`}
            subtitle='Manage organization members'
            className='text-sm-left text-capitalize'
          />
        </Row>
        <Row className='page-description'>
          <p className='light-grey'>
            This is the page that displays your managed organization accounts, and all associated
            members forming the extensions for each organization. By inviting chosen contacts to
            become members to your paid organization, they can make GCP VoIP calls from their
            AirTouch One app sharing call credits from the subscribed organization service plan.
          </p>
        </Row>
        {/* {organization.device ? */}
        <div>
          <Card className='mb-4 card-custom'>
            <CardHeader className='border-bottom card-header-custom'>
              <h6 className='m-0'>
                <div className='search-box'>
                  <label className='pb-2' htmlFor=''>
                    Invite user to join organization {organization?.name}
                  </label>
                  <div className=''>
                    <div className='form-organization'>
                      <div className='custom-form-inline'>
                        <input
                          style={{ background: '#121621' }}
                          id='number'
                          className='form-control bordered'
                          type='text'
                          name='number'
                          value={number}
                          placeholder=' + country code and mobile number'
                          onChange={handleChange}
                        />
                        <button className='btn lightblue pull-right bt-w-95' onClick={handleSubmit}>
                          Invite
                        </button>
                      </div>
                    </div>
                    {numberError && <div className={'alert alert-danger'}>{numberError}</div>}
                  </div>
                </div>
              </h6>
            </CardHeader>
            {pending && pending.length > 0 ? (
              <CardBody className='p-0 pb-3 table-responsive'>
                <table className='table  mb-0'>
                  <thead className='lightblue-bg'>
                    <tr>
                      <th className='border-0'> #</th>
                      <th className='border-0'>Number</th>
                      <th className='border-0'>Invited at</th>
                      <th className='border-0 '>Status</th>
                      <th className='border-0 text-center'>Remove </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pending.map((invite, i) => (
                      <tr key={invite._id}>
                        <td>{i + 1}</td>
                        <td>{invite.number}</td>
                        <td>{dayjs(invite.createdAt).format('YYYY-MM-DD hh:mm:ss')}</td>
                        <td>{invite.status}</td>
                        <td className='th-fa-icons text-center'>
                          <TbTrash onClick={() => handleRemoveInvite(invite._id)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            ) : null}
          </Card>

          <Card className='mb-4 card-custom mt-3'>
            <CardHeader className='border-bottom card-header-custom'>
              <div className={styles.headerWrapper}>
                <h6 className='m-0'>Extensions</h6>
                <div className={styles.inputWrapper}>
                  <input
                    placeholder='Search extensions'
                    className='form-control bordered'
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <MdSearch className={styles.searchIcon} />
                </div>
              </div>
            </CardHeader>
            <CardBody className='p-0 pb-3 table-responsive'>
              <table className='table  mb-0'>
                <thead className='lightblue-bg'>
                  <tr>
                    <th className='border-0'>Extension</th>
                    <th className='border-0'>Lines</th>
                    <th className='border-0'>Airtouch Number </th>
                    <th className='border-0'>Mobile Number / Email </th>
                    <th className='border-0 '>Status</th>
                    <th className='border-0 '>Call history</th>
                    {organizationId ? <th className='border-0 text-center'>View </th> : null}
                    {organizationId ? (
                      <th className='border-0 text-center'>Activate / Deactivate </th>
                    ) : null}
                    {organizationId ? <th className='border-0 text-center'>Remove </th> : null}
                  </tr>
                </thead>
                <tbody>
                  {filteredExtensions.map((extension) => (
                    <tr key={extension._id}>
                      <td>{extension?.extensionNumber}</td>

                      {/* TODO: COMMENTS FOR NOW EDIT EXTENSION NAME AND NAME */}

                      {/* {editingExtensionId === extension._id ? (
                        <td>
                          <Row>
                            <Col xs='8' sm='10'>
                              <input
                                type='text'
                                className='form-control pull-left'
                                name='name'
                                id='name'
                                value={editingExtensionName}
                                onChange={handleExtensionNameChange}
                              />
                            </Col>
                            <Col xs='4' sm='2'>
                              <button
                                className='btn btn-lightblue pull-left'
                                onClick={changeExtensionName}
                              >
                                Save
                              </button>
                            </Col>
                          </Row>
                        </td>
                      ) : (
                        <td>
                          {extension.name}{' '}
                          <i
                            className='fa fa-pencil-square-o '
                            aria-hidden='true'
                            onClick={() => editExtensionName(extension)}
                          ></i>
                        </td>
                      )} */}
                      <td>
                        <div className={styles.lines}>
                          {(socketExtensionsMap[extension?._id] || extension?.callLines || []).map(
                            (slot, index) => {
                              const slotStyle = {
                                'on-hold': styles.onHold,
                                'in-progress': styles.inProrgess,
                                empty: styles.empty,
                              }[slot?.callStatus]
                              return (
                                <div key={index}>
                                  <div
                                    onClick={() => {
                                      slot?.inCallCallerIds?.length === 1 &&
                                      slot?.callStatus === 'on-hold'
                                        ? openTransferCall(extension, slot)
                                        : null
                                    }}
                                    className={`${styles.circle} ${slotStyle}`}
                                    data-tooltip-id={'slot-tooltip' + slot?._id}
                                  />
                                  <ReactTooltip
                                    id={'slot-tooltip' + slot?._id}
                                    key={slot?._id}
                                    place='bottom'
                                    className={styles.qrCodeToolTip}
                                    content={
                                      (slot?.inCallCallerIds &&
                                        slot?.inCallCallerIds[0]?.replace('acc_', '+')) ||
                                      ''
                                    }
                                  />
                                </div>
                              )
                            },
                          )}
                        </div>
                      </td>
                      <td>{extension?.accountProviderNumber}</td>
                      <td>{extension.accountNumber}</td>
                      {showExtensionStatus(extension)}
                      {extension?.accountId ? (
                        <td className='th-fa-icons text-center'>
                          <MdOutlineViewList
                            className='react-icon'
                            onClick={() => henleShowCallHistory(extension.accountId, extension)}
                          />
                        </td>
                      ) : null}
                      {organizationId ? (
                        <td className='th-fa-icons text-center'>
                          <MdRemoveRedEye
                            className='react-icon'
                            onClick={() => handleShowModalExtension(extension)}
                          />
                        </td>
                      ) : null}

                      {organizationId ? (
                        <td className='th-fa-icons text-center'>
                          {extension?.active === 1 ? (
                            <MdCheckCircleOutline
                              className='react-icon'
                              onClick={() => handleActivateDeactivateExtension(extension)}
                            />
                          ) : (
                            <TbBan
                              className='react-icon'
                              onClick={() => handleActivateDeactivateExtension(extension)}
                            />
                          )}
                        </td>
                      ) : null}
                      {/* TODO: THIK HOW TO HENDLE REMOVE EXTENSION */}
                      {organizationId && !organization.default ? (
                        <td className='th-fa-icons text-center'>
                          <TbTrash
                            className='react-icon'
                            onClick={() => handleRemoveExtension(extension)}
                          />
                        </td>
                      ) : (
                        <td className='th-fa-icons text-center'>
                          <TbTrash
                            className='react-icon'
                            onClick={() => removeExtensionsFromMainOrg(extension)}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </div>
        {/* : null} */}
      </div>
    </div>
  )
}

export default Extensions
