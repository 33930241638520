import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './PlanCreation.module.scss'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import plansAndBundlesService from '../../services/plansAndBundlesService'
import { toast } from 'react-toastify'
import QuillTextarea from '../../components/QuillTextarea/QuillTextarea'

const PlanCreation = () => {
  const [plan, setPlan] = useState({
    name: '',
    minutes: '',
    sms: '',
    price: '',
    duration: 'monthly',
    planId: '',
    description: '',
    type: 'public',
  })
  const [error, setError] = useState('')

  const { planId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (planId) {
      plansAndBundlesService
        .getPlan(planId)
        .then((response) => {
          setPlan(response)
        })
        .catch(() => {})
    }
  }, [])

  const handleChange = (e) => {
    const name = e.target.name
    let value = e.target.value

    if (name === 'name' || name === 'duration' || name === 'planId') {
      value = String(value)
    } else if (name === 'price') {
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1)
      }
    } else {
      value = Number(value)
    }

    setPlan({ ...plan, [name]: value })
  }

  const handleChangeType = (e) => {
    setPlan({ ...plan, type: e.target.value })
  }

  const handleChangeDescription = (value) => {
    setPlan({ ...plan, description: value })
  }

  const handleDurationChange = (e) => {
    const value = e.target.value
    setPlan({ ...plan, duration: value })
  }

  const validateForm = () => {
    if (
      !plan.name.trim() ||
      !plan.minutes ||
      !plan.sms ||
      !plan.duration.trim() ||
      !plan.planId.trim() ||
      !(plan.price !== undefined && plan.price !== null && plan.price !== '') ||
      !plan.description
    ) {
      setError('All inputs are required')
      return false
    }

    setError('')
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm()) {
      if (planId) {
        plansAndBundlesService
          .updatePlan(plan)
          .then(() => {
            toast.success('Plan is successfully updated!')
            navigate('/dashboard/plans-bundles')
          })
          .catch(() => {
            toast.error('Something went wrong, try again')
          })
      } else {
        plansAndBundlesService
          .createPlan(plan)
          .then(() => {
            toast.success('Plan is successfully created!')
            navigate('/dashboard/plans-bundles')
          })
          .catch(() => {
            toast.error('Something went wrong, try again')
          })
      }
    }
  }

  const handleNumber = (e) => {
    const inputValue = e.target.value
    e.target.value = inputValue.replace(/\D/g, '')
  }

  return (
    <div className='py-4'>
      <div className={styles.wrapper}>
        {planId ? <h2>Edit Plan</h2> : <h1>Create Your Plan</h1>}
        <div className={styles.fromWrapper}>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputBox}>
              <label>Name</label>
              <input
                name='name'
                onChange={handleChange}
                className='form-control'
                value={plan.name}
              />
            </div>
            <div className={styles.inputBox}>
              <label>Minutes</label>
              <input
                name='minutes'
                onChange={handleChange}
                className='form-control'
                onInput={handleNumber}
                value={plan.minutes}
              />
            </div>
            <div className={styles.inputBox}>
              <label>SMS</label>
              <input
                name='sms'
                onChange={handleChange}
                className='form-control'
                onInput={handleNumber}
                value={plan.sms}
              />
            </div>
            <div className={styles.inputBox}>
              <label>Unique Plan Identifier (S, M, L, XL ) </label>
              <input
                name='planId'
                onChange={handleChange}
                className='form-control'
                disabled={!!planId}
                value={plan.planId}
              />
            </div>
            <div className={styles.selectBox}>
              <label>Duration</label>
              <select
                value={plan.duration}
                onChange={handleDurationChange}
                className='form-control'
              >
                <option value='monthly'>Monthly</option>
                <option value='yearly'>Yearly</option>
              </select>
              <MdOutlineKeyboardArrowDown className={styles.arrowDown} />
            </div>
            <div className={styles.inputBox}>
              <label>Price</label>
              <input
                name='price'
                onChange={handleChange}
                className='form-control mb-4'
                value={plan.price}
                type='number'
              />
            </div>
            <div className={styles.radioBtn}>
              <label>
                <input
                  type='radio'
                  name='public'
                  value='public'
                  onChange={handleChangeType}
                  checked={plan.type === 'public'}
                />
                Public
              </label>
              <label>
                <input
                  type='radio'
                  name='country'
                  value='country'
                  onChange={handleChangeType}
                  checked={plan.type === 'country'}
                />
                Country
              </label>
              <br />

              <label>
                <input
                  type='radio'
                  name='custom'
                  value='custom'
                  onChange={handleChangeType}
                  checked={plan.type === 'custom'}
                />
                Custom
              </label>
              <br />

              <label>
                <input
                  type='radio'
                  name='trial'
                  value='trial'
                  onChange={handleChangeType}
                  checked={plan.type === 'trial'}
                />
                Trial
              </label>
              <br />
              <br />
            </div>

            <QuillTextarea
              handleChangeDescription={handleChangeDescription}
              value={plan.description}
            />

            {error && <div className={'alert alert-danger mt-2'}>{error}</div>}
            {planId ? (
              <button className={` ${'btn btn-lightblue form-control'} ${styles.formBtn} `}>
                Edit
              </button>
            ) : (
              <button className={` ${'btn btn-lightblue form-control'} ${styles.formBtn} `}>
                Create
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default PlanCreation
