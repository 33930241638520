import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import { toast } from 'react-toastify'
import AutoComplete from 'react-google-autocomplete'

import PageTitle from '../../components/Common/PageTitle/PageTitle'
import countryCodes from '../../constants/countryCodes'
import organizationService from '../../services/organizationService'
import Stepper from '../../components/Common/Stepper'
import { confirm } from '../../components/Common/Modals/ConfirmService'

import { EMERGENCY_CALL_COUNTRIES } from '../../constants'
import { googleApiKey } from '../../environment'
import toastService from '../../services/toastService'

const preparedCodes = countryCodes.map((code) => {
  return { value: code.code, label: code.country }
})

const options = {
  autoClose: 3000,
}

const Organization = ({ main }) => {
  const [state, setState] = useState({
    name: '',
    street: '',
    city: '',
    region: '',
    postalCode: '',
    isoCountry: '',
    countries: preparedCodes,
    // selectedCountry: null,
    countryCode: '',
    type: 0,
    error: null,
    country: '',
  })

  const [disabledBtn, setDisabledBtn] = useState(true)
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const location = useLocation()

  useEffect(() => {
    if (location?.state?.editAdress) {
      organizationService
        .getById(organizationId)
        .then((response) => {
          const organization = response.address || {}
          organization.name = response.name
          organization.type = response.type
          organization.countryCode = response.countryCode
          organization.isoCountry = response.countryCode
          setState(organization)
          if (
            organization?.street &&
            organization?.city &&
            organization?.region &&
            organization?.postalCode &&
            organization?.isoCountry &&
            organization?.country
          ) {
            setDisabledBtn(false)
          }
        })

        .catch(() => {})
    }
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    setState((prev) => ({
      ...prev,
      [name]: name === 'region' || name === 'isoCountry' ? value.toUpperCase() : value,
      error: null,
    }))
  }

  const updateAddressWithContinue = (organization) => {
    try {
      confirm(`Address is not valid. Do you want to continue`).then(
        async () => {
          organization.continue = true
          organizationService.updateOrganization(organizationId, organization).then(() => {
            toast.success(
              `${location?.state?.parentId ? 'Group' : 'Organization'} is successfully updated!`,
              options,
            )
            navigate('/dashboard/organizations/')
          })
        },
        () => {},
      )
    } catch (error) {
      toastService.show('error', 'Error updating user status' + error?.message || '')
    }
  }

  const createOrganizationAddresWithContinue = (organization) => {
    try {
      confirm(`Address is not valid. Do you want to continue`).then(
        async () => {
          organization.continue = true
          if (organizationId) {
            organizationService.add(organization, organizationId).then((response) => {
              toast.success('Group is successfully created!', options)
              navigate('/dashboard/organization/' + response._id)
            })
          } else {
            organizationService.addMain(organization).then((response) => {
              toast.success('Organization is successfully created!', options)
              navigate('/dashboard/organization/' + response._id)
            })
          }
        },
        () => {},
      )
    } catch (error) {
      toastService.show('error', 'Error updating user status' + (error?.message || ''))
    }
  }

  const handleSubmitOrganization = (event) => {
    event.preventDefault()

    if (validateForm()) {
      const organization = {
        name: state.name,
        type: state.type,
        countryCode: state.countryCode,
        address: {
          street: state.street,
          city: state.city,
          region: state.region,
          postalCode: state.postalCode,
          isoCountry: state.isoCountry,
          country: state.country,
        },
      }
      setDisabledBtn(true)
      if (location?.state?.editAdress) {
        organizationService
          .updateOrganization(organizationId, organization)
          .then((response) => {
            if (response.notValidAddress) {
              updateAddressWithContinue(organization)
            } else {
              toast.success('Group is successfully updated!', options)
              navigate('/dashboard/organizations/')
            }
          })
          .catch((error) => {
            toast.error(error.response, options)
          })
          .finally(() => setDisabledBtn(false))
      } else {
        organizationService
          .add(organization, organizationId)
          .then((response) => {
            if (response.notValidAddress) {
              createOrganizationAddresWithContinue(organization)
            } else {
              toast.success('Group is successfully created!', options)
              navigate('/dashboard/organization/' + response._id)
            }
          })
          .catch((error) => {
            toast.error(error.response, options)
          })
          .finally(() => setDisabledBtn(false))
      }
    } else {
      setState((prev) => ({ ...prev, error: 'All fields are required!' }))
    }
  }

  const handleSubmitMainOrganization = (event) => {
    event.preventDefault()
    if (validateForm()) {
      const organization = {
        name: state.name,
        type: state.type,
        countryCode: state.countryCode,
        address: {
          street: state.street,
          city: state.city,
          region: state.region,
          postalCode: state.postalCode,
          isoCountry: state.isoCountry,
          country: state.country,
        },
      }
      if (location?.state?.agentCreation) {
        organization.username = location?.state?.agentUserUsername
        organization.email = location?.state?.agentUserEmail
      }
      setDisabledBtn(true)
      if (location?.state?.editAdress) {
        organizationService
          .updateOrganization(organizationId, organization)
          .then((response) => {
            if (response.notValidAddress) {
              updateAddressWithContinue(organization)
            } else {
              toast.success(
                `${location?.state?.parentId ? 'Group' : 'Organization'}  is successfully updated!`,
                options,
              )
              navigate('/dashboard/organizations/')
            }
          })
          .catch((error) => {
            toast.error(error.response, options)
          })
          .finally(() => setDisabledBtn(false))
      } else {
        organizationService
          .addMain(organization)
          .then((response) => {
            if (response.notValidAddress) {
              createOrganizationAddresWithContinue(organization)
            } else {
              toast.success('Organization is successfully created!', options)
              navigate('/dashboard/organization/' + response._id)
            }
          })
          .catch((error) => {
            toast.error(error.response, options)
          })
          .finally(() => setDisabledBtn(false))
      }
    } else {
      setState((prev) => ({ ...prev, error: 'All fields are required!' }))
    }
  }

  // const handleCountryChange = (selected) => {
  //   const countryCode = selected.target.value
  //   const selectedCountry = selected.target.options[selected.target.selectedIndex].textContent
  //   setState((prev) => ({
  //     ...prev,
  //     countryCode,
  //     selectedCountry,
  //   }))
  //   setDisabledBtn(false)
  // }

  const validateForm = () => {
    let isValid = true
    if (
      !state.name ||
      !state.street ||
      !state.street ||
      !state.city ||
      !state.region ||
      !state.postalCode ||
      !state.isoCountry
    )
      isValid = false
    return isValid
  }
  const handlePlaces = (addressFromGoogle) => {
    const address = {
      street: '',
      city: '',
      region: '',
      postalCode: '',
      isoCountry: '',
      country: '',
    }
    for (const component of addressFromGoogle?.address_components || []) {
      const componentType = component.types[0]
      switch (componentType) {
        case 'street_number': {
          address.street = `${component.long_name} ${address.street}`
          break
        }
        case 'route': {
          address.street += `${component.short_name}`
          break
        }
        case 'postal_code': {
          address.postalCode = `${component.long_name}`
          break
        }
        case 'locality':
          address.city = component.long_name
          break
        case 'postal_town':
          if (!address.city) address.city = component.long_name
          break
        case 'administrative_area_level_1': {
          address.region = component.short_name
          break
        }
        case 'country': {
          address.isoCountry = component.short_name
          address.country = component.long_name
          break
        }
        default: {
          break
        }
      }
    }
    setState((prev) => ({
      ...prev,
      ...address,
      countryCode: address?.isoCountry || prev.countryCode,
    }))
    setDisabledBtn(false)
  }

  const { name, error, country, street, city, region, postalCode, isoCountry } = state

  const showEmargancyCall = EMERGENCY_CALL_COUNTRIES.includes(isoCountry)
  return (
    <div>
      <Row className='page-header pt-4'>
        {location?.state?.editAdress ? (
          <PageTitle
            title={organizationId && location?.state?.parentId ? 'Edit group' : 'Edit organization'}
            subtitle='Manage organizations'
            className='text-sm-left'
          />
        ) : (
          <>
            <PageTitle
              title={organizationId ? 'Create new group' : 'Create new organization'}
              subtitle='Manage organizations'
              className='text-sm-left'
            />

            <Col sm='12' md='12' lg='8' xl='6'>
              <p className='m-0 light-grey'>
                Creating an AirTouch® service {organizationId ? 'group' : 'organization'} is an
                essential part of establishing a secluded and secure communication community. By
                subscribing to any of the AirTouch® premium service plans for the created{' '}
                {organizationId ? 'group' : 'organization'}, you can start inviting co-workers,
                family, and friends to experience a simple to use, feature packed{' '}
                {organizationId ? 'group' : 'organization'} communication service.
              </p>
            </Col>
          </>
        )}
      </Row>
      {location?.state?.editAdress ? null : (
        <Stepper step={1} active={true} organizationId={organizationId} />
      )}
      <Row>
        <p className='h5'>Airtouch Onboarding e.911 </p>
        <p className='mb-4 light-grey'>
          Message Welcome to Airtouch primary user onboarding! As the account creator, you can
          configure some important features of the Airtouch for your organization, including
          emergency calling. To properly setup emergency calling for your organization, we need your
          physical street address. We will verify and save your location so that in the event you
          need emergency services like the police or an ambulance, they know where to go. Emergency
          calling only available for organizations the US, Canada and the UK. For the US and Canada,
          calling 911 will route to the closest emergency center. In the UK, calling either 999 or
          112 will route to the closest emergency center. In the US and Canada, you can test
          emergency calls by dialing 933. You will reach a recording that confirms your registered
          location.
        </p>

        {isoCountry && !showEmargancyCall ? (
          <div className='border-gray d-flex justify-content-center align-content-center'>
            <span className='red p-2'>Emergency services are not available for that location.</span>
          </div>
        ) : null}

        <Col sm='12' md='12' lg='8' xl='6'>
          <Card className='mb-4 card-custom'>
            <CardHeader className='border-bottom card-header-custom'>
              <h6 className=''>Create new {organizationId ? 'group' : 'ogranization'}</h6>
            </CardHeader>
            <CardBody>
              <AutoComplete
                apiKey={googleApiKey}
                onPlaceSelected={handlePlaces}
                placeholder='Search for your location'
                options={{ types: [], fields: ['address_component', 'geometry', 'name'] }}
                className='form-control bordered'
              />

              <form className='form-wrapper'>
                <div className='margin-top-10'>
                  <label className='text-white' htmlFor=''>
                    Country:
                  </label>
                  {/* <select
                    value={countryCode || ''}
                    onChange={handleCountryChange}
                    className='form-control bordered my-2'
                    disabled
                  >
                    <option value='' disabled={true}>
                      Select country
                    </option>
                    {countries?.map((code) => (
                      <option key={code.value} value={code.value}>
                        {code.label}
                      </option>
                    ))}
                  </select> */}
                  <input
                    type='text'
                    className='form-control bordered'
                    name='country'
                    id='country'
                    disabled
                    value={country || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-white' htmlFor='city'>
                    City:
                  </label>
                  <input
                    type='text'
                    className='form-control bordered'
                    name='city'
                    id='city'
                    value={city || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-white' htmlFor='street'>
                    Street:
                  </label>
                  <input
                    type='text'
                    className='form-control bordered'
                    name='street'
                    id='street'
                    value={street || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-white' htmlFor='region'>
                    Region:
                  </label>
                  <input
                    type='text'
                    className='form-control bordered'
                    name='region'
                    id='region'
                    value={region || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-white' htmlFor='postalCode'>
                    Postal code:
                  </label>
                  <input
                    type='text'
                    className='form-control bordered'
                    name='postalCode'
                    id='postalCode'
                    value={postalCode || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-white' htmlFor='isoCountry'>
                    Country code:
                  </label>
                  <input
                    type='text'
                    className='form-control bordered'
                    name='isoCountry'
                    id='isoCountry'
                    value={isoCountry || ''}
                    disabled
                    onChange={handleChange}
                  />
                </div>
                <div className='form-group'>
                  <label className='text-white' htmlFor='name'>
                    Organization name:
                  </label>
                  <input
                    type='text'
                    className='form-control bordered'
                    name='name'
                    id='name'
                    value={name}
                    onChange={handleChange}
                  />
                </div>

                <hr />
                {error && <div className={'alert alert-danger'}>{error}</div>}
                {main ? (
                  location?.state?.editAdress ? (
                    <div className='clearfix pull-right'>
                      <button
                        className='btn btn-success'
                        disabled={disabledBtn}
                        onClick={handleSubmitMainOrganization}
                      >
                        {' '}
                        Edit{' '}
                      </button>
                    </div>
                  ) : (
                    <div className='clearfix pull-right'>
                      <button
                        className='btn btn-success'
                        disabled={disabledBtn}
                        onClick={handleSubmitMainOrganization}
                      >
                        {' '}
                        Submit Main{' '}
                      </button>
                    </div>
                  )
                ) : (
                  <div className='clearfix pull-right'>
                    <button
                      className='btn btn-lightblue'
                      disabled={disabledBtn}
                      onClick={handleSubmitOrganization}
                    >
                      {' '}
                      Submit{' '}
                    </button>
                  </div>
                )}
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Organization

Organization.propTypes = {
  main: PropTypes.bool,
}
